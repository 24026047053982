
import { mapActions } from "pinia";
import { defineComponent, PropType } from "vue";

import { ADataTableVerticalItem } from "@/models/table";
import { useHelpStore } from "@/store/help";

export default defineComponent({
  name: "VerticalCellAktivitetMatchad",

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    matchad(): boolean {
      return this.item.insats?.matchad ?? false;
    },

    arbetsomrade(): string {
      return this.nullableString(this.item.insats?.arbetsomrade);
    },

    arbetsstalle(): string {
      return this.nullableString(this.item.insats?.arbetsstalle);
    },

    /**
     * Denna temp sträng kommer från Accorda9
     * då ingen koppling har gjorts mellan arbetsställe och plats
     *
     * @since version 0.8.40
     */
    tempPlaceringText(): string | null | undefined {
      return this.item.insats?.tempPlaceringText;
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString"]),
  },
});
