
import { defineComponent, PropType } from "vue";

import StatusAktuell from "@/components/status/StatusAktuell.vue";
import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellStatusAktuell",

  components: {
    StatusAktuell,
  },

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    aktuell(): boolean {
      if (this.item.statusAktuell) {
        return this.item.statusAktuell.aktuell;
      } else {
        return false;
      }
    },

    status(): string | null {
      if (this.item.statusAktuell) {
        return this.item.statusAktuell.status;
      } else {
        return null;
      }
    },
  },
});
