import { defineStore } from "pinia";

import {
  ArbetslivTyp,
  HuvudsakligtArbete,
  KartlaggningKontakt,
  MojligArbetstid,
  Mottagningsform,
  Sprakniva,
  Svenskaniva,
  Utbildningsniva,
} from "@/models/kartlaggning";

import { useAdressStore } from "./adress";
import { useCrudStore } from "./crud";
import { useDeltagareStore } from "./deltagare";
import { useDialogStore } from "./dialog";
import { useFormStore } from "./form";
import { useSavingStore } from "./saving";
import { useSnackbarStore } from "./snackbar";

export const useKartlaggningStore = defineStore("kartlaggning", {
  state: () => ({
    loading: false,

    mojligArbetstidList: [] as MojligArbetstid[],
    svenskanivaList: [] as Svenskaniva[],
    utbildningsnivaList: [] as Utbildningsniva[],
    huvudsakligtArbeteList: [] as HuvudsakligtArbete[],
    arbetslivTypList: [] as ArbetslivTyp[],
    spraknivaList: [] as Sprakniva[],

    mottagningsformList: [] as Mottagningsform[],

    kartlaggningMigration: {
      id: null as number | null,
      medborgarskap: null as string | null,
      civilstand: null as string | null,
      mottagningsformId: null as number | null,
      dossiernummer: null as string | null,
      ankom: null as string | null,
      forstaKommunalplats: null as string | null,
      aktuellKommunalplats: null as string | null,
      folkbokford: null as string | null,
      inflyttadFran: null as string | null,
      uppehallstillstandTyp: null as string | null,
      uppehallstillstand: null as string | null,
      uppehallstillstandFran: null as string | null,
      uppehallstillstandTill: null as string | null,
      klassifikation: null as string | null,
      upprattadEtableringsplan: null as string | null,
      avslutadEtableringsperiod: null as string | null,
      anmalanArbetsformedling: null as string | null,
      paborjadSFI: null as string | null,
      avslutadSFI: null as string | null,
      paborjadSamhallsorientering: null as string | null,
      avslutadSamhallsorientering: null as string | null,
    },

    isEditUtbildning: false,
    kartlaggningUtbildning: {
      id: null as number | null,
      utbildningsnivaId: null as number | null,
      skola: null as string | null,
      inriktning: null as string | null,
      beskrivning: null as string | null,
      land: null as string | null,
      startdatum: null as string | null,
      slutdatum: null as string | null,
      langd: null as string | null,
      examensar: null as string | null,
      betyg: false,
      ekvivalering: false,
      dokumenterad: false,
      kvalifikation: false,
    },
    hogstaUtbildningId: null as number | null,

    isEditArbetsliv: false,
    huvudsakligtArbeteId: null as number | null,
    kartlaggningArbetsliv: {
      id: null as number | null,
      typId: null as number | null,
      arbetsgivare: null as string | null,
      yrke: null as string | null,
      arbetsuppgift: null as string | null,
      startdatum: null as string | null,
      slutdatum: null as string | null,
      langd: null as string | null,
      dokumenterad: false,
    },

    /**
     * @todo finns ingen endpoint för detta
     */
    ssykId: 1,

    kartlaggningFriText: {
      kartlaggningDatorvana: null as string | null,
      resurser: null as string | null,
      forvantningar: null as string | null,
      myndighetskontakter: null as string | null,
      situation: null as string | null,
      halsa: null as string | null,
      hogstaUtbildning: null as string | null,
    },

    isEditSprakkunskap: false,
    kartlaggningSprakkunskap: {
      id: null as number | null,
      sprak: null as string | null,
      primar: false,
      horForstalseId: null as number | null,
      lasForstalseId: null as number | null,
      talaId: null as number | null,
      skrivaId: null as number | null,
    },

    kartlaggningOversikt: {
      utlandskBakgrund: null as boolean | null,
      fodelseLand: null as string | null,
      forstaSprak: null as string | null,
      kartlaggningId: null as number | null,
      funktionsnedsattning: null as boolean | null,
      svenskanivaId: null as number | null,
      behoverTolk: null as boolean | null,
      arbetsformedling: null as boolean | null, // ska denna vara kvar?
      arbetsformedlingDatum: null as string | null,
      cv: null as boolean | null, // ska denna vara kvar?
      cvDatum: null as string | null,
      familjeforsorjare: null as boolean | null,
      familjeforsorjareDatum: null as string | null,
      samtycke: null as boolean | null,
      samtyckeDatum: null as string | null,
      socialtjanst: null as boolean | null,
      socialtjanstDatum: null as string | null,
      mojligArbetstidId: null as number | null,
      planeringFoljs: null as string | null,
    },

    selectedIceKontakt: null as KartlaggningKontakt | null,
    kartlaggningIceKontakt: {
      id: null as number | null,
      fornamn: null as string | null,
      efternamn: null as string | null,
      fvICERollId: null as number | null,
      primar: false,
      epost: null as string | null,
      telefon: null as string | null,
    },

    // utbildningNotering: null as string | null,
    // arbetslivNotering: null as string | null,
    sprakkunskapNotering: null as string | null,
  }),

  getters: {},

  actions: {
    async getMojligArbetstidList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "KartlaggningsVarden/MojligArbetstid",
        })) as MojligArbetstid[];

        this.mojligArbetstidList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getSvenskanivaList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "KartlaggningsVarden/Svenskaniva",
        })) as Svenskaniva[];

        this.svenskanivaList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getUtbildningsnivaList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "KartlaggningsVarden/Utbildningsniva",
        })) as Utbildningsniva[];

        this.utbildningsnivaList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getHuvudsakligtArbeteList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "KartlaggningsVarden/HuvudsakligtArbete",
        })) as HuvudsakligtArbete[];

        this.huvudsakligtArbeteList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getMottagningsformList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "KartlaggningsVarden/Mottagningsform",
        })) as Mottagningsform[];

        this.mottagningsformList = data;
      } catch (error) {
        //
      }
    },

    async getArbetslivTypList() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();

        const data = (await crud.get({
          url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/ArbeteTyp`,
        })) as ArbetslivTyp[];

        this.arbetslivTypList = data.sort((a, b) => b.id - a.id);

        if (!this.kartlaggningArbetsliv.typId) {
          this.kartlaggningArbetsliv.typId = -1;
        }
      } catch (error) {
        //
      }
    },

    async getSpraknivaList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Sprakkunskap/Sprakniva",
        })) as Sprakniva[];

        this.spraknivaList = data;
      } catch (error) {
        //
      }
    },

    async addEditKartlaggningMigration() {
      const saving = useSavingStore();

      saving.set("savingKartlaggningMigration", true);

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await crud.put({
          url: `beslutspar/${deltagare.beslutsparId}/kartlaggning/etablering`,
          data: this.kartlaggningMigration,
        });

        snackbar.genericSnackbarMessage = "Migration uppdaterad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditKartlaggningMigration = false;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);

        saving.set("savingKartlaggningMigration", false);
      } catch (error) {
        saving.set("savingKartlaggningMigration", false);
      }
    },

    async addEditKartlaggningUtbildning() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        if (this.isEditUtbildning) {
          await crud.put({
            url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/Utbildning/${this.kartlaggningUtbildning.id}`,
            data: this.kartlaggningUtbildning,
          });
        }

        if (!this.isEditUtbildning) {
          await crud.post({
            url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/Utbildning`,
            data: this.kartlaggningUtbildning,
          });
        }

        snackbar.genericSnackbarMessage = this.isEditUtbildning
          ? "Utbildning uppdaterad"
          : "Utbildning sparad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditUtbildning = false;
        this.isEditUtbildning = false;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async addEditHogstaUtbildning() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();

        console.log(this.hogstaUtbildningId);

        await crud.put({
          url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/HogstaUtbildning`,
          data: {
            hogstaUtbildningId: this.hogstaUtbildningId,
          },
        });

        snackbar.genericSnackbarMessage = "Högsta utbildning uppdaterad";
        snackbar.genericSnackbar = true;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async addEditKartlaggningArbetsliv() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        if (this.isEditArbetsliv) {
          await crud.put({
            url: `beslutspar/${deltagare.beslutsparId}/kartlaggning/arbetsliv/arbete/${this.kartlaggningArbetsliv.id}`,
            data: this.kartlaggningArbetsliv,
          });
        }

        if (!this.isEditArbetsliv) {
          await crud.post({
            url: `beslutspar/${deltagare.beslutsparId}/kartlaggning/arbetsliv/arbete`,
            data: this.kartlaggningArbetsliv,
          });
        }

        snackbar.genericSnackbarMessage = this.isEditArbetsliv
          ? "Arbetslivserfarenhet uppdaterad"
          : "Arbetslivserfarenhet sparad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditArbetsliv = false;
        this.isEditArbetsliv = false;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async addEditHuvudsakligtArbete() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();

        await crud.put({
          url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/Arbetsliv`,
          data: {
            huvudsakligtArbeteId: this.huvudsakligtArbeteId,
            ssykId: this.ssykId,
          },
        });

        snackbar.genericSnackbarMessage = "Huvudsakligt arbete uppdaterat";
        snackbar.genericSnackbar = true;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async addEditKartlaggningFritext(
      object: {
        name: string;
        endpoint: string;
      },
      dialogName: string,
      toolbarTitle: string
    ) {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await crud.put({
          url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/${object.endpoint}`,
          data: {
            text: this.kartlaggningFriText[
              object.name as keyof typeof this.$state.kartlaggningFriText
            ],
          },
        });

        snackbar.genericSnackbarMessage = `${toolbarTitle} uppdaterad`;
        snackbar.genericSnackbar = true;

        dialog.toggleDialog(dialogName, false);

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async addEditKartlaggningSprakkunskap() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        if (this.isEditSprakkunskap) {
          await crud.put({
            url: `sprakkunskap/${this.kartlaggningSprakkunskap.id}`,
            data: this.kartlaggningSprakkunskap,
          });
        }

        if (!this.isEditSprakkunskap) {
          await crud.post({
            url: `beslutspar/${deltagare.beslutsparId}/kartlaggning/sprakkunskap`,
            data: this.kartlaggningSprakkunskap,
          });
        }

        snackbar.genericSnackbarMessage = this.isEditSprakkunskap
          ? "Språkkunskap uppdaterad"
          : "Språkkunskap sparad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditSprakkunskap = false;
        this.isEditSprakkunskap = false;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async addEditSprakkunskapNotering() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();

        await crud.put({
          url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/SprakNotering`,
          data: {
            text: this.sprakkunskapNotering,
          },
        });

        snackbar.genericSnackbarMessage = "Notering uppdaterad";
        snackbar.genericSnackbar = true;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async addEditKartlaggningOversikt() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await crud.put({
          url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/DeltagareInfo`,
          data: this.kartlaggningOversikt,
        });

        snackbar.genericSnackbarMessage = "Översikt uppdaterad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditKartlaggningOversikt = false;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async addKartlaggningIceKontakt() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const adress = useAdressStore();

        await crud.post({
          url: `Beslutspar/${deltagare.beslutsparId}/Kartlaggning/IceKontakt`,
          data: {
            ...this.kartlaggningIceKontakt,
            adress: adress.adressForm,
          },
        });

        snackbar.genericSnackbarMessage = "ICE kontakt skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddIceKontakt = false;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },

    async getKartlaggningIceKontaktToEdit(
      iceKontaktId: number | null | undefined
    ) {
      if (!iceKontaktId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `KartlaggningKontakt/${iceKontaktId}`,
        })) as KartlaggningKontakt;

        this.kartlaggningIceKontakt.id = iceKontaktId;
        this.kartlaggningIceKontakt.fvICERollId = data.fvICERollId;
        this.kartlaggningIceKontakt.fornamn = data.fornamn;
        this.kartlaggningIceKontakt.efternamn = data.efternamn;
        this.kartlaggningIceKontakt.primar = data.primar;
      } catch (error) {
        //
      }
    },

    async updateKartlaggningIceKontakt() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const form = useFormStore();

        await crud.put({
          url: `KartlaggningKontakt/${this.kartlaggningIceKontakt.id}`,
          data: this.kartlaggningIceKontakt,
        });

        form.isDirty = false;

        snackbar.genericSnackbarMessage = "ICE kontakt uppdaterad";
        snackbar.genericSnackbar = true;

        deltagare.getBeslutsparKartlaggning(deltagare.beslutsparId);
      } catch (error) {
        //
      }
    },
  },
});
