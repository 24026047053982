
import { mapWritableState } from "pinia";
import { defineComponent } from "vue";

import DialogDefault from "@/components/dialog/DialogDefault.vue";
import { useDialogStore } from "@/store/dialog";

export default defineComponent({
  name: "DialogAutoBevakning",

  components: {
    DialogDefault,
  },

  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapWritableState(useDialogStore, ["dialogAutobevakning"]),
  },
});
