import { defineStore } from "pinia";

import { Adress, AdressTyp, Postnummer, PostnummerOrt } from "@/models/adress";
import { Parent } from "@/models/parent";

import { useCrudStore } from "./crud";
import { useDialogStore } from "./dialog";
import { useReloadStore } from "./reload";
import { useSnackbarStore } from "./snackbar";

export const useAdressStore = defineStore("adress", {
  state: () => ({
    loading: false,
    saving: false,
    setAsPrimarHushallAdress: false, // används vid skapade av hushåll
    postnummerList: [] as Postnummer[],
    ortList: [] as PostnummerOrt[],
    adressTypList: [] as AdressTyp[],
    isEdit: false,

    adressListByParent: [] as Adress[],
    adressListInComponent: [] as Adress[],

    adressTypEnum: Object.freeze({
      besoksadress: {
        id: -1,
        namn: "Besöksadress",
      },
      postadress: {
        id: -2,
        namn: "Postadress",
      },
      personadress: {
        id: -3,
        namn: "Personadress",
      },
      hushalladress: {
        id: -4,
        namn: "Hushålladress",
      },
    }),

    adressForm: {
      id: null as number | null,
      adressTyp: null as AdressTyp | null,
      adressTypId: null as number | null,
      gatuadress: null as string | null,
      postnummer: null as string | null,
      ort: null as string | null,
      co: null as string | null,
      primar: false,
      displayPostnr: null as string | null | undefined,
      postnummerOrt: null as string | null | undefined,
    },

    /**
     * Används bara vid skapade av nytt arbetsställe,
     * då man kan skapa ett arbetsställe med både postadress och besöksadress
     *
     * @since version 0.8.41
     */
    besoksadressForm: {
      id: null as number | null,
      adressTypId: -1 as number | null,
      adressTyp: null as AdressTyp | null,
      gatuadress: null as string | null,
      postnummer: null as string | null,
      ort: null as string | null,
      co: null as string | null,
      primar: false,
      displayPostnr: null as string | null | undefined,
      postnummerOrt: null as string | null | undefined,
    },
  }),

  getters: {
    findAdressTyp:
      (state) =>
      (namn: string): AdressTyp | null => {
        const adressTyp = state.adressTypList.find((i) => i.namn === namn);

        if (adressTyp !== undefined && adressTyp !== null) {
          return adressTyp;
        }

        return null;
      },

    getDeltagarePrimarAdress(): Adress | null {
      const adress = useAdressStore();
      const primar = adress.findPrimarAdress(adress.adressListByParent);

      return primar ?? null;
    },
  },

  actions: {
    /**
     * Formaterar postnummer från 12345 till 123 45
     */
    formateraPostnr(postnr: string | null | undefined): string | null {
      if (!postnr) return null;

      return postnr.replace(/^(\d{3})(\d{2})$/g, "$1 $2");
    },

    findPrimarAdress(adressList: Adress[]) {
      const adress = adressList.find((i) => i.primar);

      if (adress !== undefined) {
        return adress;
      }

      return null;
    },

    setAdressAsString(adress: Adress): string {
      const co = adress.co;
      const gatuadress = adress.gatuadress;
      const postnummer = adress.postnummer.replace(
        /^(\d{3})(\d{2})$/g,
        "$1 $2"
      );
      const ort = adress.ort;
      return `${co ? `c/o ${co},` : ""} ${gatuadress}, ${postnummer} ${ort}`;
    },

    findPrimarAdressByAdressTyp(
      adressList: Adress[],
      type: string
    ): Adress | null {
      const primarAdress = adressList.filter((i) => i.primar);

      if (primarAdress) {
        const adressTyp = primarAdress.find((i) => i.adressTyp?.namn === type);

        if (adressTyp) {
          return adressTyp;
        }
      }

      return null;
    },

    setAdress(adress: Adress): Adress {
      return {
        id: adress.id,
        adressTyp: adress.adressTyp,
        adressTypId: adress.adressTyp?.id ?? null,
        co: adress?.co,
        gatuadress: adress.gatuadress,
        displayPostnr: adress.postnummer
          ? adress.postnummer.replace(/^(\d{3})(\d{2})$/g, "$1 $2")
          : null,
        postnummer: adress.postnummer,
        ort: adress.ort,
        postnummerOrt: `${this.formateraPostnr(adress.postnummer)} ${
          adress.ort
        }`,
        primar: adress.primar,
      };
    },

    resetAdressForm() {
      this.adressForm.adressTyp = null;
      this.adressForm.adressTypId = null;
      this.adressForm.co = null;
      this.adressForm.displayPostnr = null;
      this.adressForm.gatuadress = null;
      this.adressForm.id = null;
      this.adressForm.ort = null;
      this.adressForm.postnummer = null;
      this.adressForm.postnummerOrt = null;
      this.adressForm.primar = false;
    },

    /**
     * Används för visning i andra komponenter t ex Personuppgifter
     *
     * @since version 0.8.41
     */
    async getAdressListByParent(parent: Parent) {
      this.adressListByParent = [];

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `${parent.name}/${parent.id}/adress`,
        })) as Adress[];

        for await (const item of data) {
          const adress = this.setAdress(item);

          this.adressListByParent.push(adress);
        }
      } catch (error) {
        //
      }
    },

    /**
     * Använder en separat hämtning av adresser i komponenten för att särskilja
     * adresser som visas i andra komponenter
     *
     * @since version 0.8.41
     */
    async getAdressListByParentInComponent(parent: Parent) {
      this.adressListInComponent = [];

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `${parent.name}/${parent.id}/adress`,
        })) as Adress[];

        for await (const item of data) {
          const adress = this.setAdress(item);

          this.adressListInComponent.push(adress);
        }
      } catch (error) {
        //
      }
    },

    async getAdressTypList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "adresstyp",
        })) as AdressTyp[];

        this.adressTypList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getAdressToEdit(parent: Parent | null, adressId: number | null) {
      if (!parent) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `${parent.name}/${parent.id}/adress/${adressId}`,
        })) as Adress;

        this.adressForm = this.setAdress(data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async addEditAdress(parent: Parent | null) {
      if (!parent) return;

      this.saving = true;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        if (this.isEdit) {
          await crud.put({
            url: `${parent.name}/${parent.id}/${parent.relation}/${this.adressForm.id}`,
            data: this.adressForm,
          });
        }

        if (!this.isEdit) {
          await crud.post({
            url: `${parent.name}/${parent.id}/${parent.relation}`,
            data: this.adressForm,
          });
        }

        snackbar.genericSnackbarMessage = this.isEdit
          ? "Adress uppdaterad"
          : "Adress skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditAdress = false;
        this.isEdit = false;

        reload.toggleReload("reloadAdressComponent");
        reload.toggleReload("reloadAfterAddEditAdress");

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async setPrimarAdress(parent: Parent) {
      if (!parent) return;

      this.saving = true;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.put({
          url: `${parent.name}/${parent.id}/${parent.relation}/${this.adressForm.id}`,
          data: {
            ...this.adressForm,
            primar: true,
          },
        });

        snackbar.genericSnackbarMessage = "Adress satt som primär";
        snackbar.genericSnackbar = true;

        dialog.dialogSetPrimarAdress = false;

        reload.toggleReload("reloadAdressComponent");
        reload.toggleReload("reloadAfterSetPrimarAdress");

        /**
         * Återställer adress formulär här då det bara är en prompt dialog för att sätta primär
         *
         * @since version 0.8.41
         */
        this.resetAdressForm();

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async getPostnummerOrtList() {
      this.loading = true;

      this.ortList = [];
      this.postnummerList = [];

      try {
        const crud = useCrudStore();
        const data = (await crud.get({
          url: "postnummerort",
        })) as PostnummerOrt[];

        this.ortList = [...new Set(data)];

        for (const ort of data) {
          for (const postnr of ort.postnummer) {
            this.postnummerList.push({
              ...postnr,
              displayPostnr: postnr.namn.replace(/^(\d{3})(\d{2})$/g, "$1 $2"),
              ortNamn: ort.namn,
            });
          }
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
});
