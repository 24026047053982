
import { mapWritableState } from "pinia";
import { defineComponent } from "vue";

import { useSnackbarStore } from "@/store/snackbar";

export default defineComponent({
  name: "GenericSnackbar",

  watch: {
    genericSnackbar: {
      handler(value) {
        if (!value) {
          this.genericSnackbarMessage = null;
        }
      },
    },
  },

  computed: {
    ...mapWritableState(useSnackbarStore, [
      "genericSnackbar",
      "genericSnackbarMessage",
    ]),
  },
});
