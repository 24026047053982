
import { defineComponent, PropType } from "vue";

import { MultiList } from "@/models/list";

import AListItemAction from "./components/AListItemAction.vue";
import AListItemButton from "./components/AListItemButton.vue";
import AListItemChildren from "./components/AListItemChildren.vue";
import AListItemLoading from "./components/AListItemLoading.vue";
import AListItemNav from "./components/AListItemNav.vue";
import AListItemNoData from "./components/AListItemNoData.vue";

export default defineComponent({
  name: "AList",

  components: {
    AListItemNav,
    AListItemChildren,
    AListItemAction,
    AListItemButton,
    AListItemLoading,
    AListItemNoData,
  },

  props: {
    items: {
      type: Array as PropType<MultiList[]>,
      default: () => [],
    },

    mini: {
      type: Boolean,
      required: true,
    },

    listWidth: {
      type: null as unknown as PropType<number | null | undefined>,
      // default: 230,
    },

    noSort: {
      type: Boolean,
      default: false,
    },

    sortBy: {
      type: String as PropType<string>,
      default: "title",
    },

    sortDesc: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    activeColor: {
      type: String,
      default: "primary--text",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    hideList: {
      type: Boolean,
      default: false,
    },
  },
});
