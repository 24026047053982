
import { defineComponent, PropType } from "vue";

import { MultiList } from "@/models/list";

export default defineComponent({
  name: "AListItemNav",

  props: {
    item: {
      type: Object as PropType<MultiList>,
      required: true,
    },

    mini: {
      type: Boolean,
      required: true,
    },

    isChild: {
      type: Boolean,
      default: false,
    },

    activeColor: {
      type: String,
      default: "primary--text",
    },

    hideList: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isMatchningRoute: false,
    activeClass: null as string | null,
  }),

  methods: {
    setActiveClass(item: MultiList) {
      if (item?.id) {
        if (parseInt(this.$route.params.id) === item.id) {
          if (this.item.aktuell !== undefined) {
            return this.item.aktuell
              ? "primary--text v-list-item--active"
              : "error--text v-list-item--active";
          } else {
            return this.activeColor;
          }
        }
      }

      if (item?.matchWithRoute) {
        if (this.$route.name === item.route?.name) {
          this.isMatchningRoute = true;
          return "primary--text v-list-item--active";
        } else {
          this.isMatchningRoute = false;
        }
      }

      if (item?.matchWithParams) {
        const params = item.route?.params as { pages: string[] };

        if (this.$route.params.pages !== undefined) {
          if (this.$route.params.pages[0] === params.pages[0]) {
            this.isMatchningRoute = true;
            return this.activeColor;
          } else {
            this.isMatchningRoute = false;
          }
        }
      }
    },
  },
});
