
import { defineComponent, PropType } from "vue";

import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellWithButton",

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    buttonText(): string | null | undefined {
      return this.item.buttonText;
    },

    event(): { name: string; value: unknown } {
      return this.item?.event ? this.item.event : { name: "", value: "" };
    },
  },
});
