export default {
  primary: {
    base: "#002E6D", // Primary 900
    lighten5: "#EBF1F5", // Primary A100
    lighten4: "#E4ECF3", // Primary A200
    lighten3: "#D7E3ED", // Primary 50
    lighten2: "#B3C0D3", // Primary 200
    lighten1: "#8097B6", // Primary 400
    darken1: "#063b85",
    darken2: "#053477",
    darken3: "#042c64",
    darken4: "#032350", // Primary 1000
  },

  secondary: "#424242",
  accent: "#82B1FF",
  error: "#B30036", // negative_medium
  info: "#2C81C6", // info_medium
  success: "#006633", // positive_medium
  warning: "#EE5711", // attention_medium
};
