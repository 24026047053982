
import { defineComponent, PropType } from "vue";

import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellWithKey",

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    horizontal(): boolean {
      return this.item.horizontal ?? false;
    },
  },
});
