
import { defineComponent } from "vue";

import AppBar from "@/layouts/default/AppBar.vue";
import NavigationDrawer from "@/layouts/default/NavigationDrawer.vue";

import ErrorSnackbar from "./components/snackbar/ErrorSnackbar.vue";
import GenericSnackbar from "./components/snackbar/GenericSnackbar.vue";
import DialogUpdateStatus from "./components/status/dialog/DialogUpdateStatus.vue";

export default defineComponent({
  name: "App",

  components: {
    AppBar,
    NavigationDrawer,
    GenericSnackbar,
    ErrorSnackbar,
    DialogUpdateStatus,
  },
});
