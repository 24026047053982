var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list-item',{class:_vm.setActiveClass(_vm.item),attrs:{"disabled":_vm.item.disabled,"to":_vm.item.route
      ? _vm.item.route.params
        ? {
            name: _vm.item.route.name,
            params: _vm.item.route.params,
          }
        : { name: _vm.item.route.name }
      : null},on:{"click":function($event){return _vm.$emit('clicked-nav', _vm.item)}}},[(_vm.mini)?[_c('v-tooltip',{attrs:{"left":"","open-delay":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"x-small":_vm.item.icon.size === 'x-small',"small":_vm.item.icon.size === 'small' || _vm.isChild,"large":_vm.item.icon.size === 'large',"x-large":_vm.item.icon.size === 'x-large',"color":_vm.isMatchningRoute ? 'primary' : _vm.item.icon.color,"disabled":_vm.item.disabled}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.item.icon.name)+" ")])]}}],null,false,674669177)},[_c('span',[_vm._v(_vm._s(_vm.item.title))])])]:_vm._e(),(!_vm.mini)?[(!_vm.isChild)?_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"small":"","disabled":_vm.item.disabled}},[_vm._v(" "+_vm._s(_vm.item.icon.name)+" ")])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.isChild ? 'pl-8' : null},[(_vm.hideList)?_c('v-skeleton-loader',{attrs:{"type":"text","boilerplate":"","height":"10","tile":""}}):[_vm._v(" "+_vm._s(_vm.item.title)+" ")]],2)],1),(_vm.isChild)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.item.icon.name)+" ")])],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }