
import { ChartData } from "chart.js";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import { DataTableHeader } from "vuetify/types";

import ACard from "@/components/card/ACard.vue";
import ADataTableDefault from "@/components/table/ADataTableDefault.vue";
import { KeyNumber, StatistikTableRow } from "@/models/statistik";
import { useHelpStore } from "@/store/help";
import { useStatistikStore } from "@/store/statistik";

import ABarChart from "./ABarChart.vue";

export default defineComponent({
  name: "AntalPerResultatVidBeslutsbyte",

  components: {
    ACard,
    ADataTableDefault,
    ABarChart,
  },

  props: {
    resultDone: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    chartData: {
      labels: [] as string[],
      datasets: [
        {
          label: "Antal deltagare",
          data: [] as number[],
        },
      ],
    } as ChartData<"bar">,
  }),

  watch: {
    resultDone: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.prepareChartData();
        }
      },
    },
  },

  computed: {
    ...mapState(useStatistikStore, ["resultData"]),

    headers(): DataTableHeader[] {
      return [
        {
          text: "Resultat",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
        {
          text: "Fördelning i %",
          value: "procent",
          sortable: false,
          align: "center",
        },
      ];
    },

    items(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      let totalProcent = 0;

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerBeslut.resultatVidBeslutByte as KeyNumber
      )) {
        const procent = this.percentage(
          value,
          this.resultData?.uppgifterPerBeslut.totaltAntal ?? 0
        );

        totalProcent += procent ?? 0;

        array.push({
          name: key,
          antal: value,
          totalAntal: this.resultData?.uppgifterPerBeslut.totaltAntal ?? 0,
          procent: `${this.nullableString(procent?.toString(), "0")} %`,
          totalProcent: "",
        });
      }

      this.addTotalProcentToAllItems(array, totalProcent);

      return array;
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString", "percentage"]),
    ...mapActions(useStatistikStore, ["addTotalProcentToAllItems"]),

    prepareChartData() {
      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerBeslut.resultatVidBeslutByte as KeyNumber
      ).sort()) {
        this.chartData.labels?.push(key);
        this.chartData.datasets[0].data.push(value);
      }
    },
  },
});
