import { defineStore } from "pinia";

import { getDiffBetweenTwoDates } from "@/helpers/date";
import { FastVarde, Land } from "@/models/fastvarde";
import { Kontaktuppgift } from "@/models/kontaktuppgift";
import { Parent } from "@/models/parent";

import { useCrudStore } from "./crud";
import { useFormStore } from "./form";
import { useKontaktuppgiftStore } from "./kontaktuppgift";
import { useReloadStore } from "./reload";
import { useSnackbarStore } from "./snackbar";

/**
 * Primary colors
 *
 * primary lighten-2 #b3c0d3
 * primary lighten-3 #d7e3ed
 */
export const useHelpStore = defineStore("help", {
  state: () => ({
    prioColor: Object.freeze({
      one: {
        hex: "#BBDEFB",
        css: "blue lighten-4",
      },
      onehalf: {
        hex: "#E3F2FD",
        css: "blue lighten-5",
      },
      two: {
        hex: "#B2DFDB",
        css: "teal lighten-4",
      },
      twohalf: {
        hex: "#E0F2F1",
        css: "teal lighten-5",
      },
      three: {
        hex: "#FFE0B2",
        css: "orange lighten-4",
      },
      threehalf: {
        hex: "#FFF3E0",
        css: "orange lighten-5",
      },
      four: {
        hex: "#ECEFF1",
        css: "blue-grey lighten-5",
      },
    }),

    // tillägg / ta bort
    selected: [] as number[],
    previousSelected: [] as number[],

    landList: [] as Land[],
    sprakList: [] as string[],
    civilstandList: [] as string[],
    uppehallstillstandTypList: [] as string[],
    kommunList: [] as string[],
    klassifikationList: [] as { header?: string; name?: string }[],
  }),

  actions: {
    nullableBooleanToString(item: boolean | null | undefined): string {
      if (item === undefined) return "---";

      switch (item) {
        case null:
          return "Ej valt";
        case true:
          return "Ja";
        case false:
          return "Nej";
        default:
          return "---";
      }
    },

    nullableString(
      item: string | null | undefined,
      nullString = "---"
    ): string {
      if (!item) return nullString;

      return item;
    },

    findPrimarKontaktuppgift(
      kontaktuppgiftList: Kontaktuppgift[],
      kontaktTyp: string
    ): string {
      const kontaktuppgift = useKontaktuppgiftStore();

      return kontaktTyp === "Telefon"
        ? kontaktuppgift.formateraTelefon(
            kontaktuppgiftList.find(
              (i) => i.kontaktuppgiftTyp.namn === kontaktTyp && i.primar
            )?.varde ?? null
          )
        : kontaktuppgiftList.find(
            (i) => i.kontaktuppgiftTyp.namn === kontaktTyp && i.primar
          )?.varde ?? "---";
    },

    /**
     * Formaterar personnummer till tio siffror i format: YYMMDD-NNNN
     *
     * @since Version 0.8.0
     */
    formateraPersonnummer(
      personnummer: string | null | undefined,
      format = "$1$2$3-$4"
    ): string {
      if (!personnummer) return "---";

      const tio = personnummer.slice(2);
      return tio.replace(/^(\d{2})(\d{2})(\d{2})(\d{4})$/g, format);
    },

    /**
     * Räknar ut ålder från personnummer och dagens datum
     */
    getAlder(personnummer: string | null | undefined): string {
      if (!personnummer) return "---";

      const birthDate = personnummer.slice(0, 8);
      const age = getDiffBetweenTwoDates(
        birthDate,
        new Date().toISOString().substring(0, 10)
      );

      return age.toString();
    },

    getPrioColor(prio: string): { hex: string; css: string } {
      return this.prioColor[prio as keyof typeof this.prioColor];
    },

    /**
     * Vid Browser Refresh är route.params.pages en sträng så den måste göras om till array
     */
    checkParamPages(pages: string | string[]): string[] {
      if (typeof pages === "object" && Array.isArray(pages)) {
        return pages;
      } else if (typeof pages === "string") {
        const array = pages.split("/");
        return array;
      }

      return [];
    },

    slugify(str: string | null | undefined, separator = "-"): string {
      if (!str) return "";

      return str
        .toString() // Cast to string (optional)
        .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, separator);
    },

    isValueNegative(value: number | null | undefined): boolean {
      if (!value) return false;

      return Math.sign(value) === -1;
    },

    async getFastavarden(fastVardeTyp: string): Promise<FastVarde[]> {
      let result = [] as FastVarde[];

      try {
        const crud = useCrudStore();

        result = (await crud.get({
          url: "Fastavarden",
          params: {
            fastVardeTyp: fastVardeTyp,
          },
        })) as FastVarde[];
      } catch (error) {
        //
      }

      return result;
    },

    percentage(partialValue: number, totalValue: number): number | null {
      if (partialValue === 0 && totalValue === 0) return null;

      return Math.round((100 * partialValue) / totalValue);
    },

    /**
     * Sortering från A till Ö med property namn
     */
    sortListByNamn(items: { namn: string }[]) {
      return items.sort((a, b) => {
        const namnA = a.namn.toUpperCase();
        const namnB = b.namn.toUpperCase();

        if (namnA < namnB) {
          return -1;
        }

        if (namnA > namnB) {
          return 1;
        }

        return 0;
      });
    },

    /**
     * Special funktioner
     */

    /**
     * Används vid autocomplete input när man kan ta bort och lägga till simultant
     */
    async getTillaggTaBortList(parent: Parent | null | undefined) {
      if (!parent) return;

      this.selected = [];
      this.previousSelected = [];

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `${parent.name}/${parent.id}/${parent.relation}`,
        })) as { id: number }[];

        this.selected = data.map((i) => i.id);
        this.previousSelected = data.map((i) => i.id);
      } catch (error) {
        //
      }
    },

    /**
     * Lägger till och tar bort i samma request
     */
    async submitTillaggTaBortList(parent: Parent | null | undefined) {
      if (!parent) return;

      const gamla = this.previousSelected;
      const nya = this.selected;
      const taBort = [];
      const tillagg = [];

      for (const g of gamla) {
        if (!nya.find((i) => i === g)) taBort.push(g);
      }

      for (const n of nya) {
        if (!gamla.find((i) => i === n)) tillagg.push(n);
      }

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const reload = useReloadStore();
        const form = useFormStore();

        await Promise.all([
          crud.put({
            url: `${parent.name}/${parent.id}/${parent.relation}/TaBort`,
            data: taBort,
          }),
          crud.put({
            url: `${parent.name}/${parent.id}/${parent.relation}/Tillagg`,
            data: tillagg,
          }),
        ]);

        snackbar.genericSnackbarMessage = `${taBort.length} borttagna och ${tillagg.length} lades till.`;
        snackbar.genericSnackbar = true;

        this.selected = [];
        this.previousSelected = [];

        reload.toggleReload("reloadAfterSubmitTillaggTaBortList");

        form.isDirty = false;
      } catch (error) {
        //
      }
    },
  },
});
