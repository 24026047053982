import { defineStore } from "pinia";

import { SavingName } from "@/models/saving";

export const useSavingStore = defineStore("saving", {
  state: () => ({
    savingKartlaggningMigration: false,
    savingFastval: false,
  }),

  getters: {},

  actions: {
    set(name: SavingName, value: boolean) {
      this[name] = value;
    },
  },
});
