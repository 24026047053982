
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent, PropType } from "vue";

import InputAutocompleteDefault from "@/components/input/autocomplete/InputAutocompleteDefault.vue";
import InputDateValidationWrapper from "@/components/input/date/InputDateValidationWrapper.vue";
import InputNumberDefault from "@/components/input/number/InputNumberDefault.vue";
import InputSelectDefault from "@/components/input/select/InputSelectDefault.vue";
import { FilterUrvalNamn } from "@/models/filterListgeneratorStatistik";
import { useFilterListgeneratorStatistikStore } from "@/store/filterListgeneratorStatistik";
import { useStatistikStore } from "@/store/statistik";

export default defineComponent({
  name: "FormFilterListgeneratorStatistik",

  components: {
    InputAutocompleteDefault,
    InputSelectDefault,
    InputDateValidationWrapper,
    InputNumberDefault,
  },

  props: {
    initForm: {
      type: Boolean,
      default: false,
    },

    dirty: {
      type: Boolean,
      default: false,
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    filterUrvalNamn: {
      type: String as PropType<FilterUrvalNamn>,
      required: true,
    },
  },

  data: () => ({
    loading: false,
  }),

  watch: {
    initForm: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.setExtraData();
          this.getData();
        }

        if (!value) {
          this.resetForm(true);
        }
      },
    },

    resetFilterForm: {
      handler(value: boolean) {
        if (value) {
          this.resetFilter();
        }
      },
    },

    dirty: {
      handler(value: boolean) {
        this.$emit("is-dirty", value);
      },
    },

    invalid: {
      handler(value: boolean) {
        this.$emit("is-invalid", value);
      },
    },

    /**
     * Statistik
     */
    statistikTypForm: {
      handler(value: string | null) {
        if (value && value === "overgripandeKaa") {
          this.filterForm.kaa = true;
        } else {
          this.filterForm.kaa = false;
        }
      },
    },
  },

  computed: {
    ...mapState(useFilterListgeneratorStatistikStore, [
      "inskrivningstypList",
      "loadingAnvandareGrupp",
      "inloggadAnvandareGruppList",
      "loadingFilter",
      "konList",
      "filterUrval",
      "statistikTypList",
      "urvalHuvudHandlaggareList",
    ]),
    ...mapWritableState(useFilterListgeneratorStatistikStore, [
      "resetFilterForm",
      "inskrivningstypForm",
      "filterForm",
      "filtreringsalternativArray",
      "filtreringsalternativObject",
      "filtreringsalternativString",
      "statistikTypForm",
      "urvalHuvudHandlaggareForm",
    ]),
  },

  methods: {
    ...mapActions(useFilterListgeneratorStatistikStore, [
      "getInloggadAnvandareGruppList",
      "getFilterUrval",
      "resetFiltreringar",
    ]),

    async getData() {
      await this.getInloggadAnvandareGruppList();
      await this.getFilterUrval(
        this.filtreringsalternativArray[0].value,
        this.filterUrvalNamn
      );
    },

    setExtraData() {
      this.inskrivningstypForm = "default";
      this.resetFilterForm = false;

      if (this.filterUrvalNamn === "Statistik") {
        this.statistikTypForm = "overgripandeAlla";
      }

      if (this.filterUrvalNamn === "List") {
        this.urvalHuvudHandlaggareForm = "beslutListA";
      }
    },

    resetFilter() {
      this.resetForm(false);
      this.getData();
    },

    resetForm(closingDialog: boolean) {
      const resetWithTheseValues = {
        statistikTyp: this.statistikTypForm,
        startdatum: this.filterForm.startdatum,
        slutdatum: this.filterForm.slutdatum,
      };

      this.resetFiltreringar();

      const statistik = useStatistikStore();
      statistik.resultData = null;

      if (closingDialog) {
        this.filterForm.startdatum = new Date().toISOString().substring(0, 10);
        this.filterForm.slutdatum = new Date().toISOString().substring(0, 10);
      }

      if (!closingDialog) {
        this.statistikTypForm = resetWithTheseValues.statistikTyp;
        this.filterForm.startdatum = resetWithTheseValues.startdatum;
        this.filterForm.slutdatum = resetWithTheseValues.slutdatum;
      }

      this.resetFilterForm = false;
    },
  },
});
