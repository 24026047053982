import { defineStore } from "pinia";

import { ReloadName } from "@/models/reload";

export const useReloadStore = defineStore("reload", {
  state: () => ({
    // kontaktuppgift
    reloadAfterAddEditKontaktuppgift: false,
    reloadAfterTaBortKontaktuppgift: false,
    reloadAfterSetPrimarKontaktuppgift: false,
    // adress
    reloadAfterAddEditAdress: false,
    reloadAfterTaBortAdress: false,
    reloadAfterSetPrimarAdress: false,
    reloadAdressComponent: false,
    // special
    reloadAfterSubmitTillaggTaBortList: false,
    // administration
    reloadAdministrationContentLayout: false,
    reloadAnvandareDetailView: false,
    reloadGruppDetailView: false,
    reloadFastvalDetailView: false,
    reloadAtkomstRollDetailView: false,
    reloadMatchningstypDetailView: false,
    reloadMatchningsfiltreringDetailView: false,
    reloadAfterAddSamlingAlternativ: false,
    // deltagare
    reloadDeltagareDefaultLayout: false,
    reloadDeltagareBeslutsparLayout: false,
    reloadBeslutsparOversiktAktuellView: false,
    reloadAfterEditResurs: false,
    reloadAfterAddResurs: false,
    reloadAfterAddExternResursBeslutspar: false,
    reloadAfterAddEditAnteckning: false,
    reloadAfterAddEditDeltagareBevakning: false,
    reloadAfterAddInternResursBeslutspar: false,
    reloadAfterRemoveInternResursBeslutspar: false,
    reloadAfterAddHandlingsplanBeslutspar: false,
    reloadAfterAddDetaljHandlingsplan: false,
    // matchningsfiltrering
    reloadAfterAddEditMatchningsfiltrering: false,
    // status
    reloadAfterUpdateStatus: false,
    // textmall
    reloadAfterAddUpdateTextMallHandlingsplan: false,
  }),

  actions: {
    /**
     * Gör en omladdning
     *
     * @since version 0.8.41
     */
    single(reloadName: ReloadName) {
      this[reloadName as keyof typeof this.$state] =
        !this[reloadName as keyof typeof this.$state];
    },

    /**
     * Gör flera omladdningar
     *
     * @since version 0.8.41
     */
    multiple(reloadNameList: ReloadName[]) {
      for (const name of reloadNameList) {
        this[name as keyof typeof this.$state] =
          !this[name as keyof typeof this.$state];
      }
    },

    /**
     * Använd reload.single eller reload.multiple istället
     *
     * @deprecated since version 0.8.41
     */
    toggleReload(name: ReloadName) {
      this[name as keyof typeof this.$state] =
        !this[name as keyof typeof this.$state];
    },
  },
});
