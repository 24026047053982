import { defineStore } from "pinia";

import { add, format, subtract } from "@/helpers/date";
import { ResetStepForm, StepperType } from "@/models/stepper";

import { useAdressStore } from "./adress";
import { useCrudStore } from "./crud";
import { useDeltagareStore } from "./deltagare";
import { useDialogStore } from "./dialog";
import { useInsatsStore } from "./insats";
import { useSnackbarStore } from "./snackbar";
import { useStatusStore } from "./status";
import { useUppdragStore } from "./uppdrag";

export const useStepperStore = defineStore("stepper", {
  state: () => ({
    loading: false,
    saving: false,

    serverValidationSuccess: false,

    deltagare: {
      personnummer: null as string | null,
      fornamn: null as string | null,
      efternamn: null as string | null,
      tillfalligtPersonnummer: false,
      epost: null as string | null,
      telefon: null as string | null,
    },

    insatsModel: {
      handlaggareId: null as number | null,
      fvInsatsId: null as number | null,
      fvProjektId: null as number | null,
      omfattning: 0,
      startdatum: new Date().toISOString().substring(0, 10),
      slutdatum: null as string | null,
      gruppId: null as number | null,
      avslutaInsatsId: [] as number[],
      isInternResurs: false,
    },

    beslut: {
      handlaggareId: null as number | null,
      fvBeslutId: null as number | null,
      fvProjektId: null as number | null,
      arendeNummer: null as string | null,
      startdatum: new Date().toISOString().substring(0, 10),
      slutdatum: null as string | null,
      avslutaBeslutId: [] as number[],
    },

    beslutspar: {
      fvIngangsvardeId: null as number | null,
      ersattningInId: null as number | null,
      fvStodnivaId: null as number | null,
      fvVerktygId: null as number | null,
      initiatorId: null as number | null,
      kaa: {
        senasteUtbildningId: null as number | null,
        utbildningsprogramId: null as number | null,
        skola: null as string | null,
      },
      gruppId: null as number | null,
    },

    visaHistorik: false,
    externResursId: [] as number[],
    internResursId: [] as number[],
    insatsId: [] as number[],
    kompletterandeBeslutId: [] as number[],

    isNyAktivitet: true,
    resetStepForm: null as ResetStepForm | null,
    isBytBeslutKaa: false,

    activeStepper: "none" as StepperType,
  }),

  getters: {
    /**
     * Minimum startdatum för nytt beslut på ny deltagare är 5 år bakåt från dagens datum
     */
    minStartdatumBeslut(): string {
      return format(
        subtract(new Date().toISOString().substring(0, 10), 5, "year")
      );
    },

    /**
     * Max startdatum för nytt beslut på ny deltagare är 1 mindre än beslutets startdatum om det är satt
     * annars är det en vecka framåt från dagens datum
     */
    maxStartdatumBeslut(state): string {
      return state.beslut.slutdatum
        ? format(subtract(state.beslut.slutdatum, 1, "day"))
        : format(add(new Date().toISOString().substring(0, 10), 1, "week"));
    },

    /**
     * Min slutdatum för nytt beslut på ny deltagare är minst en dag från beslutets startdatum
     */
    minSlutdatumBeslut(state): string {
      return format(add(state.beslut.startdatum, 1, "day"));
    },

    /**
     * Max slutdatum för nytt beslut på ny deltagare är 5 år framåt
     */
    maxSlutdatumBeslut(): string {
      return format(add(new Date().toISOString().substring(0, 10), 5, "year"));
    },

    /**
     * Måste vara gamla huvudbeslut.startdatum + 2 dagar, då nya beslutets startdatum - 1 dag = gamla huvudbeslut.slutdatum.
     *
     * @since Version 0.5.6
     */
    minStartdatumBytBeslut(): string {
      const deltagare = useDeltagareStore();

      return format(add(deltagare.huvudbeslut?.startdatum, 2, "day"));
    },

    /**
     * Max startdatum för nytt huvudbeslut.
     * Maximum en vecka framåt från dagens datum.
     *
     * @since Version 0.5.6
     */
    maxStartdatumBytBeslut(): string {
      return format(add(new Date().toISOString().substring(0, 10), 1, "week"));
    },

    /**
     * Min slutdatum för nytt huvudbeslut.
     *
     * @since Version 0.5.6
     */
    minSlutdatumBytBeslut(state): string {
      return format(add(state.beslut.startdatum, 1, "day"));
    },

    /**
     * Max slutdatum för nytt huvudbeslut.
     *
     * @since Version 0.5.6
     */
    maxSlutdatumBytBeslut(): string {
      return format(add(new Date().toISOString().substring(0, 10), 5, "year"));
    },

    /**
     * Minimum beslutets startdatum
     */
    minStartdatumAktivitet(state): string {
      return state.beslut.startdatum;
    },

    /**
     * Max startdatum för aktivitet är en dag minus slutdatum för aktivitet om det är satt
     * annars blir det beslutets slutdatum
     */
    maxStartdatumAktivitet(state): string {
      return state.insatsModel.slutdatum
        ? format(subtract(state.insatsModel.slutdatum, 1, "day"))
        : format(subtract(state.beslut.slutdatum, 1, "day"));
    },

    /**
     * Minimum sludatum för aktivitet är aktivitetens startdatum plus en dag
     */
    minSlutdatumAktivitet(state): string {
      return state.insatsModel.startdatum !== ""
        ? format(add(state.insatsModel.startdatum, 1, "day"))
        : "";
    },

    /**
     * Max slutdatum för aktivitet är beslutets slutdatum
     */
    maxSlutdatumAktivitet(state): string {
      return format(state.beslut.slutdatum);
    },

    /**
     * Används för att sätta slutdatum när man byter beslut eller skickar vidare deltagare.
     * Formel: Nytt huvudbeslut startdatum - en dag = gammalt huvudbeslut slutdatum.
     *
     * @since Version 0.6.1
     */
    foregaendeBeslutSlutdatum(state): string | null {
      if (state.beslut.startdatum === "") return null;
      return format(subtract(state.beslut.startdatum, 1, "day"));
    },
  },

  actions: {
    async validatePersonnummer() {
      try {
        const crud = useCrudStore();

        await crud.post({
          url: `Deltagare/Kontrollera/Personnummer/${this.deltagare.personnummer}`,
        });

        this.serverValidationSuccess = true;
      } catch (error) {
        this.serverValidationSuccess = false;
      }
    },

    async addDeltagare(): Promise<{
      id: string | null;
      beslutsparId: string | null;
    }> {
      this.saving = true;

      let routeParams = {
        id: null as string | null,
        beslutsparId: null as string | null,
      };

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const adress = useAdressStore();
        const uppdrag = useUppdragStore();
        const status = useStatusStore();

        const data = (await crud.post({
          url: "Deltagare",
          data: {
            deltagare: {
              ...this.deltagare,
              adress: adress.adressForm,
            },
            beslutsparModel: {
              beslutModel: {
                insatsModel: this.insatsModel,
                beslut: this.beslut,
              },
              beslutspar: this.beslutspar,
              uppdragId: uppdrag.selectedUppdragId,
            },
          },
        })) as { id: number };

        /**
         * Om det är ett uppdrag ska följande göras:
         *
         * PUT Uppdrag/{uppdragId}/Status/{status}
         * PUT Uppdrag/{uppdragId}/SendStatus
         *
         * @since version 0.8.42
         */
        if (uppdrag.isUppdrag) {
          status.statusToChange.name = "Uppdrag";
          status.statusToChange.id = uppdrag.selectedUppdragId;
          status.statusToChange.status = "accepterad";

          await status.updateStatus();
          await uppdrag.sendStatus(uppdrag.selectedUppdragId);

          uppdrag.selectedUppdragId = null;
          uppdrag.isUppdrag = false;
        }

        await deltagare.getBeslutsparList(data.id);

        routeParams = {
          id: data.id.toString(),
          beslutsparId: deltagare.beslutsparList[0].id.toString(),
        };

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }

      return routeParams;
    },

    async registreraNyGrupp(): Promise<number | null> {
      this.saving = true;

      let beslutsparId = null;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        beslutsparId = (await crud.post({
          url: `Deltagare/${deltagare.deltagareItem?.id}/Beslutspar`,
          data: {
            beslutModel: {
              insatsModel: this.insatsModel,
              beslut: this.beslut,
            },
            beslutspar: this.beslutspar,
          },
        })) as number;

        snackbar.genericSnackbarMessage = "Deltagare registrerad i ny grupp";
        snackbar.genericSnackbar = true;

        dialog.dialogRegistreraNyGrupp = false;

        await deltagare.getBeslutsparList(deltagare.deltagareItem?.id ?? null);

        this.saving = false;
      } catch (error) {
        beslutsparId = null;
        this.saving = false;
      }

      return beslutsparId;
    },

    async skickaVidareDeltagare(): Promise<{
      nyttBeslutsparId: number;
      redirectBeslutsparId: number;
    }> {
      let endpoint = "";
      let data = {
        nyttBeslutsparId: 0,
        redirectBeslutsparId: 0,
      };

      if (this.insatsId.length === 0) {
        endpoint = "BytBeslutsparNyInsats";
      }

      if (this.insatsId.length > 0) {
        endpoint = "BytBeslutsparMedInsatser";
      }

      this.saving = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        data = (await crud.post({
          url: `Beslutspar/${deltagare.beslutsparId}/${endpoint}`,
          data: {
            slutdatum: this.foregaendeBeslutSlutdatum,
            beslutModel: {
              insatsModel: this.insatsModel,
              beslut: this.beslut,
              ...this.beslut,
            },
            insatsId: this.insatsId,
            gruppId: this.beslutspar.gruppId,
            visaHistorik: this.visaHistorik,
            externResursId: this.externResursId,
            internResursId: this.internResursId,
          },
        })) as { nyttBeslutsparId: number; redirectBeslutsparId: number };

        snackbar.genericSnackbarMessage = "Deltagaren har skickats vidare";
        snackbar.genericSnackbar = true;

        dialog.dialogSkickaVidareDeltagare = false;

        await deltagare.getBeslutsparList(deltagare.deltagareItem?.id ?? null);

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }

      return data;
    },

    async bytHuvudbeslut(): Promise<void> {
      let endpoint = "";

      if (this.insatsId.length === 0) {
        endpoint = "BytBeslutNyInsats";
      }

      if (this.insatsId.length > 0) {
        endpoint = "BytBeslutMedInsatser";
      }

      this.saving = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await crud.post({
          url: `Beslutspar/${deltagare.beslutsparId}/${endpoint}`,
          data: {
            slutdatum: this.foregaendeBeslutSlutdatum,
            beslutModel: {
              insatsModel: this.insatsModel,
              beslut: this.beslut,
              ...this.beslut,
            },
            insatsId: this.insatsId,
            kompletterandeBeslutId: this.kompletterandeBeslutId,
          },
        });

        snackbar.genericSnackbarMessage = "Nytt huvudbeslut skapat";
        snackbar.genericSnackbar = true;

        dialog.dialogBytHuvudbeslut = false;

        await deltagare.getBeslutList(deltagare.beslutsparId);

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async bytBeslutKaa(): Promise<void> {
      this.saving = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const insats = useInsatsStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await crud.post({
          url: `Beslutspar/${deltagare.beslutsparId}/Kaa/BytBeslut`,
          data: {
            slutdatum: this.foregaendeBeslutSlutdatum,
            beslutModel: {
              insatsModel: this.insatsModel,
              beslut: this.beslut,
            },
            sysselsattning: {
              sysselsattningId: insats.sysselsattning.sysselsattningId,
              handlaggareId: this.insatsModel.handlaggareId,
            },
          },
        });

        snackbar.genericSnackbarMessage = "Nytt huvudbeslut skapat";
        snackbar.genericSnackbar = true;

        dialog.dialogBytBeslutKaa = false;

        await deltagare.getBeslutList(deltagare.beslutsparId);

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },
  },
});
