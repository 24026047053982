import { defineStore } from "pinia";

import { format } from "@/helpers/date";

export const useDateStore = defineStore("date", {
  state: () => ({}),

  actions: {
    formatDate(
      date: Date | string | null | undefined,
      stringFormat = "YYYY-MM-DD"
    ): string | null {
      if (!date) return null;

      return format(date, stringFormat);
    },
  },
});
