
import { mapWritableState } from "pinia";
import { defineComponent, PropType } from "vue";

import AAlert from "@/components/alert/AAlert.vue";
import ACard from "@/components/card/ACard.vue";
import InputTextDefault from "@/components/input/text/InputTextDefault.vue";
import { StepProps } from "@/models/stepper";
import { useStepperStore } from "@/store/stepper";

export default defineComponent({
  name: "StepPersonnummer",

  components: {
    ACard,
    AAlert,
    InputTextDefault,
  },

  props: {
    stepProps: {
      type: Object as PropType<StepProps>,
      required: true,
    },
  },

  computed: {
    ...mapWritableState(useStepperStore, ["deltagare"]),
  },
});
