
import { defineComponent, PropType } from "vue";

import { Plats } from "@/models/plats";
import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellPlaceringar",

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    isMatchad(): boolean {
      return this.item.plats?.matchad ?? false;
    },

    plats(): Plats | null {
      return this.item.plats ? this.item.plats : null;
    },
  },
});
