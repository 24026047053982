
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ACard",

  props: {
    hasToolbarTitleSlot: {
      type: Boolean,
      default: false,
    },

    toolbarTitle: {
      type: null as unknown as PropType<string | null>,
      default: null,
    },

    toolbarTitleClass: {
      type: String,
      default: "caption font-weight-bold",
    },

    toolbarColor: {
      type: String as PropType<string | null | undefined>,
      default: "primary lighten-2",
    },

    toolbarTitleId: {
      type: String,
      default: null,
    },

    hideToolbar: {
      type: Boolean,
      default: false,
    },

    cardBorderColor: {
      type: String,
      default: null,
    },

    contentClass: {
      type: String,
      default: "pa-1",
    },

    contentHeight: {
      type: Number as PropType<number>,
      default: 0,
    },

    cardWidth: {
      type: Number,
      default: 0,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    hasAction: {
      type: Boolean,
      default: false,
    },

    actionClass: {
      type: String,
      default: "py-1",
    },

    hasActionDivider: {
      type: Boolean,
      default: false,
    },

    hasOverlay: {
      type: Boolean,
      default: false,
    },
  },
});
