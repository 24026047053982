
import { ChartData } from "chart.js";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import { DataTableHeader } from "vuetify/types";

import ACard from "@/components/card/ACard.vue";
import ADataTableDefault from "@/components/table/ADataTableDefault.vue";
import { KeyNumber } from "@/models/statistik";
import { useHelpStore } from "@/store/help";
import { useStatistikStore } from "@/store/statistik";

import ABarChart from "./ABarChart.vue";

export default defineComponent({
  name: "AntalPerProjekt",

  components: {
    ACard,
    ADataTableDefault,
    ABarChart,
  },

  props: {
    resultDone: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    chartData: {
      labels: [] as string[],
      datasets: [
        {
          label: "Antal deltagare",
          data: [] as number[],
        },
      ],
    } as ChartData<"bar">,
  }),

  watch: {
    resultDone: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.prepareChartData();
        }
      },
    },
  },

  computed: {
    ...mapState(useStatistikStore, ["resultData"]),

    headers(): DataTableHeader[] {
      return [
        {
          text: "Projektgrupp",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
        {
          text: "Fördelning i %",
          value: "procent",
          sortable: false,
          align: "center",
        },
      ];
    },

    items(): [{ name: string; antal: number; procent: string }] {
      const array = [] as unknown as [
        { name: string; antal: number; procent: string }
      ];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerInsats.projekt as KeyNumber
      )) {
        const procent = this.percentage(
          value,
          this.resultData?.uppgifterPerInsats.totaltAntalAktivitet ?? 0
        );

        array.push({
          name: key,
          antal: value,
          procent: `${this.nullableString(procent?.toString(), "0")} %`,
        });
      }

      return array;
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString", "percentage"]),

    prepareChartData() {
      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerInsats.projekt as KeyNumber
      ).sort()) {
        this.chartData.labels?.push(key);
        this.chartData.datasets[0].data.push(value);
      }
    },
  },
});
