import { defineStore } from "pinia";

import { Beslutspar } from "@/models/beslutspar";
import { Deltagare } from "@/models/deltagare";
import { FilterData } from "@/models/filterListgeneratorStatistik";

import { useCrudStore } from "./crud";
import { useFilterListgeneratorStatistikStore } from "./filterListgeneratorStatistik";

export const useListGeneratorStore = defineStore("listgenerator", {
  state: () => ({
    loadingResult: false,

    resultData: {
      beslutspar: [] as Beslutspar[],
      deltagare: [] as Deltagare[],
    },
  }),

  getters: {},

  actions: {
    async getResultData(filterData: FilterData) {
      this.loadingResult = true;

      try {
        const crud = useCrudStore();
        const filterListgeneratorStatistik =
          useFilterListgeneratorStatistikStore();

        const data = (await crud.post({
          url: "List",
          data: {
            filtreringsalternativ: filterData,
            inskrivningstyp: filterListgeneratorStatistik.inskrivningstypForm,
            startdatum: filterListgeneratorStatistik.filterForm.startdatum,
            slutdatum: filterListgeneratorStatistik.filterForm.slutdatum,
          },
        })) as { beslutspar: Beslutspar[]; deltagare: Deltagare[] };

        this.resultData = data;

        this.loadingResult = false;
      } catch (error) {
        this.loadingResult = false;
      }
    },
  },
});
