
import { defineComponent, PropType } from "vue";

import { ArbetsstalleKontaktRoll } from "@/models/arbetsstalle";
import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellArbetsstalleRoll",

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    items(): ArbetsstalleKontaktRoll[] {
      return this.item.arbetsstalleKontaktRollList
        ? this.item.arbetsstalleKontaktRollList
        : [];
    },
  },
});
