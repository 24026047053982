import { defineStore } from "pinia";

import { Uppdrag, UppdragStatus } from "@/models/uppdrag";

import { useCrudStore } from "./crud";

export const useUppdragStore = defineStore("uppdrag", {
  state: () => ({
    uppdragList: [] as Uppdrag[],
    uppdragStatusList: [] as UppdragStatus[],
    selectedUppdragId: null as number | null,
    isUppdrag: false,
  }),

  getters: {},

  actions: {
    uppdragCount(): number {
      const inkomna = this.uppdragList.filter(
        (i) => i.uppdragStatus.namn === "Inkommen"
      );

      return inkomna.length;
    },

    uppdragStatusColor(status: string): string {
      switch (status) {
        case "Inkommen":
          return "orange lighten-4";
        case "Visad":
          return "blue lighten-4";
        case "Accepterad":
          return "success";
        default:
          return "orange lighten-4";
      }
    },

    async getUppdragList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Uppdrag",
        })) as Uppdrag[];

        this.uppdragList = data;
      } catch (error) {
        //
      }
    },

    async getUppdragStatusList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "UppdragStatus",
        })) as UppdragStatus[];

        this.uppdragStatusList = data;
      } catch (error) {
        //
      }
    },

    async sendStatus(uppdragId: number | null) {
      if (!uppdragId) return;

      try {
        const crud = useCrudStore();

        await crud.put({
          url: `Uppdrag/${uppdragId}/SendStatus`,
        });
      } catch (error) {
        //
      }
    },
  },
});
