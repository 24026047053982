import { defineStore } from "pinia";

import { add, format, subtract } from "@/helpers/date";
import { ArbetsstalleKontakt } from "@/models/arbetsstalle";
import { Placering, Plats } from "@/models/plats";

import { useArbetsstalleStore } from "./arbetsstalle";
import { useCrudStore } from "./crud";
import { useDialogStore } from "./dialog";
import { useSnackbarStore } from "./snackbar";

export const usePlatsStore = defineStore("plats", {
  state: () => ({
    platsList: [] as Plats[],
    platsPlaceringList: [] as Placering[],
    platsHandledareList: [] as ArbetsstalleKontakt[],

    platsForm: {
      avdelning: null as string | null,
      arbetsomradeId: null as number | null,
      antal: 0 as number | string,
      anteckning: null as string | null,
      ansvarigId: null as number | null,
      startdatum: new Date().toISOString().substring(0, 10),
      slutdatum: null as string | null,
    },

    selectedPlats: null as Plats | null,

    isEditPlats: false,
  }),

  getters: {
    /**
     * Ska kunna skapa en plats retroaktivt, sätter 1 år tillbaka
     * tillfälligt tills det har bestämts
     */
    minStartdatumNyPlats(): string {
      return format(
        subtract(new Date().toISOString().substring(0, 10), 1, "year")
      );
    },

    maxStartdatumNyPlats(): string {
      return format(add(new Date().toISOString().substring(0, 10), 1, "month"));
    },

    minSlutdatumNyPlats(state): string {
      return format(add(state.platsForm.startdatum, 1, "day"));
    },

    maxSlutdatumNyPlats(): string {
      return format(add(new Date().toISOString().substring(0, 10), 5, "year"));
    },

    selectedPlatsId(state): number | null {
      return state.selectedPlats?.id ?? null;
    },

    selectedPlatsArbetsomrade(state): string {
      return state.selectedPlats?.arbetsomrade.namn ?? "---";
    },

    selectedPlatsRamperiod(state): string {
      return `${format(state.selectedPlats?.startdatum)} - ${format(
        state.selectedPlats?.slutdatum
      )}`;
    },

    selectedPlatsAnsvarig(state): string {
      return state.selectedPlats?.ansvarig.namn ?? "---";
    },

    selectedPlatsAvdelning(state): string {
      return state.selectedPlats?.avdelning ?? "---";
    },

    selectedPlatsBeskrivning(state): string {
      return state.selectedPlats?.anteckning ?? "---";
    },

    selectedPlatsAktuell(state): boolean {
      return state.selectedPlats?.aktuell ?? false;
    },

    selectedPlatsStatus(state): string | null {
      return state.selectedPlats?.status ?? null;
    },
  },

  actions: {
    async addEditPlats(arbetsstalleId: number | null): Promise<void> {
      if (!arbetsstalleId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        if (this.isEditPlats) {
          // put
        }

        if (!this.isEditPlats) {
          await crud.post({
            url: `arbetsstalle/${arbetsstalleId}/plats`,
            data: this.platsForm,
          });
        }

        snackbar.genericSnackbarMessage = this.isEditPlats
          ? "Plats uppdaterad"
          : "Plats skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditPlats = false;

        this.getArbetsstallePlatsList(arbetsstalleId);
      } catch (error) {
        //
      }
    },

    async getArbetsstallePlatsList(
      arbetsstalleId: number | null,
      ejaktuella = false
    ) {
      this.platsList = [];

      if (!arbetsstalleId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `arbetsstalle/${arbetsstalleId}/plats`,
          params: {
            ejaktuella: ejaktuella,
          },
        })) as Plats[];

        this.platsList = data;
      } catch (error) {
        //
      }
    },

    async getSelectedPlats(platsId: number | null | undefined) {
      if (!platsId) return;

      try {
        const crud = useCrudStore();
        const arbetsstalle = useArbetsstalleStore();

        const data = (await crud.get({
          url: `plats/${platsId}`,
        })) as Plats;

        this.selectedPlats = data;

        await arbetsstalle.getSelectedArbetsstalle(data.arbetsstalleId);
      } catch (error) {
        //
      }
    },

    async getPlatsPlaceringList(platsId: number | null | undefined) {
      if (!platsId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `plats/${platsId}/placering`,
        })) as Placering[];

        this.platsPlaceringList = data;
      } catch (error) {
        //
      }
    },

    async getPlatsHandledareList(arbetsstalleId: number | null | undefined) {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `arbetsstalle/${arbetsstalleId}/kontakt`,
        })) as ArbetsstalleKontakt[];

        this.platsHandledareList = data.filter((i) => {
          return i.arbetsstalleKontaktRoll.some((r) => {
            return r.namn === "Handledare";
          });
        });
      } catch (error) {
        //
      }
    },
  },
});
