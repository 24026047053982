import { defineStore } from "pinia";

import {
  FilterData,
  FilterUrval,
  FilterUrvalNamn,
} from "@/models/filterListgeneratorStatistik";
import { AnvandareGrupp } from "@/models/grupp";

import { useCrudStore } from "./crud";

export const useFilterListgeneratorStatistikStore = defineStore(
  "filterListgeneratorStatistik",
  {
    state: () => ({
      loadingAnvandareGrupp: false,
      loadingFilter: false,
      loadingResult: false,

      inloggadAnvandareGruppList: [] as AnvandareGrupp[],

      filtreringsalternativArray: [
        {
          key: "filterGrupp",
          value: [] as number[],
        },
      ],

      filtreringsalternativObject: [
        {
          key: "filterAlder",
          value: {
            item1: null as string | null,
            item2: null as string | null,
          },
        },
      ],

      filtreringsalternativString: [
        {
          key: "filterBestallare",
          value: null as string | null,
        },
        {
          key: "filterInitierad",
          value: null as string | null,
        },
        {
          key: "filterErsattningIn",
          value: null as string | null,
        },
        {
          key: "filterErsattningUt",
          value: null as string | null,
        },
        {
          key: "filterStodniva",
          value: null as string | null,
        },
        {
          key: "filterVidareTill",
          value: null as string | null,
        },
        {
          key: "filterBeslut",
          value: null as string | null,
        },
        {
          key: "filterInsats",
          value: null as string | null,
        },
        {
          key: "filterBeslutStatus",
          value: null as string | null,
        },
        {
          key: "filterKon",
          value: null as string | null,
        },
      ],

      inskrivningstypForm: null as string | null,
      inskrivningstypList: [
        {
          id: "default",
          namn: "Inskrivna i spår",
        },
        {
          id: "ny",
          namn: "Nyinskrivna i spår",
        },
        {
          id: "avslutad",
          namn: "Avslutad från spår",
        },
      ],

      konList: [
        {
          id: "Man",
          namn: "Man",
        },
        {
          id: "Kvinna",
          namn: "Kvinna",
        },
      ],

      resetFilterForm: false,

      filterForm: {
        startdatum: new Date().toISOString().substring(0, 10),
        slutdatum: new Date().toISOString().substring(0, 10),
        kaa: false,
      },

      filterUrval: null as FilterUrval | null,

      statistikTypForm: null as string | null,
      statistikTypList: [
        {
          id: "overgripandeAlla",
          namn: "Övergripande alla",
        },
        {
          id: "overgripandeKaa",
          namn: "Övergripande KAA",
        },
      ],

      /**
       * Listgenerator val
       */
      urvalHuvudHandlaggareForm: null as string | null,
      urvalHuvudHandlaggareList: [
        {
          id: "beslutListA",
          namn: "Beslutslista",
        },
      ],
    }),

    getters: {
      filterData(state) {
        return {
          filtreringsalternativ: [
            ...state.filtreringsalternativArray,
            ...state.filtreringsalternativString,
            ...state.filtreringsalternativObject,
          ],
          inskrivningstyp: state.inskrivningstypForm,
          startdatum: state.filterForm.startdatum,
          slutdatum: state.filterForm.slutdatum,
          kaa: state.filterForm.kaa,
        };
      },

      inskrivningstypNamn(state): string {
        const typ = state.inskrivningstypList.find(
          (i) => i.id === state.inskrivningstypForm
        );

        if (typ !== undefined) {
          return typ.namn;
        }

        return "---";
      },

      inskrivningsperiodText(): string {
        return `${this.inskrivningstypNamn} under period ${this.filterData.startdatum} - ${this.filterData.slutdatum}`;
      },
    },

    actions: {
      async getInloggadAnvandareGruppList() {
        this.loadingAnvandareGrupp = true;

        try {
          const crud = useCrudStore();

          const data = (await crud.get({
            url: "InloggadAnvandare/Grupp",
          })) as AnvandareGrupp[];

          this.inloggadAnvandareGruppList = data;

          this.filtreringsalternativArray[0].value = data.map((i) => i.id);

          this.loadingAnvandareGrupp = false;
        } catch (error) {
          this.loadingAnvandareGrupp = false;
        }
      },

      async getFilterUrval(gruppId: number[], namn: FilterUrvalNamn) {
        this.loadingFilter = true;

        try {
          const crud = useCrudStore();

          const data = (await crud.post({
            url: `${namn}/Urval`,
            data: {
              gruppId: gruppId,
            },
          })) as FilterUrval;

          this.filterUrval = data;

          this.loadingFilter = false;
        } catch (error) {
          this.loadingFilter = false;
        }
      },

      async getOnlySetFilters(): Promise<FilterData> {
        const valdaFilter = [] as FilterData;

        for (const filter of this.filterData.filtreringsalternativ) {
          if (filter.key === "filterAlder") {
            if (filter.value) {
              const value = filter.value as unknown as {
                item1: string | null;
                item2: string | null;
              };

              if (value.item1 && value.item2) {
                valdaFilter.push({
                  key: filter.key,
                  value: value,
                });
              }
            }
          }

          if (filter.key !== "filterAlder") {
            if (filter.value) {
              valdaFilter.push(filter);
            }
          }
        }

        return valdaFilter;
      },

      setFiltreringar(
        filterData: FilterData,
        anvandareGruppList: AnvandareGrupp[]
      ): string {
        let filtreringar = "";

        for (const filter of filterData) {
          if (filter.key === "filterGrupp") {
            filtreringar += "Huvudhandläggargrupp: ";

            for (const gruppId of filter.value as number[]) {
              const grupp = anvandareGruppList.find((i) => i.id === gruppId);

              if (grupp) {
                filtreringar += `${grupp.namn} / `;
              }
            }
          }

          if (filter.key === "filterBestallare") {
            const bestallare = this.filterUrval?.bestallare.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (bestallare) {
              filtreringar += `Beställare: ${bestallare.namn} / `;
            }
          }

          if (filter.key === "filterInitierad") {
            const initierad = this.filterUrval?.initierad.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (initierad) {
              filtreringar += `Initierad av: ${initierad.namn} / `;
            }
          }

          if (filter.key === "filterErsattningIn") {
            const ersattning = this.filterUrval?.ersattningIn.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (ersattning) {
              filtreringar += `Ersättning in: ${ersattning.namn} / `;
            }
          }

          if (filter.key === "filterErsattningUt") {
            const ersattning = this.filterUrval?.ersattningUt.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (ersattning) {
              filtreringar += `Ersättning ut: ${ersattning.namn} / `;
            }
          }

          if (filter.key === "filterStodniva") {
            const stodniva = this.filterUrval?.stodniva.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (stodniva) {
              filtreringar += `Stödnivå: ${stodniva.namn} / `;
            }
          }

          if (filter.key === "filterVidareTill") {
            const resultat = this.filterUrval?.resultat.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (resultat) {
              filtreringar += `Vidare till: ${resultat.namn} / `;
            }
          }

          if (filter.key === "filterBeslut") {
            const beslut = this.filterUrval?.beslut.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (beslut) {
              filtreringar += `Beslut: ${beslut.namn} / `;
            }
          }

          if (filter.key === "filterInsats") {
            const insats = this.filterUrval?.insats.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (insats) {
              filtreringar += `Insats: ${insats.namn} / `;
            }
          }

          if (filter.key === "filterBeslutStatus") {
            const status = this.filterUrval?.status.find(
              (i) => i.id === (filter.value as unknown as number)
            );

            if (status) {
              filtreringar += `Status för beslut: ${status.namn} / `;
            }
          }

          if (filter.key === "filterKon") {
            const kon = this.konList.find((i) => i.id === filter.value);

            if (kon) {
              filtreringar += `Kön: ${kon.namn} / `;
            }
          }

          if (filter.key === "filterAlder") {
            const value = filter.value as unknown as {
              item1: string | null;
              item2: string | null;
            };

            filtreringar += `Ålder: ${value.item1} - ${value.item2}`;
          }
        }

        return filtreringar;
      },

      resetFiltreringar() {
        for (const item of this.filtreringsalternativArray) {
          item.value = [];
        }

        for (const item of this.filtreringsalternativObject) {
          item.value.item1 = null;
          item.value.item2 = null;
        }

        for (const item of this.filtreringsalternativString) {
          item.value = null;
        }
      },
    },
  }
);
