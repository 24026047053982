import { defineStore } from "pinia";

import {
  Arbetsstalle,
  ArbetsstalleIndex,
  ArbetsstalleKontakt,
  KontaktRoll,
  OrgTyp,
} from "@/models/arbetsstalle";
import { MultiList } from "@/models/list";

import { useAdressStore } from "./adress";
import { useCrudStore } from "./crud";
import { useDialogStore } from "./dialog";
import { useFormStore } from "./form";
import { useSnackbarStore } from "./snackbar";

export const useArbetsstalleStore = defineStore("arbetsstalle", {
  state: () => ({
    arbetsstalleIndexList: [] as MultiList[],
    arbetsstalleKontaktList: [] as ArbetsstalleKontakt[],
    arbetsstalleRollList: [] as KontaktRoll[],
    orgTypList: [] as OrgTyp[],

    arbetsstalleForm: {
      id: null as number | null,
      geografisktOmradeId: null as number | null,
      namn: null as string | null,
      orgTypId: null as number | null,
      orgnr: null as string | null,
    },

    arbetsstalleKontaktForm: {
      namn: null as string | null,
      epost: null as string | null,
      telefon: null as string | null,
      arbetsstalleRollId: [] as number[],
    },

    arbetsstalle: null as Arbetsstalle | null,
    selectedArbetsstalle: null as Arbetsstalle | null,
    selectedArbetsstalleKontakt: null as ArbetsstalleKontakt | null,

    maximeraOversikt: false,
  }),

  getters: {
    arbetsstalleNamn(state): string {
      return state.arbetsstalle?.namn ?? "---";
    },
  },

  actions: {
    async getArbetsstalleIndexList(ejaktuella = false) {
      this.arbetsstalleIndexList = [];

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Arbetsstalle",
          params: {
            ejaktuella: ejaktuella,
          },
        })) as ArbetsstalleIndex[];

        for (const item of data) {
          this.arbetsstalleIndexList.push({
            type: "nav",
            id: item.id,
            title: item.namn,
            icon: {
              name: "mdi-office-building",
              size: null,
              color: null,
            },
            disabled: false,
            aktuell: item.status === "Aktiv",
            status: item.status,
            route: {
              name: "arbetsstalle-detail-layout",
              params: {
                id: item.id,
              },
            },
            children: [],
          });
        }
      } catch (error) {
        //
      }
    },

    async addArbetsstalle(): Promise<{ id: number | null }> {
      let data = { id: null as number | null };

      try {
        const crud = useCrudStore();
        const adress = useAdressStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        data = (await crud.post({
          url: "arbetsstalle",
          data: {
            ...this.arbetsstalleForm,
            postadress: adress.adressForm,
            besoksadress: {
              ...adress.besoksadressForm,
              primar: true,
            },
          },
        })) as { id: number | null };

        snackbar.genericSnackbarMessage = "Nytt arbetsställe skapat";
        snackbar.genericSnackbar = true;

        dialog.dialogAddArbetsstalle = false;

        this.getArbetsstalleIndexList();
      } catch (error) {
        data = { id: null };
      }

      return data;
    },

    async updateArbetsstalle(arbetsstalleId: number) {
      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const form = useFormStore();

        await crud.put({
          url: `arbetsstalle/${arbetsstalleId}`,
          data: this.arbetsstalleForm,
        });

        form.isDirty = false;

        snackbar.genericSnackbarMessage = "Arbetsställe uppdaterat";
        snackbar.genericSnackbar = true;

        this.getArbetsstalleIndexList();
        this.getArbetsstalle(arbetsstalleId);
      } catch (error) {
        //
      }
    },

    async getOrgTypList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "OrgTyp",
        })) as OrgTyp[];

        this.orgTypList = data;
      } catch (error) {
        //
      }
    },

    async getArbetsstalle(
      arbetsstalleId: number | null | undefined,
      isForm = false
    ) {
      this.arbetsstalle = null;

      if (!arbetsstalleId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `arbetsstalle/${arbetsstalleId}`,
        })) as Arbetsstalle;

        this.arbetsstalle = data;

        // lägg till data till formulär
        if (isForm) {
          this.arbetsstalleForm.id = data.id;
          this.arbetsstalleForm.namn = data.namn;
          this.arbetsstalleForm.orgTypId = null;
          this.arbetsstalleForm.orgnr = data.orgnr;
          this.arbetsstalleForm.geografisktOmradeId = data.geografisktOmrade.id;
        }
      } catch (error) {
        //
      }
    },

    async getArbetsstalleKontaktList(
      arbetsstalleId: number | null | undefined,
      ejaktuella = false
    ) {
      this.arbetsstalleKontaktList = [];

      if (!arbetsstalleId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `arbetsstalle/${arbetsstalleId}/kontakt`,
          params: {
            ejaktuella: ejaktuella,
          },
        })) as ArbetsstalleKontakt[];

        this.arbetsstalleKontaktList = data;
      } catch (error) {
        //
      }
    },

    async getSelectedArbetsstalleKontakt(kontaktId: number | null | undefined) {
      if (!kontaktId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `ArbetsstalleKontakt/${kontaktId}`,
        })) as ArbetsstalleKontakt;

        this.selectedArbetsstalleKontakt = data;
      } catch (error) {
        //
      }
    },

    async addArbetsstalleKontakt(arbetsstalleId: number | null) {
      if (!arbetsstalleId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        const kontaktId = (await crud.post({
          url: `arbetsstalle/${arbetsstalleId}/kontakt`,
          data: this.arbetsstalleKontaktForm,
        })) as number;

        await this.tillaggArbetsstalleKontaktRoll(kontaktId);

        snackbar.genericSnackbarMessage = "Kontakt tillagd";
        snackbar.genericSnackbar = true;

        dialog.dialogAddArbetsstalleKontakt = false;

        this.getArbetsstalleKontaktList(arbetsstalleId);
      } catch (error) {
        //
      }
    },

    async getArbetsstalleKontaktToEdit(kontaktId: number | null | undefined) {
      if (!kontaktId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `ArbetsstalleKontakt/${kontaktId}`,
        })) as ArbetsstalleKontakt;

        this.arbetsstalleKontaktForm.namn = data.namn;
      } catch (error) {
        //
      }
    },

    async updateArbetsstalleKontakt(
      arbetsstalleId: number,
      kontaktId: number | null | undefined
    ) {
      if (!kontaktId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const form = useFormStore();

        await crud.put({
          url: `ArbetsstalleKontakt/${kontaktId}`,
          data: {
            namn: this.arbetsstalleKontaktForm.namn,
          },
        });

        snackbar.genericSnackbarMessage = "Kontakt uppdaterad";
        snackbar.genericSnackbar = true;

        this.getArbetsstalleKontaktList(arbetsstalleId);
        this.getSelectedArbetsstalleKontakt(kontaktId);

        form.isDirty = false;
      } catch (error) {
        //
      }
    },

    async tillaggArbetsstalleKontaktRoll(kontaktId: number) {
      try {
        const crud = useCrudStore();

        await crud.put({
          url: `ArbetsstalleKontakt/${kontaktId}/Roll/Tillagg`,
          data: this.arbetsstalleKontaktForm.arbetsstalleRollId,
        });
      } catch (error) {
        //
      }
    },

    async getArbetsstalleRollList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "ArbetsstalleKontaktRoll",
        })) as KontaktRoll[];

        this.arbetsstalleRollList = data;
      } catch (error) {
        //
      }
    },

    async getSelectedArbetsstalle(arbetsstalleId: number | null | undefined) {
      if (!arbetsstalleId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Arbetsstalle/${arbetsstalleId}`,
        })) as Arbetsstalle;

        this.selectedArbetsstalle = data;
      } catch (error) {
        //
      }
    },
  },
});
