
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import { DataTableHeader } from "vuetify/types";

import ACard from "@/components/card/ACard.vue";
import ADataTableDefault from "@/components/table/ADataTableDefault.vue";
import { useHelpStore } from "@/store/help";
import { useStatistikStore } from "@/store/statistik";

export default defineComponent({
  name: "AktivitetsperiodDagar",

  components: {
    ACard,
    ADataTableDefault,
  },

  props: {
    resultDone: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useStatistikStore, ["resultData"]),

    headers(): DataTableHeader[] {
      return [
        {
          text: "Aktivitet",
          value: "name",
          sortable: false,
        },
        {
          text: "Genomsnitt",
          value: "genomsnitt",
          sortable: false,
          align: "center",
        },
        {
          text: "Kortast",
          value: "kortast",
          sortable: false,
          align: "center",
        },
        {
          text: "Längst",
          value: "langst",
          sortable: false,
          align: "center",
        },
      ];
    },

    items(): [
      { name: string; genomsnitt: number; kortast: number; langst: number }
    ] {
      const array = [] as unknown as [
        { name: string; genomsnitt: number; kortast: number; langst: number }
      ];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerInsats.aktivitetDagar as {
          [key: string]: {
            genomsnitt: number;
            kortast: number;
            langst: number;
          };
        }
      )) {
        array.push({
          name: key,
          genomsnitt: value.genomsnitt,
          kortast: value.kortast,
          langst: value.langst,
        });
      }

      return array;
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString"]),
  },
});
