
import { ValidationProvider } from "vee-validate";
import { defineComponent, PropType } from "vue";

import InputDate from "./InputDate.vue";

export default defineComponent({
  name: "InputDateValidationWrapper",

  components: {
    ValidationProvider,
    InputDate,
  },

  props: {
    vid: {
      type: String,
      default: "ValidationVid",
    },

    mode: {
      type: String,
      default: "eager",
    },

    value: {
      type: String as PropType<string | null | undefined>,
      default: "",
      required: false,
    },

    label: {
      type: String,
      default: "label",
    },

    hint: {
      type: String,
      default: null,
    },

    required: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    min: {
      type: String,
      default: "",
    },

    max: {
      type: String,
      default: "",
    },

    prependIcon: {
      type: String,
      default: null,
    },

    setFocus: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
      default: null,
    },
  },
});
