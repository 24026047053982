import { defineStore } from "pinia";

import { add, format } from "@/helpers/date";
import { groupBy } from "@/helpers/sort";
import { Adress } from "@/models/adress";
import { Anteckning } from "@/models/anteckning";
import { Anvandare } from "@/models/anvandare";
import { Beslut } from "@/models/beslut";
import { Beslutspar, BeslutsparIndex } from "@/models/beslutspar";
import {
  Deltagare,
  DeltagareIndex,
  DeltagareIndexFilter,
} from "@/models/deltagare";
import { Dokument } from "@/models/dokumenthanterare";
import { DropdownMenuItem } from "@/models/dropdown";
import { FvBeslut, FvBestallare, FvInsats, FvProjekt } from "@/models/fastval";
import { Grupp } from "@/models/grupp";
import { Hushall, HushallStatus } from "@/models/hushall";
import { Insats, PlaneringFoljs } from "@/models/insats";
import { Kartlaggning } from "@/models/kartlaggning";
import { Kontaktuppgift } from "@/models/kontaktuppgift";
import { MultiList } from "@/models/list";
import { Matchningskrav } from "@/models/matchningskrav";
import { InternResurs } from "@/models/resurs";

import { useAdressStore } from "./adress";
import { useAuthStore } from "./auth";
import { useCrudStore } from "./crud";
import { useDialogStore } from "./dialog";
import { useExternResursStore } from "./externresurs";
import { useFormStore } from "./form";
import { useHelpStore } from "./help";
import { useKartlaggningStore } from "./kartlaggning";
import { useKontaktuppgiftStore } from "./kontaktuppgift";
import { useLoadingStore } from "./loading";
import { useReloadStore } from "./reload";
import { useSnackbarStore } from "./snackbar";
import { useStepperStore } from "./stepper";

export const useDeltagareStore = defineStore("deltagare", {
  state: () => ({
    loading: false,
    saving: false,
    deltagareIndexList: [] as MultiList[],
    beslutsparList: [] as BeslutsparIndex[],
    beslutsparItem: null as Beslutspar | null,
    maximeraOversikt: false,
    beslutList: [] as Beslut[],
    huvudbeslut: null as Beslut | null,
    aktivitetList: [] as Insats[],
    handelseList: [] as Insats[],
    planeringFoljsList: [] as PlaneringFoljs[],
    internResursList: [] as InternResurs[],
    beslutHistorikList: [] as Beslut[],
    deltagareItem: null as Deltagare | null,
    kartlaggningItem: null as Kartlaggning | null,

    /**
     * @todo flytta till hushall store
     */
    hushallItem: null as Hushall | null,

    kontaktuppgiftList: [] as Kontaktuppgift[],
    kaa: Object.freeze({
      u: {
        id: -1,
        namn: "KAA-U",
      },
      i: {
        id: -2,
        namn: "KAA-I",
      },
    }),
    kaauInsatsTyp: Object.freeze({
      bevakande: {
        id: -6,
        namn: "Bevakande",
      },
      uppsokande: {
        id: -5,
        namn: "Uppsökande",
      },
    }),
    beslutsparAnteckningList: [] as Anteckning[],
    dokumentList: [] as Dokument[],
    hushallStatusList: [] as HushallStatus[],
    matchningskravInsatsList: [] as Matchningskrav[],

    // deltagare list filter
    tilldeladeUrvalList: [
      {
        id: "allamina",
        namn: "Alla mina deltagare",
      },
      {
        id: "minaegna",
        namn: "Mina egna deltagare",
      },
      {
        id: "minatilldelade",
        namn: "Mina tilldelade deltagare",
      },
      {
        id: "", // ska vara tom för att få fram alla deltagare
        namn: "Alla deltagare",
      },
    ],

    /**
     * Denna läggs globalt för att filtering inte ska kunna
     * användas när man sökt, då det återställer resultatet
     *
     * @since version 0.8.42
     */
    isSearch: false,
    searchText: null as string | null,
    searchCount: 0,

    gruppList: [] as Grupp[],
    fvBestallareList: [] as FvBestallare[],
    fvBeslutList: [] as FvBeslut[],
    fvInsatsList: [] as FvInsats[],
    handlaggareList: [] as Anvandare[],
    fvProjektList: [] as FvProjekt[],

    resetFilterAndSearch: false,

    avslutaDeltagareForm: {
      slutdatum: new Date().toISOString().substring(0, 10),
      fvResultatId: null as number | null,
      ersattningUtId: null as number | null,
      ssykId: null as number | null,
    },

    personuppgiftForm: {
      personnummer: null as string | null,
      fornamn: null as string | null,
      efternamn: null as string | null,
    },

    deltagareSortByList: [
      {
        id: "fornamn",
        namn: "Förnamn",
      },
      {
        id: "efternamn",
        namn: "Efternamn",
      },
      {
        id: "personnummer",
        namn: "Personnummer",
      },
    ],

    deltagareFilter: {
      tilldeladeUrval: "allamina",
      gruppId: null as number | null,
      bestallareId: null as number | null,
      beslutId: null as number | null,
      insatsId: null as number | null,
      handlaggareId: null as number | null,
      projektId: null as number | null,
      sortBy: "fornamn",
      sortOrder: "asc",
    },
  }),

  getters: {
    beslutsparId(state): number | null {
      return state.beslutsparItem?.id ?? null;
    },

    beslutsparGruppId(state): number | null {
      return state.beslutsparItem?.grupp.id ?? null;
    },

    beslutsparGruppNamn(state): string | null {
      return state.beslutsparItem?.grupp.namn ?? null;
    },

    beslutsparVerktygNamn(state): string | null {
      return state.beslutsparItem?.beslutsparMetaData.fvVerktyg.namn ?? null;
    },

    isEtablering(): boolean {
      return this.beslutsparVerktygNamn === "Etablering";
    },

    beslutsparStatus(state): string | null {
      return state.beslutsparItem?.status ?? null;
    },

    huvudbeslutId(state): number | null {
      return state.huvudbeslut?.id ?? null;
    },

    huvudbeslutStartdatum(state): string {
      return format(state.huvudbeslut?.startdatum);
    },

    huvudbeslutSlutdatum(state): string {
      return format(state.huvudbeslut?.slutdatum);
    },

    huvudbeslutPeriod(): string {
      return `${this.huvudbeslutStartdatum} - ${this.huvudbeslutSlutdatum}`;
    },

    kompletterandeBeslutList(state): Beslut[] {
      return state.beslutList.filter((i) => !i.isHuvudbeslut);
    },

    deltagareFornamn(state): string {
      return state.deltagareItem?.fornamn ?? "---";
    },

    deltagareEfternamn(state): string {
      return state.deltagareItem?.efternamn ?? "---";
    },

    deltagareNamn(state): string | null {
      return state.deltagareItem?.namn ?? null;
    },

    personnummer(state): string | null {
      const help = useHelpStore();

      return state.deltagareItem?.personnummer
        ? help.formateraPersonnummer(state.deltagareItem?.personnummer)
        : null;
    },

    alder(state): string | null {
      const help = useHelpStore();

      return state.deltagareItem?.personnummer
        ? help.getAlder(state.deltagareItem.personnummer)
        : null;
    },

    personnrAlder(): string | null {
      const personnr = this.personnummer;
      const alder = this.alder;

      if (this.personnummer && this.alder) {
        return `${personnr} (${alder}) år`;
      }

      return null;
    },

    namnPersonnrAlder(): string | null {
      const namn = this.deltagareNamn;
      const personnr = this.personnummer;
      const alder = this.alder;

      if (namn && this.personnummer && alder) {
        return `${namn} / ${personnr} (${alder} år)`;
      }

      return null;
    },

    findHushallStatusNamn:
      (state) =>
      (deltagareId: number | undefined): string | null => {
        if (!state.hushallItem || !deltagareId) return null;

        const deltagare = state.hushallItem.deltagare.find(
          (i) => i.id === deltagareId
        );

        return deltagare?.hushallStatus?.namn ?? null;
      },

    findPrimarTelefon(state): string | null {
      const kontaktuppgift = useKontaktuppgiftStore();

      const items = kontaktuppgift.formatKontaktuppgiftList(
        state.kontaktuppgiftList
      );
      const telefon = items.find(
        (i) => i.kontaktuppgiftTyp.namn === "Telefon" && i.primar
      );

      return telefon?.varde ?? null;
    },

    findPrimarEpost(state): string | null {
      const kontaktuppgift = useKontaktuppgiftStore();

      const items = kontaktuppgift.formatKontaktuppgiftList(
        state.kontaktuppgiftList
      );

      const epost = items.find(
        (i) => i.kontaktuppgiftTyp.namn === "Epost" && i.primar
      );

      return epost?.varde ?? null;
    },

    findKompletterandeBeslutList(state): Beslut[] {
      const stepper = useStepperStore();
      const array = [] as Beslut[];

      for (const id of stepper.kompletterandeBeslutId) {
        const beslut = state.beslutList.find((i) => i.id === id) as Beslut;

        array.push(beslut);
      }

      return array;
    },

    findTaMedAktiviteterList(state): Insats[] {
      const stepper = useStepperStore();
      const array = [] as Insats[];

      for (const id of stepper.insatsId) {
        const insats = state.aktivitetList.find((i) => i.id === id) as Insats;

        array.push(insats);
      }

      return array;
    },

    findTaMedInternResursList(state): InternResurs[] {
      const stepper = useStepperStore();
      const array = [] as InternResurs[];

      for (const id of stepper.internResursId) {
        const resurs = state.internResursList.find(
          (i) => i.id === id
        ) as InternResurs;

        array.push(resurs);
      }

      return array;
    },

    findPrimarIceKontaktNamn(state): string {
      return (
        state.kartlaggningItem?.kartlaggningICE.kartlaggningKontakt.find(
          (i) => i.primar
        )?.namn ?? "---"
      );
    },

    findPrimarIceKontaktTelefon(state): string {
      const kontaktuppgift = useKontaktuppgiftStore();

      const primar =
        state.kartlaggningItem?.kartlaggningICE.kartlaggningKontakt.find(
          (i) => i.primar
        );
      const telefon = primar?.kontaktuppgift.find(
        (i) => i.kontaktuppgiftTyp.namn === "Telefon" && i.primar
      )?.varde;

      return telefon ? kontaktuppgift.formateraTelefon(telefon) : "---";
    },

    isKAAU(state): boolean {
      return state.huvudbeslut?.fvBeslut.id === this.kaa.u.id;
    },

    isKAAI(state): boolean {
      return state.huvudbeslut?.fvBeslut.id === this.kaa.i.id;
    },

    test: () => {
      return (disableAll: boolean) => [
        {
          title: "test",
          disable: disableAll,
        },
      ];
    },

    beslutsparMenuList: () => {
      const auth = useAuthStore();

      return (disabled = false) =>
        [
          { header: "hantera" },
          {
            title: "Huvudbeslut",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogBytHuvudbeslut" },
          },
          {
            title: "Kompletterande beslut",
            icon: "mdi-plus",
            disabled: disabled,
            emit: {
              name: "open-dialog",
              value: "dialogAddKompletterandeBeslut",
            },
          },
          {
            title: "Aktivitet",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAddAktivitet" },
          },
          {
            title: "Händelse",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAddHandelse" },
          },
          {
            title: "Planering följs",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAddPlaneringFoljs" },
          },
          {
            title: "Intern resurs",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAddInternResurs" },
          },
          {
            title: "Extern resurs",
            icon: "mdi-plus",
            disabled: disabled,
            emit: {
              name: "open-dialog",
              value: "dialogAddExternResursBeslutspar",
            },
          },
          {
            title: "Avsluta deltagare",
            icon: "mdi-check",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAvslutaDeltagare" },
          },
          {
            title: "Skicka vidare deltagare",
            icon: "mdi-fast-forward",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogSkickaVidareDeltagare" },
          },
          {
            title: "Registrera i ny grupp",
            icon: "mdi-plus",
            disabled: !auth.hasAtkomst(
              auth.atkomstEnum["Utförare - Nytt Parallellt Beslutsspår"]
            ),
            emit: { name: "open-dialog", value: "dialogRegistreraNyGrupp" },
          },
        ] as DropdownMenuItem[];
    },

    /**
     * KAA-I, ska även ha alla funktioner som ett vanligt beslutsspår
     */
    beslutsparKAAIMenuList: () => {
      return (disabled = false) =>
        [
          {
            title: "Aktivitet",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAddInsatsKaai" },
          },
        ] as DropdownMenuItem[];
    },

    /**
     * För att lägga till sysselsättning i meny under KAA-I / KAA-U
     */
    beslutsparKAASysselsattningMenuList: () => {
      return (disabled = false) =>
        [
          {
            title: "Sysselsättning vid kontakt",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAddSysselsattning" },
          },
        ] as DropdownMenuItem[];
    },

    /**
     * Extra meny för KAA-I o KAA-U
     */
    beslutsparKaaExtraMenuList: () => {
      const auth = useAuthStore();

      return (disabled = false) =>
        [
          {
            title: "Planering följs",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAddPlaneringFoljs" },
          },
          {
            title: "Intern resurs",
            icon: "mdi-plus",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAddInternResurs" },
          },
          {
            title: "Extern resurs",
            icon: "mdi-plus",
            disabled: disabled,
            emit: {
              name: "open-dialog",
              value: "dialogAddExternResursBeslutspar",
            },
          },
          {
            title: "Avsluta deltagare",
            icon: "mdi-check",
            disabled: disabled,
            emit: { name: "open-dialog", value: "dialogAvslutaDeltagare" },
          },
          {
            title: "Registrera i ny grupp",
            icon: "mdi-plus",
            disabled: !auth.hasAtkomst(
              auth.atkomstEnum["Utförare - Nytt Parallellt Beslutsspår"]
            ),
            emit: { name: "open-dialog", value: "dialogRegistreraNyGrupp" },
          },
        ] as DropdownMenuItem[];
    },

    /**
     * Kontrollerar om första aktiviteten är bevakande om beslutet är KAAU.
     * Detta görs för man bara kan växla mellan bevakande / uppsökande aktivitet
     */
    isBevakande(): boolean {
      if (this.aktivitetList.length === 0) return false;

      return false;
    },

    /**
     * Kontrollerar om första aktiviteten är uppsökande om beslutet är KAAU.
     */
    isUppsokande(): boolean {
      if (this.aktivitetList.length === 0) return false;

      if (this.isKAAU) {
        if (
          this.aktivitetList[0].fvInsatsId === this.kaauInsatsTyp.uppsokande.id
        )
          return true;
      }

      return false;
    },

    hasHushallsbild(state): boolean {
      return (
        typeof state.hushallItem === "object" && state.hushallItem !== null
      );
    },

    hushallAdress(state): Adress | null {
      const adress = useAdressStore();

      return state.hushallItem?.adress
        ? adress.setAdress(state.hushallItem.adress)
        : null;
    },

    hushallMedlemList(state): Deltagare[] {
      if (state.hushallItem === null) return [];

      const help = useHelpStore();

      return state.hushallItem.deltagare.map((item) => ({
        ...item,
        personnummer: help.formateraPersonnummer(item.personnummer),
        cannotRemove: item.hushallStatus?.namn === "Registerledare",
      }));
    },

    registerledare(state): Deltagare | null {
      if (state.hushallItem === null) return null;

      const help = useHelpStore();

      for (const deltagare of state.hushallItem.deltagare) {
        if (
          deltagare.hushallStatus &&
          deltagare.hushallStatus.namn === "Registerledare"
        ) {
          return {
            ...deltagare,
            personnummer: help.formateraPersonnummer(deltagare.personnummer),
          };
        }
      }

      return null;
    },

    civilstand(state): string | undefined {
      return state.kartlaggningItem?.etablering?.civilstand;
    },

    filterOutHushallStatus:
      (state) =>
      (hushallStatus: string): HushallStatus[] => {
        return state.hushallStatusList.filter((i) => i.namn !== hushallStatus);
      },

    /**
     * Man ska kunna avsluta från en dag plus av huvudbeslutets startdatum
     *
     * @since version 0.5.8
     */
    minSlutdatumAvslutaDeltagare(state): string {
      return format(add(state.huvudbeslut?.startdatum, 1, "day"));
    },

    /**
     * 5 dagar framåt max
     *
     * @since version 0.5.8
     */
    maxSlutdatumAvslutaDeltagare(): string {
      return format(add(new Date().toISOString().substring(0, 10), 5, "day"));
    },
  },

  actions: {
    /**
     * Inga referenser kan finnas till filter params, för att de måste kunna nollställas
     */
    prepareFilterParams(deltagareFilter: DeltagareIndexFilter) {
      const filter = {} as { [key: string]: string | number };

      for (const [key, value] of Object.entries(deltagareFilter)) {
        if (value !== null && value !== "") {
          filter[key] = value;
        }
      }

      return filter;
    },

    resetDeltagareFilter() {
      this.deltagareFilter.tilldeladeUrval = "allamina";
      this.deltagareFilter.gruppId = null;
      this.deltagareFilter.bestallareId = null;
      this.deltagareFilter.beslutId = null;
      this.deltagareFilter.insatsId = null;
      this.deltagareFilter.handlaggareId = null;
      this.deltagareFilter.projektId = null;
      this.deltagareFilter.sortBy = "fornamn";
      this.deltagareFilter.sortOrder = "asc";

      this.resetFilterAndSearch = true;
    },

    async getDeltagareIndexList(valtFilter: {
      [key: string]: string | number;
    }) {
      this.deltagareIndexList = [];

      const loading = useLoadingStore();

      loading.set("loadingDeltagareIndex", true);

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Deltagare",
          params: valtFilter,
        })) as DeltagareIndex[];

        this.createDeltagareIndexList(data);

        loading.set("loadingDeltagareIndex", false);
      } catch (error) {
        loading.set("loadingDeltagareIndex", false);
      }
    },

    /**
     * Deltagare Sök
     */
    async searchDeltagare() {
      this.deltagareIndexList = [];

      const loading = useLoadingStore();

      loading.set("loadingDeltagareIndex", true);

      this.searchCount = 0;

      try {
        const crud = useCrudStore();

        const data = (await crud.post({
          url: "Deltagare/Sok",
          data: {
            sokord: this.searchText,
          },
        })) as DeltagareIndex[];

        this.createDeltagareIndexList(data);
        this.searchCount = data.length;

        loading.set("loadingDeltagareIndex", false);
      } catch (error) {
        loading.set("loadingDeltagareIndex", false);
      }
    },

    createDeltagareIndexList(data: DeltagareIndex[]) {
      for (const item of data) {
        this.deltagareIndexList.push({
          type: "nav",
          id: item.id,
          title: item.namn,
          icon: {
            name: "mdi-account",
            size: null,
            color: null,
          },
          disabled: false,
          aktuell: item.status === "Aktiv",
          status: item.status,
          route: {
            name: item.beslutsparId
              ? "deltagare-beslutspar-oversikt-aktuellt-view"
              : "deltagare-beslutspar-layout",
            params: {
              id: item.id,
              beslutsparId: item.beslutsparId,
            },
          },
          children: [],
        });
      }
    },

    /**
     * Används för att hämta och sortera beslutsspåren i spårkomponenten
     *
     * @since version 0.8.7
     */
    async getBeslutsparList(deltagareId: number | null) {
      this.beslutsparList = [];

      if (!deltagareId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Deltagare/${deltagareId}/Beslutspar`,
          params: {
            ejaktuella: true,
          },
        })) as BeslutsparIndex[];

        const groupByStatusList = groupBy(data, (i) => i.status);
        let aktivList = [] as BeslutsparIndex[];
        let avslutadList = [] as BeslutsparIndex[];

        /**
         * För att jämföra datum i Typescript måste valueOf() användas så att det blir rätt datatyp.
         *
         * @see https://stackoverflow.com/questions/36560806/the-left-hand-side-of-an-arithmetic-operation-must-be-of-type-any-number-or
         */
        if ("Aktiv" in groupByStatusList) {
          aktivList = groupByStatusList["Aktiv"].sort(
            (a, b) =>
              new Date(b.startdatum).valueOf() -
              new Date(a.startdatum).valueOf()
          );
        }

        if ("Avslutad" in groupByStatusList) {
          avslutadList = groupByStatusList["Avslutad"].sort(
            (a, b) =>
              new Date(b.startdatum).valueOf() -
              new Date(a.startdatum).valueOf()
          );
        }

        const mergedList = [...aktivList, ...avslutadList];

        this.beslutsparList = mergedList;
      } catch (error) {
        //
      }
    },

    async getBeslutsparItem(beslutsparId: number | null | undefined) {
      this.beslutsparItem = null;

      if (!beslutsparId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Beslutspar/${beslutsparId}`,
        })) as Beslutspar;

        this.beslutsparItem = data;
      } catch (error) {
        //
      }
    },

    async getBeslutList(beslutsparId: number | null) {
      const externResurs = useExternResursStore();

      this.beslutList = [];
      this.huvudbeslut = null;
      this.aktivitetList = [];
      this.handelseList = [];
      this.internResursList = [];
      externResurs.beslutsparExternResursList = [];

      if (!beslutsparId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `beslutspar/${beslutsparId}/beslut`,
          params: {
            ejaktuella: false,
          },
        })) as Beslut[];

        this.beslutList = data;

        this.huvudbeslut = this.beslutList.find(
          (i) => i.isHuvudbeslut
        ) as Beslut;

        await this.getInsatsList(this.huvudbeslutId);

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getInsatsList(huvudbeslutId: number | null) {
      if (!huvudbeslutId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Beslut/${huvudbeslutId}/Insats`,
          params: {
            ejaktuella: false,
          },
        })) as Insats[];

        if (data.length > 0) {
          this.aktivitetList = data.filter(
            (i) => i.fvInsats.fvInsatsTyp?.namn === "Aktivitet"
          ) as Insats[];

          this.handelseList = data.filter(
            (i) => i.fvInsats.fvInsatsTyp?.namn === "Händelse"
          ) as Insats[];
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getBeslutsparInternResursList(beslutsparId: number | null) {
      if (!beslutsparId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `beslutspar/${beslutsparId}/internresurs`,
        })) as InternResurs[];

        this.internResursList = data;
      } catch (error) {
        //
      }
    },

    async getBeslutHistorikList(beslutsparId: number | null) {
      if (!beslutsparId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Beslutspar/${beslutsparId}/BeslutHistorik`,
        })) as Beslut[];

        /**
         * Denna sortering ser till att senaste huvudbeslut kommer först
         *
         * @since version 0.9.1
         */
        this.beslutHistorikList = data.sort((a, b) => b.id - a.id);

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getDeltagareItem(deltagareId: number | null | undefined) {
      this.deltagareItem = null;

      if (!deltagareId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Deltagare/${deltagareId}`,
        })) as Deltagare;

        this.deltagareItem = data;
      } catch (error) {
        //
      }
    },

    async getBeslutsparKartlaggning(beslutsparId: number | null | undefined) {
      this.kartlaggningItem = null;

      if (!beslutsparId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `beslutspar/${beslutsparId}/kartlaggning`,
        })) as Kartlaggning;

        this.kartlaggningItem = data;
      } catch (error) {
        //
      }
    },

    async getDeltagareHushallItem(deltagareId: number | null) {
      this.hushallItem = null;

      if (!deltagareId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Deltagare/${deltagareId}/Hushall`,
        })) as Hushall;

        if (typeof data === "object") {
          this.hushallItem = data;
        } else {
          this.hushallItem = null;
        }
      } catch (error) {
        //
      }
    },

    async getHushallStatusList() {
      this.loading = false;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "HushallStatus",
        })) as HushallStatus[];

        this.hushallStatusList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getDeltagareKontaktuppgiftList(deltagareId: number) {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `deltagare/${deltagareId}/kontaktuppgift`,
        })) as Kontaktuppgift[];

        this.kontaktuppgiftList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getBeslutsparAnteckningList(beslutsparId: number | null) {
      this.beslutsparAnteckningList = [];

      if (!beslutsparId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `beslutspar/${beslutsparId}/anteckning`,
          params: {
            ejaktuella: true,
            sortBy: "datum",
            sortOrder: "desc",
          },
        })) as Anteckning[];

        this.beslutsparAnteckningList = data;
      } catch (error) {
        //
      }
    },

    async getMatchningskravInsatsList(insatsId: number | null | undefined) {
      if (!insatsId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `insats/${insatsId}/sokomrade`,
        })) as Matchningskrav[];

        this.matchningskravInsatsList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getDokumentList(beslutsparId: number | null) {
      if (!beslutsparId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `beslutspar/${beslutsparId}/dokumenthanterare`,
        })) as Dokument[];

        this.dokumentList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFile(beslutsparId: number, file: Dokument) {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const dokument = (await crud.download({
          url: `beslutspar/${beslutsparId}/dokumenthanterare/${file.id}`,
        })) as Blob;

        if (dokument) {
          const url = window.URL.createObjectURL(
            new Blob([dokument], { type: file.fileMetadata.fileType })
          );

          const link = document.createElement("a");

          link.href = url;

          link.setAttribute("target", "_blank");

          document.body.appendChild(link);

          link.click();

          // The URL lifetime is tied to the document in the window on which it was created
          // därför revoke vi här för att spara minne
          window.URL.revokeObjectURL(url);
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * Deltagare filter
     */
    async getDeltagareFilterData() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await Promise.all([
          crud.get({
            url: "InloggadAnvandare/Grupp",
          }),
          crud.get({
            url: "FvBestallare",
            params: {
              init: false,
            },
          }),
          crud.get({
            url: "FvBeslut",
          }),
          crud.get({
            url: "FvAktivitet",
          }),
          crud.get({
            url: "Anvandare",
          }),
          crud.get({
            url: "FvProjekt",
          }),
        ])) as [
          Grupp[],
          FvBestallare[],
          FvBeslut[],
          FvInsats[],
          Anvandare[],
          FvProjekt[]
        ];

        this.gruppList = data[0];
        this.fvBestallareList = data[1];
        this.fvBeslutList = data[2];
        this.fvInsatsList = data[3];
        this.handlaggareList = data[4];
        this.fvProjektList = data[5];

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * Avsluta deltagare
     */
    async avslutaDeltagare() {
      let success = false;

      try {
        const crud = useCrudStore();
        const kartlaggning = useKartlaggningStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.post({
          url: `Beslutspar/${this.beslutsparId}/Avsluta`,
          data: {
            ...this.avslutaDeltagareForm,
            ssykId: kartlaggning.ssykId,
          },
        });

        snackbar.genericSnackbarMessage = "Deltagare avslutad";
        snackbar.genericSnackbar = true;

        dialog.dialogAvslutaDeltagare = false;

        reload.toggleReload("reloadDeltagareDefaultLayout");

        success = true;
      } catch (error) {
        success = false;
      }

      return success;
    },

    /**
     * Redigera personuppgifter
     */
    async getPersonuppgiftDeltagareToEdit(
      deltagareId: number | null | undefined
    ) {
      if (!deltagareId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `deltagare/${deltagareId}`,
        })) as Deltagare;

        this.personuppgiftForm.personnummer = data.personnummer;
        this.personuppgiftForm.fornamn = data.fornamn;
        this.personuppgiftForm.efternamn = data.efternamn;
      } catch (error) {
        //
      }
    },

    async updatePersonuppgiftDeltagare(deltagareId: number | null | undefined) {
      if (!deltagareId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const form = useFormStore();
        const reload = useReloadStore();

        await crud.put({
          url: `deltagare/${deltagareId}`,
          data: this.personuppgiftForm,
        });

        snackbar.genericSnackbarMessage = "Personuppgifter uppdaterande";
        snackbar.genericSnackbar = true;

        form.isDirty = false;

        // reload deltagareList, deltagareItem
        reload.toggleReload("reloadDeltagareDefaultLayout");
        reload.toggleReload("reloadDeltagareBeslutsparLayout");
      } catch (error) {
        //
      }
    },

    /**
     * KAA dropdown items som finns på både KAA-U / KAA-I
     */
    beslutsparKAAMenuList(disabled = false): DropdownMenuItem[] {
      return [
        { header: this.isKAAI ? "hantera - kaa-i" : "hantera - kaa-u" },
        {
          title: this.isKAAI
            ? "KAA - Uppsökande / Bevakande"
            : "KAA - Insats från kommunen",
          icon: "mdi-swap-horizontal",
          disabled: disabled,
          emit: { name: "open-dialog", value: "dialogBytBeslutKaa" },
        },
      ];
    },

    /**
     * KAA-U
     */
    beslutsparKAAUMenuList(disabled = false): DropdownMenuItem[] {
      return [
        {
          title: this.isBevakande ? "Uppsökande" : "Bevakande",
          icon: this.isBevakande ? "mdi-eye-plus" : "mdi-eye",
          disabled: disabled,
          emit: { name: "open-dialog", value: "dialogBytInsatsKaau" },
        },
      ];
    },
  },
});
