
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import { DataTableHeader } from "vuetify/types";

import ACard from "@/components/card/ACard.vue";
import ADataTableDefault from "@/components/table/ADataTableDefault.vue";
import { useFilterListgeneratorStatistikStore } from "@/store/filterListgeneratorStatistik";
import { useStatistikStore } from "@/store/statistik";

export default defineComponent({
  name: "InskrivningsperioderVeckor",

  components: {
    ACard,
    ADataTableDefault,
  },

  props: {
    resultDone: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useFilterListgeneratorStatistikStore, ["filterData"]),
    ...mapState(useStatistikStore, ["resultData"]),

    perKonHeaders(): DataTableHeader[] {
      return [
        {
          text: "Kön",
          value: "name",
          sortable: false,
          width: 200,
        },
        {
          text: "Totalt",
          value: "total",
          sortable: false,
        },
      ];
    },

    perKonItems(): [{ name: string; total: number }] {
      const array = [] as unknown as [{ name: string; total: number }];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerVeckor.konVeckor as {
          [key: string]: number;
        }
      )) {
        const keyName = this.konKeyName(key);
        array.push({ name: keyName, total: value });
      }

      return array;
    },

    perAlderHeaders(): DataTableHeader[] {
      return [
        {
          text: "Åldersgrupp",
          value: "name",
          sortable: false,
          width: 200,
        },
        {
          text: "Totalt",
          value: "total",
          sortable: false,
        },
      ];
    },

    perAlderItems(): [{ name: string; total: number }] {
      const array = [] as unknown as [{ name: string; total: number }];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerVeckor.alderVeckor as {
          [key: string]: number;
        }
      )) {
        const keyName = this.alderKeyName(key);
        array.push({ name: keyName, total: value });
      }

      return array;
    },

    perFodelseArHeaders(): DataTableHeader[] {
      return [
        {
          text: "Åldersgrupp",
          value: "name",
          sortable: false,
          width: 200,
        },
        {
          text: "Totalt",
          value: "total",
          sortable: false,
        },
      ];
    },

    perFodelseArItems(): [{ name: string; total: number }] {
      const array = [] as unknown as [{ name: string; total: number }];

      for (const [key, value] of Object.entries(
        this.resultData?.kaaUppgifter.fodelseAr as {
          [key: string]: {
            aktuella: number;
            avslutade: number;
            totalt: number;
          };
        }
      )) {
        array.push({ name: key, total: value.totalt });
      }

      return array;
    },

    perErsattningHeaders(): DataTableHeader[] {
      return [
        {
          text: "Ersättning",
          value: "name",
          sortable: false,
          width: 200,
        },
        {
          text: "Totalt",
          value: "total",
          sortable: false,
        },
      ];
    },

    perErsattningItems(): [{ name: string; total: number }] {
      const array = [] as unknown as [{ name: string; total: number }];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerVeckor.ersattningVeckor as {
          [key: string]: number;
        }
      )) {
        array.push({ name: key, total: value });
      }

      return array;
    },
  },

  methods: {
    ...mapActions(useStatistikStore, ["konKeyName", "alderKeyName"]),
  },
});
