
import { mapActions, mapState } from "pinia";
import { defineComponent, PropType } from "vue";

import { StatusTyp } from "@/models/status";
import { useStatusStore } from "@/store/status";

export default defineComponent({
  name: "InputStatus",

  props: {
    initField: {
      type: Boolean,
      default: false,
    },

    resetField: {
      type: Boolean,
      default: false,
    },

    datum: {
      type: null as unknown as PropType<string | null>,
      required: true,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    statusTyp: null as StatusTyp | null,
  }),

  watch: {
    initField: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getStatusTypList();
        }
      },
    },

    datum: {
      immediate: true,
      handler(value) {
        if (value === "") return;

        this.statusTyp = this.statusTypForDatum(value);
      },
    },
  },

  computed: {
    ...mapState(useStatusStore, ["hint", "statusTypList"]),
  },

  methods: {
    ...mapActions(useStatusStore, ["getStatusTypList", "statusTypForDatum"]),
  },
});
