
import { mapWritableState } from "pinia";
import { defineComponent } from "vue";

import InputTextDefault from "@/components/input/text/InputTextDefault.vue";
import { useAdressStore } from "@/store/adress";

export default defineComponent({
  name: "InputGatuadress",

  components: {
    InputTextDefault,
  },

  props: {
    initField: {
      type: Boolean,
      default: false,
    },

    resetField: {
      type: Boolean,
      default: false,
      required: true,
    },

    hint: {
      type: String,
      default: "Gatuadress med tillhörande nummer eller port",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    resetField: {
      handler(value: boolean) {
        if (value) {
          this.adressForm.gatuadress = null;
        }
      },
    },
  },

  computed: {
    ...mapWritableState(useAdressStore, ["adressForm"]),
  },
});
