
import { defineComponent } from "vue";

export default defineComponent({
  name: "DialogPrompt",

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "title",
    },

    content: {
      type: String,
      default: "content",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    noCancelButton: {
      type: Boolean,
      default: false,
    },

    confirmedButtonText: {
      type: String,
      default: "Ja",
    },
  },
});
