
import { mapActions, mapState } from "pinia";
import { defineComponent, PropType } from "vue";

import ACard from "@/components/card/ACard.vue";
import KontrollAktivitet from "@/components/deltagare/stepper/shared/KontrollAktivitet.vue";
import KontrollBeslut from "@/components/deltagare/stepper/shared/KontrollBeslut.vue";
import KontrollIngangsvarde from "@/components/deltagare/stepper/shared/KontrollIngangsvarde.vue";
import RowTitle from "@/components/grid/RowTitle.vue";
import ADataTableVertical from "@/components/table/ADataTableVertical.vue";
import { StepProps } from "@/models/stepper";
import { ADataTableVerticalItem } from "@/models/table";
import { useAdressStore } from "@/store/adress";
import { useHelpStore } from "@/store/help";
import { useStepperStore } from "@/store/stepper";

export default defineComponent({
  name: "StepKontroll",

  components: {
    ACard,
    RowTitle,
    KontrollIngangsvarde,
    KontrollBeslut,
    KontrollAktivitet,
    ADataTableVertical,
  },

  props: {
    stepProps: {
      type: Object as PropType<StepProps>,
      required: true,
    },
  },

  computed: {
    ...mapState(useAdressStore, ["adressForm"]),
    ...mapState(useStepperStore, ["deltagare"]),

    personuppgiftInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "Personnr",
          value: this.nullableString(this.deltagare.personnummer),
        },
        {
          key: "Förnamn",
          value: this.nullableString(this.deltagare.fornamn),
        },
        {
          key: "Efternamn",
          value: this.nullableString(this.deltagare.efternamn),
        },
      ];
    },

    kontaktInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "E-post",
          value: this.nullableString(this.deltagare.epost),
        },
        {
          key: "Telefon",
          value: this.nullableString(this.deltagare.telefon),
        },
      ];
    },

    adressInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "c/o",
          value: this.nullableString(this.adressForm.co),
        },
        {
          key: "Gatuadress",
          value: this.nullableString(this.adressForm.gatuadress),
        },
        {
          key: "Postnr / Ort",
          value: `${this.nullableString(
            this.adressForm.displayPostnr
          )} ${this.nullableString(this.adressForm.ort)}`,
        },
      ];
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString"]),
  },
});
