
import { ChartData } from "chart.js";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import { DataTableHeader } from "vuetify/types";

import ACard from "@/components/card/ACard.vue";
import ADataTableDefault from "@/components/table/ADataTableDefault.vue";
import ADataTableVertical from "@/components/table/ADataTableVertical.vue";
import { KeyNumber, StatistikTableRow } from "@/models/statistik";
import { ADataTableVerticalItem } from "@/models/table";
import { useFilterListgeneratorStatistikStore } from "@/store/filterListgeneratorStatistik";
import { useHelpStore } from "@/store/help";
import { useStatistikStore } from "@/store/statistik";

import ABarChart from "./ABarChart.vue";
import APieChart from "./APieChart.vue";

export default defineComponent({
  name: "UppgiftPerPerson",

  components: {
    ACard,
    ADataTableVertical,
    ADataTableDefault,
    APieChart,
    ABarChart,
  },

  props: {
    resultDone: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    deltagarePerKonChartData: {
      labels: [] as string[],
      datasets: [
        {
          label: "Antal deltagare",
          data: [] as number[],
        },
      ],
    } as ChartData<"pie">,

    deltagarePerAldersgruppChartData: {
      labels: [] as string[],
      datasets: [
        {
          label: "Antal deltagare",
          data: [] as number[],
        },
      ],
    } as ChartData<"pie">,

    deltagarePerFodelseArChartData: {
      labels: [] as string[],
      datasets: [
        {
          label: "Antal deltagare",
          data: [] as number[],
        },
      ],
    } as ChartData<"pie">,

    deltagarePerFodelseplatsChartData: {
      labels: [] as string[],
      datasets: [
        {
          label: "Antal deltagare",
          data: [] as number[],
        },
      ],
    } as ChartData<"bar">,

    objectFodelsePlats: {
      "Ej svar": {
        totalt: 0,
      },
      Sverige: {
        totalt: 0,
      },
      "Övr. Europa": {
        totalt: 0,
      },
      "Utanför Europa": {
        totalt: 0,
      },
    },
  }),

  watch: {
    resultDone: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.perKonData();
          this.perAldersGrupp();
          this.perFodelseplats();

          if (this.filterData.kaa) {
            this.perFodelseAr();
          }
        }
      },
    },
  },

  computed: {
    ...mapState(useFilterListgeneratorStatistikStore, [
      "loadingAnvandareGrupp",
      "filterData",
    ]),

    ...mapState(useStatistikStore, ["resultData", "loadingResult"]),

    colOneInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "Antal aktiva deltagare under period",
          value: this.nullableString(
            this.resultData?.uppgifterPerDeltagare.totaltAntal.toString()
          ),
        },
        {
          key: "Därav antal nyinskrivna deltagare",
          value: this.nullableString(
            this.resultData?.uppgifterPerDeltagare.nyinskrivnaAntal.toString()
          ),
        },
        {
          key: "Därav antal avslutade deltagare",
          value: this.nullableString(
            this.resultData?.uppgifterPerDeltagare.avslutadeAntal.toString()
          ),
        },
      ];
    },

    colTwoInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "Behov av tolk",
          value: `${
            this.resultData?.uppgifterPerDeltagare.behovAvTolkAntal
          } / ${this.nullableString(
            this.percentage(
              this.resultData?.uppgifterPerDeltagare.behovAvTolkAntal ?? 0,
              this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0
            )?.toString(),
            "0"
          )} %`,
        },
        {
          key: "Antal deltagare med funktionsnedsättning",
          value: `${
            this.resultData?.uppgifterPerDeltagare.funktionsnedsattning.Ja
          } / ${this.nullableString(
            this.percentage(
              this.resultData?.uppgifterPerDeltagare.funktionsnedsattning.Ja ??
                0,
              this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0
            )?.toString(),
            "0"
          )} %`,
        },
        {
          key: "Antal med utländsk bakgrund",
          value: `${
            this.resultData?.uppgifterPerDeltagare.utlandskBakgrundAntal
          } / ${this.nullableString(
            this.percentage(
              this.resultData?.uppgifterPerDeltagare.utlandskBakgrundAntal ?? 0,
              this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0
            )?.toString(),
            "0"
          )} %`,
        },
        {
          key: "Antal familjeförsörjande",
          value: `${
            this.resultData?.uppgifterPerDeltagare.familjeforsorjandeAntal
          } / ${this.nullableString(
            this.percentage(
              this.resultData?.uppgifterPerDeltagare.familjeforsorjandeAntal ??
                0,
              this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0
            )?.toString(),
            "0"
          )} %`,
        },
      ];
    },

    oversiktDeltagareHeaders(): DataTableHeader[] {
      return [
        {
          text: "Månad*",
          value: "",
          align: "start",
          sortable: false,
        },
        {
          text: "Aktiva**",
          value: "",
          sortable: false,
        },
        {
          text: "Nyinskrivna",
          value: "",
          sortable: false,
        },
      ];
    },

    deltagarePerFodelseplatsHeaders(): DataTableHeader[] {
      return [
        {
          text: "Födelseplats",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
        {
          text: "Fördelning i %",
          value: "procent",
          sortable: false,
          align: "center",
        },
      ];
    },

    deltagarePerFodelseplatsItems(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      let totalProcent = 0;

      for (const [key, value] of Object.entries(this.objectFodelsePlats)) {
        const procent = this.percentage(
          value.totalt,
          this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0
        );

        totalProcent += procent ?? 0;

        array.push({
          name: key,
          antal: value.totalt,
          totalAntal: this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0,
          procent: `${this.nullableString(procent?.toString(), "0")} %`,
          totalProcent: "",
        });
      }

      this.addTotalProcentToAllItems(array, totalProcent);

      return array;
    },

    deltagarePerKonHeaders(): DataTableHeader[] {
      return [
        {
          text: "Kön",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
      ];
    },

    deltagarePerKonItems(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerDeltagare.kon as KeyNumber
      ).sort()) {
        const keyName = this.konKeyName(key);

        array.push({
          name: keyName,
          antal: value,
          totalAntal: this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0,
        });
      }

      return array;
    },

    deltagarePerAldersgruppHeaders(): DataTableHeader[] {
      return [
        {
          text: "Åldersgrupp",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
      ];
    },

    deltagarePerAldersgruppItems(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerDeltagare.alder[0] as KeyNumber
      )) {
        const keyName = this.alderKeyName(key);

        array.push({
          name: keyName,
          antal: value,
          totalAntal: this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0,
        });
      }

      return array;
    },

    /**
     * Detta gäller KAA
     */
    deltagarePerFodelseArHeaders(): DataTableHeader[] {
      return [
        {
          text: "Födelseår",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
      ];
    },

    /**
     * Detta gäller KAA
     */
    deltagarePerFodelseArItems(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      for (const [key, value] of Object.entries(
        this.resultData?.kaaUppgifter.fodelseAr as {
          [key: string]: {
            aktuella: number;
            avslutade: number;
            totalt: number;
          };
        }
      )) {
        array.push({
          name: key,
          antal: value.totalt,
          totalAntal: this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0,
        });
      }

      return array;
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["percentage", "nullableString"]),
    ...mapActions(useStatistikStore, [
      "alderKeyName",
      "konKeyName",
      "addTotalProcentToAllItems",
    ]),

    perKonData() {
      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerDeltagare.kon as KeyNumber
      ).sort()) {
        const keyName = this.konKeyName(key);
        this.deltagarePerKonChartData.labels?.push(keyName);

        const procent = this.percentage(
          value ?? 0,
          this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0
        );

        this.deltagarePerKonChartData.datasets[0].data.push(procent ?? 0);
      }
    },

    perAldersGrupp() {
      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerDeltagare.alder[0] as KeyNumber
      )) {
        const keyName = this.alderKeyName(key);
        this.deltagarePerAldersgruppChartData.labels?.push(keyName);

        const procent = this.percentage(
          value ?? 0,
          this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0
        );

        this.deltagarePerAldersgruppChartData.datasets[0].data.push(
          procent ?? 0
        );
      }
    },

    perFodelseplats() {
      for (const [key, value] of Object.entries(this.objectFodelsePlats)) {
        this.deltagarePerFodelseplatsChartData.labels?.push(key);
        this.deltagarePerFodelseplatsChartData.datasets[0].data.push(
          value.totalt
        );
      }
    },

    perFodelseAr() {
      for (const [key, value] of Object.entries(
        this.resultData?.kaaUppgifter.fodelseAr as {
          [key: string]: {
            aktuella: number;
            avslutade: number;
            totalt: number;
          };
        }
      )) {
        this.deltagarePerFodelseArChartData.labels?.push(key);

        const procent = this.percentage(
          value.totalt ?? 0,
          this.resultData?.uppgifterPerDeltagare.totaltAntal ?? 0
        );

        this.deltagarePerFodelseArChartData.datasets[0].data.push(procent ?? 0);
      }
    },
  },
});
