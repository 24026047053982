
import { mapWritableState } from "pinia";
import { defineComponent } from "vue";

import { useDialogStore } from "@/store/dialog";

import DialogDefault from "./DialogDefault.vue";

export default defineComponent({
  name: "DialogAbout",

  components: {
    DialogDefault,
  },

  computed: {
    ...mapWritableState(useDialogStore, ["dialogAbout"]),

    appName(): string | undefined {
      return process.env.VUE_APP_TITLE ?? "---";
    },

    appVersion(): string | undefined {
      return process.env.VUE_APP_VERSION ?? "---";
    },

    backendVersion(): string | undefined {
      return process.env.VUE_APP_BACKEND_VERSION ?? "---";
    },
  },
});
