
import { mapActions, mapState, mapWritableState } from "pinia";
import { ValidationObserver } from "vee-validate";
import { defineComponent } from "vue";

import ACard from "@/components/card/ACard.vue";
import DialogDefault from "@/components/dialog/DialogDefault.vue";
import FormFilterListgeneratorStatistik from "@/components/filter-listgenerator-statistik/form/FormFilterListgeneratorStatistik.vue";
import { useDialogStore } from "@/store/dialog";
import { useFilterListgeneratorStatistikStore } from "@/store/filterListgeneratorStatistik";
import { useGeneratePdfStore } from "@/store/generatepdf";
import { useListGeneratorStore } from "@/store/listgenerator";

export default defineComponent({
  name: "DialogListGenerator",

  components: {
    ACard,
    DialogDefault,
    ValidationObserver,
    FormFilterListgeneratorStatistik,
  },

  data: () => ({
    isDirty: false,
    isInvalid: false,
    isPdfGenerated: false,
    resultDone: false,
  }),

  watch: {
    dialogListGenerator: {
      immediate: true,
      handler(value: boolean) {
        if (!value) {
          this.resetFilterAndPdf();
        }
      },
    },
  },

  computed: {
    ...mapWritableState(useDialogStore, ["dialogListGenerator"]),

    ...mapState(useListGeneratorStore, ["loadingResult", "resultData"]),

    ...mapState(useFilterListgeneratorStatistikStore, [
      "loadingAnvandareGrupp",
      "filterData",
      "urvalHuvudHandlaggareForm",
    ]),
    ...mapWritableState(useFilterListgeneratorStatistikStore, [
      "resetFilterForm",
    ]),
  },

  methods: {
    ...mapActions(useListGeneratorStore, ["getResultData"]),
    ...mapActions(useGeneratePdfStore, ["generateBeslutListA"]),
    ...mapActions(useFilterListgeneratorStatistikStore, ["getOnlySetFilters"]),

    async generatePdf() {
      this.resultDone = false;

      const filterData = await this.getOnlySetFilters();
      await this.getResultData(filterData);

      if (this.urvalHuvudHandlaggareForm === "beslutListA") {
        this.isPdfGenerated = await this.generateBeslutListA(filterData);
      }

      this.resultDone = true;
    },

    removeGeneratedPdf() {
      const doc = document.getElementById("pdf") as HTMLIFrameElement;

      if (doc) {
        doc.src = "";
      }
    },

    resetFilterAndPdf() {
      this.removeGeneratedPdf();

      this.resetFilterForm = true;
      this.isPdfGenerated = false;
      this.resultDone = false;

      const listgenerator = useListGeneratorStore();
      listgenerator.$reset();
    },
  },
});
