
import "@/validation/rules";

import { ValidationProvider } from "vee-validate";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "InputNumberDefault",

  components: {
    ValidationProvider,
  },

  props: {
    vid: {
      type: String,
      default: "ValidationVid",
    },

    mode: {
      type: String,
      default: "eager",
    },

    value: {
      type: null as unknown as PropType<string | number | null>,
      default: 0,
      required: false,
    },

    inputType: {
      type: String as PropType<string | null>,
      default: "number",
    },

    appendIcon: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "label",
    },

    hint: {
      type: String,
      default: null,
    },

    required: {
      type: Boolean,
      default: true,
    },

    min: {
      type: [String, Number],
      default: null,
    },

    max: {
      type: [String, Number],
      default: null,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
      default: null,
    },
  },
});
