import { defineStore } from "pinia";

export const useDialogStore = defineStore("dialog", {
  state: () => ({
    dialogUppdrag: false,
    dialogVisaUppdrag: false,
    dialogAutobevakning: false,
    dialogAbout: false, // visning av version
    // deltagare
    dialogAddDeltagare: false,
    dialogHanteraPersonuppgift: false,
    dialogAnteckningsBlockHistorik: false,
    dialogAddEditBeslutsparAnteckning: false,
    dialogAddEditAnteckningsBlockAnteckning: false,
    dialogHanteraDeltagareBevakning: false,
    dialogAddEditDeltagareBevakning: false,
    dialogListGenerator: false,
    // mallhanteraren
    dialogHanteraMallHanteraren: false,
    dialogAddEditTextMall: false,
    // beslutsspår
    dialogRegistreraNyGrupp: false,
    dialogBytHuvudbeslut: false,
    dialogSkickaVidareDeltagare: false,
    dialogBytBeslutKaa: false,
    dialogAddKompletterandeBeslut: false,
    dialogAddAktivitet: false,
    dialogAddHandelse: false,
    dialogAddPlaneringFoljs: false,
    dialogAddInternResurs: false,
    dialogAddExternResursBeslutspar: false,
    dialogAvslutaDeltagare: false,
    dialogBytInsatsKaau: false, // växla mellan uppsökande / bevakande insats
    dialogAddSysselsattning: false,
    dialogAddInsatsKaai: false,
    dialogAddEditMatchningskravBeslutspar: false,
    dialogTaBortDokument: false,
    dialogAddDokument: false,
    dialogMatchaAktivitet: false,
    dialogPlaceraInsats: false,
    dialogForlangAktivitet: false,
    dialogEditAktivitetOmfattning: false,
    dialogVisaAktivitetPlacering: false,
    dialogAvbrytAktivitet: false,
    dialogAvslutaAktivitet: false,
    dialogEditAktivitet: false,
    dialogPromptForlangBeslut: false,
    dialogHanteraExternResurs: false,
    dialogAddEditExternResursOrganisation: false,
    dialogAddResurs: false,
    dialogHanteraResurs: false,
    dialogTaBortBeslutsparExternResurs: false,
    dialogTaBortBeslutsparInternResurs: false,
    dialogVisaDeltagareIceKontakt: false,
    dialogAvslutaKompletterandeBeslut: false,
    dialogForlangBeslut: false,
    dialogBeslutBytHandlaggare: false,
    dialogAddHandlingsplanBeslutspar: false,
    dialogAddDetaljHandlingsplanBeslutspar: false,
    dialogAddEditMemoText: false,
    // hushåll
    dialogHanteraHushall: false,
    dialogAddEditHushall: false,
    dialogIsRegisterledare: false,
    dialogAddHushallMedlem: false,
    dialogTaBortHushallMedlem: false,
    dialogBytRegisterledare: false,
    dialogFlyttaHelaHushallet: false,
    dialogFlyttaDelarAvHushallet: false,
    // kartläggning
    dialogDeltagareKartlaggning: false,
    dialogAddEditDatorvana: false,
    dialogAddEditResurser: false,
    dialogAddEditForvantningar: false,
    dialogAddEditMyndighetskontakt: false,
    dialogAddEditSituation: false,
    dialogAddEditHalsa: false,
    dialogKartlaggningHanteraUtbildning: false,
    dialogAddEditUtbildning: false,
    dialogTaBortUtbildning: false,
    dialogKartlaggningHanteraArbetsliv: false,
    dialogAddEditArbetsliv: false,
    dialogTaBortArbetsliv: false,
    dialogKartlaggningHanteraSprakkunskap: false,
    dialogAddEditSprakkunskap: false,
    dialogTaBortSprakkunskap: false,
    dialogAddEditKartlaggningOversikt: false,
    dialogAddEditKartlaggningMigration: false,
    dialogKartlaggningHanteraIceKontakt: false,
    dialogAddIceKontakt: false,
    dialogTaBortIceKontakt: false,
    dialogHanteraIceKontakt: false,
    // kontaktuppgift
    dialogAddEditKontaktuppgift: false,
    dialogTaBortKontaktuppgift: false,
    dialogSetPrimarKontaktuppgift: false,
    // adress
    dialogAddEditAdress: false,
    dialogTaBortAdress: false,
    dialogSetPrimarAdress: false,
    // platsbank
    dialogAddArbetsstalle: false,
    dialogAddArbetsstalleKontakt: false,
    dialogAddEditPlats: false,
    dialogHanteraArbetsstalle: false,
    dialogHanteraArbetsstalleKontakt: false,
    dialogAddEditMatchningskravPlats: false,
    dialogHanteraArbetsstallePlatsPlacering: false,
    dialogVisaArbetsstallePlatsPlaceringUppgifter: false,
    // administration
    dialogAddAnvandare: false,
    dialogVisaAnvandareLista: false,
    dialogAddGrupp: false,
    dialogHanteraAnvandare: false,
    dialogSetPrimarAnvandareGrupp: false,
    dialogTaBortAnvandareGrupp: false,
    dialogTaBortAnvandareAtkomstroll: false,
    dialogHanteraGrupp: false,
    dialogAddFastval: false,
    dialogHanteraFastval: false,
    dialogAddAtkomstRoll: false,
    dialogHanteraAtkomstRoll: false,
    dialogAddMatchningsTyp: false,
    dialogAddFragestallning: false,
    dialogHanteraSamling: false,
    dialogAddSamlingAlternativ: false,
    // matchningsfiltrering
    dialogAddMatchningsfiltrering: false,
    dialogAddEditMatchningsfiltreringOnContext: false,
    // status
    dialogUpdateStatus: false,
    // accorda forms
    dialogHanteraAccordaForms: false,
    dialogAddEditAccordaForm: false,
    // statistik
    dialogStatistik: false,

    // icke implementerade feature dialoger
    dialogMyndighet: false,
    dialogGallring: false,
    dialogSupport: false,
    dialogChecklista: false,
    dialogUtredningsmall: false,
    dialogProgressionsmatning: false,
    dialogUtskickshanteraren: false,
    dialogTidshantering: false,
  }),

  actions: {
    /**
     * Öppnar dialog
     *
     * @since version 0.9.1
     */
    open(name: string | null) {
      if (!name) return;

      this[name as keyof typeof this.$state] = true;
    },

    /**
     * Stänger dialog
     *
     * @since version 0.9.1
     */
    close(name: string | null) {
      if (!name) return;

      this[name as keyof typeof this.$state] = false;
    },

    /**
     * Använd open, close istället
     *
     * @deprecated since version 0.9.1
     */
    toggleDialog(name: string | null, value: boolean) {
      if (!name) return;

      this[name as keyof typeof this.$state] = value;
    },
  },
});
