
import { ChartData } from "chart.js";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import { DataTableHeader } from "vuetify/types";

import ACard from "@/components/card/ACard.vue";
import ADataTableDefault from "@/components/table/ADataTableDefault.vue";
import { LabelType } from "@/models/label";
import { StatistikTableRow } from "@/models/statistik";
import { ADataTableVerticalItem } from "@/models/table";
import { useHelpStore } from "@/store/help";
import { useStatistikStore } from "@/store/statistik";

import APieChart from "./APieChart.vue";

export default defineComponent({
  name: "AntalPerHandlaggargruppStodniva",

  components: {
    ACard,
    ADataTableDefault,
    APieChart,
  },

  props: {
    resultDone: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    underDiagramAntalPerStodniva: [] as ADataTableVerticalItem[],

    antalPerHandlaggargruppChartData: {
      labels: [] as string[],
      datasets: [
        {
          label: "Antal deltagare",
          data: [] as number[],
        },
      ],
    } as ChartData<"pie">,

    antalPerStodnivaChartData: {
      labels: [] as string[],
      datasets: [
        {
          label: "Antal deltagare",
          data: [] as number[],
        },
      ],
    } as ChartData<"pie">,
  }),

  watch: {
    resultDone: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.prepareAntalPerHandlaggargruppData();
          this.prepareAntalPerStodnivaData();
        }
      },
    },
  },

  computed: {
    ...mapState(useStatistikStore, ["resultData"]),

    antalPerHandlaggargruppHeaders(): DataTableHeader[] {
      return [
        {
          text: "Handläggargrupp",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
      ];
    },

    antalPerHandlaggargruppItems(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerBeslutspar.grupp as {
          [key: string]: number;
        }
      )) {
        array.push({
          name: key,
          antal: value,
          totalAntal: this.resultData?.uppgifterPerBeslutspar.totaltAntal ?? 0,
        });
      }

      return array;
    },

    antalPerStodnivaHeaders(): DataTableHeader[] {
      return [
        {
          text: "Stödniva",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
      ];
    },

    antalPerStodnivaItems(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      let totalAntal = 0;

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerBeslutspar.stodniva as {
          [key: string]: number;
        }
      )) {
        totalAntal += value;

        array.push({
          name: key,
          antal: value,
          totalAntal: 0,
        });
      }

      for (const item of array) {
        item.totalAntal = totalAntal;
      }

      return array;
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString", "percentage"]),

    prepareAntalPerHandlaggargruppData() {
      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerBeslutspar.grupp as {
          [key: string]: number;
        }
      ).sort()) {
        this.antalPerHandlaggargruppChartData.labels?.push(key);

        const procent = this.percentage(
          value,
          this.resultData?.uppgifterPerBeslutspar.totaltAntal ?? 0
        );
        this.antalPerHandlaggargruppChartData.datasets[0].data.push(
          procent ?? 0
        );
      }
    },

    prepareAntalPerStodnivaData() {
      let total = 0 as number;

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerBeslutspar.stodniva as {
          [key: string]: number;
        }
      ).sort()) {
        this.antalPerStodnivaChartData.labels?.push(key);

        total += value;

        const procent = this.percentage(
          value,
          total // this.resultData?.uppgifterPerBeslutspar.totaltAntal ?? 0
        );
        this.antalPerStodnivaChartData.datasets[0].data.push(procent ?? 0);

        this.underDiagramAntalPerStodniva.push({
          key: key as LabelType,
          value: value.toString(),
        });
      }
    },
  },
});
