
import { defineComponent, PropType } from "vue";

import StatusTextAndIcon from "@/components/status/StatusTextAndIcon.vue";
import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellStatusTextAndIcon",

  components: {
    StatusTextAndIcon,
  },

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    iconSet(): string {
      if (this.item.iconSet) {
        return this.item.iconSet;
      }

      return "period";
    },

    status(): string | null {
      if (this.item.status) {
        return this.item.status;
      }

      return null;
    },
  },
});
