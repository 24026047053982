
import { defineComponent, PropType } from "vue";

import InputTextDefault from "@/components/input/text/InputTextDefault.vue";

export default defineComponent({
  name: "InputTelefonDefault",

  components: {
    InputTextDefault,
  },

  props: {
    value: {
      type: null as unknown as PropType<string | null>,
    },

    required: {
      type: Boolean,
      default: true,
    },

    hint: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    telefon: {
      get(): string | null | undefined {
        return this.value;
      },

      set(value: string) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    clean() {
      if (this.telefon) {
        this.telefon = this.telefon.replace(/\D/g, "");
      }
    },
  },
});
