import { defineStore } from "pinia";

import { position } from "@/helpers/date";
import { DropdownMenuItem } from "@/models/dropdown";
import {
  Arbetsstalle,
  Color,
  Period,
  StatusTyp,
  StatusTypObject,
} from "@/models/status";

import { useCrudStore } from "./crud";
import { useDialogStore } from "./dialog";
import { useHelpStore } from "./help";
import { useRedirectStore } from "./redirect";
import { useReloadStore } from "./reload";
import { useSnackbarStore } from "./snackbar";

type StatusToChange = "aktivera" | "avsluta" | "pausa" | "accepterad" | "visad";

interface State {
  loading: boolean;
  saving: boolean;
  statusTypList: StatusTyp[];
  color: Color;
  period: Period;
  arbetsstalle: Arbetsstalle;
  anvandare: { [key: string]: string };
  statusToChange: {
    name: string | null;
    id: number | null;
    status: StatusToChange | null;
  };
  actionReload: boolean;
  actionRedirect: boolean;
  statusTypEnum: {
    pagaende: {
      id: number;
      namn: string;
    };
    avbruten: {
      id: number;
      namn: string;
    };
    genomford: {
      id: number;
      namn: string;
    };
    planerad: {
      id: number;
      namn: string;
    };
    avslutad: {
      id: number;
      namn: string;
    };
    aktiv: {
      id: number;
      namn: string;
    };
  };
}

export const useStatusStore = defineStore("status", {
  state: (): State => ({
    loading: false,
    saving: false,

    statusTypList: [] as StatusTyp[],

    color: {
      aktiv: "primary",
      avslutad: "error", // "success",
      genomford: "success",
      avbruten: "error",
      pausad: "purple",
      obekraftad: "warning",
      pagaende: "primary",
      planerad: "purple",
      passerat: "warning",
    },

    period: {
      pagaende: "mdi-arrow-down-thick",
      planerad: "mdi-arrow-right-thick",
      avbruten: "mdi-arrow-left-thick",
      avslutad: "mdi-arrow-left-thick",
      passerat: "mdi-alert-circle",
      genomford: "mdi-arrow-left-thick",
    },

    arbetsstalle: {
      aktiv: "mdi-office-building",
      avslutad: "mdi-office-building",
    },

    anvandare: {
      aktiv: "mdi-account-check",
      avslutad: "mdi-account-off",
      pausad: "mdi-pause-circle",
      obekraftad: "mdi-account-alert",
    },

    statusTypEnum: Object.freeze({
      pagaende: {
        id: -1,
        namn: "Pågående",
      },
      avbruten: {
        id: -2,
        namn: "Avbruten",
      },
      genomford: {
        id: -3,
        namn: "Genomförd", // tidigare status namn: Avslutad
      },
      planerad: {
        id: -100,
        namn: "Planerad",
      },
      avslutad: {
        id: -101,
        namn: "Avslutad",
      },
      aktiv: {
        id: -102,
        namn: "Aktiv",
      },
      passerat: {
        id: -103,
        namn: "Passerat",
      },
    }),

    statusToChange: {
      name: null as string | null,
      id: null as number | null,
      status: null as StatusToChange | null,
    },

    actionReload: false,
    actionRedirect: false,
  }),

  getters: {
    hint(): string {
      return `Startdatum efter ${new Date()
        .toISOString()
        .substring(0, 10)} får status "Planerad"`;
    },
  },

  actions: {
    setStatus(
      iconSet: string,
      status: string | null | undefined
    ): StatusTypObject | null {
      if (!status) return null;

      const help = useHelpStore();
      const slugified = help.slugify(status);

      let icons;

      switch (iconSet) {
        case "period":
          icons = this.period;
          break;

        case "arbetsstalle":
          icons = this.arbetsstalle;
          break;

        case "anvandare":
          icons = this.anvandare;
          break;

        default:
          icons = this.period;
      }

      return {
        icon: icons[slugified],
        iconColor: this.color[slugified],
        color: `${this.color[slugified]}--text`,
        namn: status,
      };
    },

    async getStatusTypList(ejaktuella = false) {
      this.loading = true;

      this.statusTypList = [];

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "statustyp",
          params: {
            ejaktuella: ejaktuella,
          },
        })) as StatusTyp[];

        const planerad = this.statusTypEnum.planerad;

        data.push(planerad);

        this.statusTypList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * Sätter statusTyp utifrån datum
     *
     * @since version 0.8.5
     */
    statusTypForDatum(datum: string): StatusTyp | null {
      if (!datum) return null;

      let statusTyp = null as StatusTyp | null;
      const date = position(datum);

      if (date.before || date.same) {
        // pågående
        statusTyp = {
          id: this.statusTypEnum.pagaende.id,
          namn: this.statusTypEnum.pagaende.namn,
        };
      }

      if (date.after) {
        // planerad
        statusTyp = {
          id: this.statusTypEnum.planerad.id,
          namn: this.statusTypEnum.planerad.namn,
        };
      }

      return statusTyp;
    },

    statusDropdownMenuList(
      name: string,
      id: number | null | undefined,
      aktuell: boolean,
      divider = true
    ): DropdownMenuItem[] {
      if (!id) return [];

      const array = [
        { header: "status" },
        {
          title: "Aktivera",
          icon: "mdi-play",
          disabled: aktuell,
          emit: {
            name: "open-status-dialog",
            value: { name: name, id: id, status: "aktivera" },
          },
        },
        {
          title: "Avsluta",
          icon: "mdi-stop",
          disabled: !aktuell,
          emit: {
            name: "open-status-dialog",
            value: { name: name, id: id, status: "avsluta" },
          },
        },
      ];

      if (divider) {
        return [{ divider: true }, ...array];
      } else {
        return array;
      }
    },

    openStatusDialog(
      event: {
        name: string;
        id: number;
        status: StatusToChange;
      },
      reload = true,
      redirect = true
    ) {
      this.statusToChange = event;

      this.actionReload = reload;
      this.actionRedirect = redirect;

      const dialog = useDialogStore();
      dialog.toggleDialog("dialogUpdateStatus", true);
    },

    async updateStatus() {
      this.saving = true;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();
        const redirect = useRedirectStore();

        await crud.put({
          url: `${this.statusToChange.name}/${this.statusToChange.id}/status/${this.statusToChange.status}`,
        });

        snackbar.genericSnackbarMessage = "Status har ändrats";
        snackbar.genericSnackbar = true;

        dialog.dialogUpdateStatus = false;

        if (this.actionReload) {
          reload.single("reloadAfterUpdateStatus");
        }

        if (this.actionRedirect && this.statusToChange.status === "avsluta") {
          redirect.toggleRedirect("redirectAfterUpdateStatus");
        }

        this.statusToChange.name = null;
        this.statusToChange.id = null;
        this.statusToChange.status = null;

        this.actionReload = false;
        this.actionRedirect = false;

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },
  },
});
