import { extend } from "vee-validate";
import {
  alpha,
  alpha_num,
  alpha_spaces,
  between,
  confirmed,
  digits,
  email,
  ext,
  max,
  min,
  numeric,
  regex,
  required,
  size,
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} är obligatoriskt",
});

extend("email", {
  ...email,
  message: "{_field_} är ogiltig",
});

extend("digits", {
  ...digits,
  message: "{_field_} måste innehålla {length} siffror",
});

extend("numeric", {
  ...numeric,
  message: "{_field_} får bara innehålla siffror",
});

extend("min", {
  ...min,
  message: "{_field_} måste minst innehålla {length} tecken",
});

extend("max", {
  ...max,
  message: "{_field_} får maximalt innehålla {length} tecken",
});

extend("alpha", {
  ...alpha,
  message: "{_field_} får bara innehålla bokstäver",
});

extend("alpha_num", {
  ...alpha_num,
  message: "{_field_} får bara innehålla bokstäver och siffror",
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "{_field_} får bara innehålla bokstäver och mellanslag",
});

extend("confirmed", {
  ...confirmed,
  message: "{_field_} stämmer inte överens",
});

extend("between", {
  ...between,
  message: "{_field_} måste innehålla minimum {min} eller maximum {max} tecken",
});

extend("regex", {
  ...regex,
  message: "{_field_} innehåller otillåtna tecken",
});

extend("ext", {
  ...ext,
  message: "{_field_} innehåller otillåtet filformat",
});

extend("size", {
  ...size,
  message: "{_field_} överskrider den tillåtna storleken",
});

extend("minLength", {
  params: ["length"],
  validate: (value, { length }) => {
    return value.length >= length;
  },
  message: "Minst {length} {_field_} måste väljas",
});
