import { defineStore } from "pinia";

import { numberToWord } from "@/helpers/convert";
import { add, format, subtract } from "@/helpers/date";
import { Anvandare } from "@/models/anvandare";
import { FvInsats, FvProjekt } from "@/models/fastval";
import { AnvandareGrupp, Grupp } from "@/models/grupp";
import { Insats } from "@/models/insats";

import { useAuthStore } from "./auth";
import { useCrudStore } from "./crud";
import { useDeltagareStore } from "./deltagare";
import { useDialogStore } from "./dialog";
import { usePlatsStore } from "./plats";
import { useReloadStore } from "./reload";
import { useSnackbarStore } from "./snackbar";
import { useStepperStore } from "./stepper";

export const useInsatsStore = defineStore("insats", {
  state: () => ({
    loading: false,
    saving: false,

    insats: {
      handlaggareId: null as number | null,
      fvInsatsId: null as number | null,
      fvProjektId: null as number | null,
      omfattning: 0,
      startdatum: new Date().toISOString().substring(0, 10),
      slutdatum: null as string | null,
      gruppId: null as number | null,
      avslutaInsatsId: [] as number[],
      isInternResurs: false,
    },

    sysselsattning: {
      sysselsattningId: null as number | null,
      handlaggareId: null as number | null,
      startdatum: new Date().toISOString().substring(0, 10),
    },

    selectedFvInsats: null as FvInsats | null,
    selectedInsats: null as Insats | null,

    fvInsatsList: [] as FvInsats[],
    fvProjektList: [] as FvProjekt[],
    handlaggareList: [] as Anvandare[],
    insatsGruppList: [] as Grupp[],

    avbrytAktivitetForm: {
      datum: null as string | null,
      statusTypId: null as number | null,
      notering: null as string | null,
    },

    avslutaAktivitetForm: {
      datum: "", // för att undvika null
      statusTypId: null as number | null,
    },

    omfattning: 0 as number,

    forlangAktivitetForm: {
      slutdatum: "", // för att undvika null
      forlangBeslut: false, // bara kosmetisk property
    },

    insatsMatchningForm: {
      arbetsuppgifter: null as string | null,
      ovrigaUppgifter: null as string | null,
      generellInformation: null as string | null,
      fackligOrg: null as string | null,
      fackligOmbud: null as string | null,
      ansvarigId: null as number | null,
    },

    planeringFoljsForm: {
      startdatum: null as string | null,
      planeringFoljs: false,
    },
  }),

  getters: {
    /**
     * Minimum startdatum för ny aktivitet är huvudbeslutets startdatum.
     *
     * @since version 0.5.6
     */
    minStartdatumNyAktivitet(): string {
      const deltagare = useDeltagareStore();

      return format(deltagare.huvudbeslut?.startdatum);
    },

    /**
     * Maximum startdatum för ny aktivitet. Startdatum för aktivitet måste vara en dag mindre
     * än slutdatum om det är satt, annars används beslutets slutdatum minus en dag då max startdatum
     * för ny aktivitet inte kan vara på samma dag som huvudbeslutet slutar.
     *
     * @since version 0.5.6
     */
    maxStartdatumNyAktivitet(state): string {
      const deltagare = useDeltagareStore();

      if (state.insats.slutdatum) {
        return format(subtract(state.insats.slutdatum, 1, "day"));
      } else if (deltagare.huvudbeslut?.slutdatum) {
        return format(subtract(deltagare.huvudbeslut.slutdatum, 1, "day"));
      } else {
        return "";
      }
    },

    /**
     * Minimum slutdatum för ny aktivitet, måste vara startdatum plus en dag.
     *
     * @since version 0.5.6
     */
    minSlutdatumNyAktivitet(state): string {
      if (state.insats.startdatum !== "") {
        return format(add(state.insats.startdatum, 1, "day"));
      } else {
        return "";
      }
    },

    /**
     * Maximum slutdatum för ny aktivitet.
     *
     * @since version 0.5.6
     */
    maxSlutdatumNyAktivitet(): string {
      const deltagare = useDeltagareStore();

      if (deltagare.huvudbeslut?.slutdatum) {
        return format(deltagare.huvudbeslut.slutdatum);
      } else {
        return "";
      }
    },

    minSlutdatumForlangAktivitet(state): string {
      return state.selectedInsats
        ? format(add(this.selectedInsats?.slutdatum, 1, "day"))
        : "";
    },

    /**
     * Max slutdatum att förlänga en aktivitet är hårt satt till 2 år,
     * även om huvudbeslutet sträcker sig längre ska man inte kunna förlänga aktiviteten
     * för långt fram i tiden.
     *
     * @since version 0.5.9
     */
    maxSlutdatumForlangAktivitet(): string {
      return format(add(new Date().toISOString().substring(0, 10), 2, "year"));
    },

    minDatumAvbrytAvslutaAktivitet(state): string {
      return format(add(state.selectedInsats?.startdatum, 1, "day"));
    },

    maxDatumAvbrytAvslutaAktivitet(state): string {
      return format(state.selectedInsats?.slutdatum);
    },

    minStartdatumNyHandelse(): string {
      const deltagare = useDeltagareStore();
      return deltagare.huvudbeslut?.startdatum ?? "";
    },

    maxStartdatumNyHandelse(): string {
      const deltagare = useDeltagareStore();
      return deltagare.huvudbeslut?.slutdatum ?? "";
    },

    minStartdatumNySysselsattning(): string {
      const deltagare = useDeltagareStore();
      return deltagare.huvudbeslut?.startdatum ?? "";
    },

    maxStartdatumNySysselsattning(): string {
      const deltagare = useDeltagareStore();
      return deltagare.huvudbeslut?.slutdatum ?? "";
    },

    selectedInsatsNamn(state): string {
      return state.selectedInsats?.fvInsats.namn ?? "---";
    },

    selectedInsatsPeriod(state): string {
      return `${format(state.selectedInsats?.startdatum)} - ${format(
        state.selectedInsats?.slutdatum
      )}`;
    },

    selectedInsatsResurs(state): string {
      return state.selectedInsats?.handlaggare?.namn ?? "---";
    },

    selectedInsatsProjekt(state): string {
      return state.selectedInsats?.fvProjekt?.namn ?? "---";
    },

    selectedInsatsOmfattning(state): number | string {
      return state.selectedInsats?.omfattning ?? "---";
    },

    selectedInsatsStatus(state): string | null {
      return state.selectedInsats?.status ?? null;
    },
  },

  actions: {
    setInsatsHandlaggare(items: Anvandare[]) {
      const auth = useAuthStore();

      const handlaggare = items.find((i) => i.id === auth.user?.id);

      if (handlaggare) {
        this.insats.handlaggareId = handlaggare.id;
      }
    },

    setSysselsattningHandlaggare(items: Anvandare[]) {
      const auth = useAuthStore();

      const handlaggare = items.find((i) => i.id === auth.user?.id);

      if (handlaggare) {
        this.sysselsattning.handlaggareId = handlaggare.id;
      }
    },

    /**
     * Denna funktion används för att växla mellan bevakande / uppsökande i KAA-U
     */
    setKaauFvInsatsList(data: FvInsats[]) {
      const deltagare = useDeltagareStore();

      /**
       * Sätt uppsökande och filtrera ut bevakande i listan
       */
      if (deltagare.isBevakande) {
        this.fvInsatsList = data.filter(
          (i) => i.id === deltagare.kaauInsatsTyp.uppsokande.id
        );
        this.insats.fvInsatsId = deltagare.kaauInsatsTyp.uppsokande.id;
      }

      /**
       * Sätt bevakande och filtrera ut uppsökande i listan
       */
      if (deltagare.isUppsokande) {
        this.fvInsatsList = data.filter(
          (i) => i.id === deltagare.kaauInsatsTyp.bevakande.id
        );
        this.insats.fvInsatsId = deltagare.kaauInsatsTyp.bevakande.id;
      }
    },

    /**
     * Om det finns fler grupper kolla om inloggade användarens primärgrupp
     * finns i grupplistan och sätt den som förvald, om inte sätt det valda beslutsspårets grupp.
     *
     * @since version 0.8.5
     */
    setPrimarGruppOrBeslutsparGrupp(
      gruppList: Grupp[],
      primarGrupp: AnvandareGrupp | null
    ): void {
      if (primarGrupp) {
        const grupp = gruppList.find((i) => i.id === primarGrupp.id);

        if (grupp) {
          this.insats.gruppId = grupp.id;
        } else {
          const deltagare = useDeltagareStore();
          this.insats.gruppId = deltagare.beslutsparGruppId;
        }
      }
    },

    resetInsats() {
      this.insats.avslutaInsatsId = [];
      this.insats.fvInsatsId = null;
      this.insats.fvProjektId = null;
      this.insats.gruppId = null;
      this.insats.handlaggareId = null;
      this.insats.isInternResurs = false;
      this.insats.omfattning = 0;
      this.insats.slutdatum = null;
      this.insats.startdatum = new Date().toISOString().substring(0, 10);
    },

    resetSysselsattning() {
      this.sysselsattning.startdatum = new Date()
        .toISOString()
        .substring(0, 10);
      this.sysselsattning.sysselsattningId = null;
      this.sysselsattning.handlaggareId = null;
    },

    async getInsatsGruppList() {
      this.loading = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const auth = useAuthStore();

        const data = (await crud.get({
          url: `beslutspar/${deltagare.beslutsparId}/insatsgrupp`,
        })) as Grupp[];

        this.insatsGruppList = data;

        if (data.length === 1) {
          this.insats.gruppId = data[0].id;
        }

        if (data.length > 1) {
          await auth.getInloggadAnvandareGruppList();
          this.setPrimarGruppOrBeslutsparGrupp(data, auth.primarGrupp);
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * url typer:
     *
     * kaa/aktivitet/kaa-i
     * kaa/aktivitet/kaa-u
     * grupp/{beslutsparGruppId}/fvaktivitet
     * grupp/{beslutsparGruppId}/fvhandelse
     */
    async getFvInsatsList(url: string | null | undefined) {
      this.fvInsatsList = [];

      if (!url) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: url,
        })) as FvInsats[];

        if (url === "kaa/aktivitet/kaa-u") {
          this.setKaauFvInsatsList(data);
        } else {
          this.fvInsatsList = data;
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * url typer:
     *
     * `beslutspar/{beslutsparId}/insatsgrupp/{beslutsparGruppId}/anvandare`;
     * `gruppanvandare/{beslutsparGruppId}/anvandare`;
     */
    async getHandlaggareList(
      url: string | null | undefined,
      ejaktuella = false
    ) {
      if (!url) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: url,
          params: {
            ejaktuella: ejaktuella,
          },
        })) as Anvandare[];

        this.handlaggareList = data;
        this.setInsatsHandlaggare(data);
        this.setSysselsattningHandlaggare(data);

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFvProjektList(gruppId: number | null | undefined) {
      if (!gruppId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Grupp/${gruppId}/fvProjekt`,
          params: {
            ejaktuella: false,
          },
        })) as FvProjekt[];

        this.fvProjektList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async addInsatsKaai() {
      this.saving = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await Promise.all([
          crud.post({
            url: `beslut/${deltagare.huvudbeslut?.id}/insats`,
            data: this.insats,
          }),
          crud.post({
            url: `beslutspar/${deltagare.beslutsparId}/kaa/sysselsattning`,
            data: this.sysselsattning,
          }),
        ]);

        snackbar.genericSnackbarMessage =
          "Ny aktivitet och sysselsättning skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddInsatsKaai = false;

        await deltagare.getBeslutList(deltagare.beslutsparId);

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async bytInsatsKaau() {
      this.saving = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await crud.post({
          url: `Beslutspar/${deltagare.beslutsparId}/Kaa/BytInsats`,
          data: {
            ...this.insats,
            sysselsattning: {
              ...this.sysselsattning,
              handlaggareId: this.insats.handlaggareId,
            },
          },
        });

        snackbar.genericSnackbarMessage = "Ny aktivitet skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogBytInsatsKaau = false;

        await deltagare.getBeslutList(deltagare.beslutsparId);

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async addInsatsAktivitet() {
      this.saving = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.post({
          url: `beslut/${deltagare.huvudbeslut?.id}/insats`,
          data: this.insats,
        });

        let message = "";
        const count = this.insats.avslutaInsatsId.length;
        const word = count > 1 ? " avslutade" : " avslutad";
        const isInternResurs = this.insats.isInternResurs
          ? "och intern resurs tillagd"
          : "";

        if (count > 0) {
          message = `Aktivitet skapad och ${numberToWord(
            count
          )} ${word} ${isInternResurs}`;
        } else {
          message = `Aktivitet skapad ${isInternResurs}`;
        }

        snackbar.genericSnackbarMessage = message;
        snackbar.genericSnackbar = true;

        dialog.dialogAddAktivitet = false;

        reload.toggleReload("reloadBeslutsparOversiktAktuellView");

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async addInsatsHandelse() {
      this.saving = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await crud.post({
          url: `beslut/${deltagare.huvudbeslut?.id}/insats`,
          data: this.insats,
        });

        snackbar.genericSnackbarMessage = "Händelse skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddHandelse = false;

        await deltagare.getBeslutList(deltagare.beslutsparId);

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async addInsatsSysselsattning() {
      this.saving = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        await crud.post({
          url: `beslutspar/${deltagare.beslutsparId}/kaa/sysselsattning`,
          data: this.sysselsattning,
        });

        snackbar.genericSnackbarMessage = "Sysselsättning skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddSysselsattning = false;

        await deltagare.getBeslutList(deltagare.beslutsparId);

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    /**
     * Kontrollera om aktivitetens handläggare redan finns på beslutsspåret
     * innan dialogen lägg till som intern resurs öppnas.
     */
    isAktivitetHandlaggareSameAsBeslutHandlaggare(
      aktivitetHandlaggareId: number | null,
      beslutHandlaggareId: number | null
    ): boolean {
      if (!aktivitetHandlaggareId || !beslutHandlaggareId) return false;

      const deltagare = useDeltagareStore();
      const stepper = useStepperStore();

      let resurs = undefined;
      let dialogPrompt = false;

      /**
       * När en deltagare skickas vidare måste man kontrollera emot medtagna interna resurser i
       * föregående beslutssteg
       */
      if (
        stepper.activeStepper === "skickaVidare" &&
        stepper.internResursId.length > 0
      ) {
        for (const id of stepper.internResursId) {
          const internResurs = deltagare.internResursList.find(
            (i) => i.id === id
          );

          if (internResurs) {
            if (internResurs.resursAnvandare.id === aktivitetHandlaggareId) {
              resurs = internResurs;
            }
          }
        }
      }

      /**
       * Om man byter huvudbeslut ska man kontrollera om aktivitetens handläggare redan finns
       * som intern resurs på beslutsspåret.
       */
      if (stepper.activeStepper === "bytHuvudbeslut") {
        resurs = deltagare.internResursList.find(
          (i) => i.resursAnvandare.id === aktivitetHandlaggareId
        );
      }

      /**
       * Om aktivitets handläggare redan finns som intern resurs på beslutsspåret kan man
       * inte lägga till den igen
       */
      if (
        stepper.activeStepper === "bytHuvudbeslut" ||
        stepper.activeStepper === "skickaVidare"
      ) {
        if (
          aktivitetHandlaggareId !== beslutHandlaggareId &&
          resurs !== undefined
        ) {
          const snackbar = useSnackbarStore();
          snackbar.genericSnackbarMessage =
            "Aktivitetens resurs finns redan som intern resurs och behöver inte läggas till igen.";
          snackbar.genericSnackbar = true;
        }
      }

      if (stepper.activeStepper === "addDeltagare") {
        // behövs ingen kontroll än
      }

      /**
       * Visa dialogen om att lägga till handläggare som intern resurs på beslutsspåret
       */
      if (
        aktivitetHandlaggareId !== beslutHandlaggareId &&
        resurs === undefined
      ) {
        dialogPrompt = true;
      }

      /**
       * Om beslutets handläggare är samma som aktivitetens handläggare återställ och visa meddelande
       */
      if (
        aktivitetHandlaggareId === beslutHandlaggareId &&
        stepper.activeStepper !== "none"
      ) {
        stepper.insatsModel.isInternResurs = false;
        this.insats.isInternResurs = false;

        const snackbar = useSnackbarStore();
        snackbar.genericSnackbarMessage =
          "Resurs är samma som beslutet och kommer inte läggas till som intern resurs";
        snackbar.genericSnackbar = true;
      }

      return dialogPrompt;
    },

    async avbrytAktivitet(insatsId: number | null | undefined) {
      if (!insatsId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.put({
          url: `Insats/${insatsId}/StatusTyp`,
          data: this.avbrytAktivitetForm,
        });

        snackbar.genericSnackbarMessage =
          "Aktivitet avbruten och flyttad till historik";
        snackbar.genericSnackbar = true;

        dialog.dialogAvbrytAktivitet = false;

        reload.toggleReload("reloadBeslutsparOversiktAktuellView");

        this.selectedInsats = null;
      } catch (error) {
        //
      }
    },

    async avslutaAktivitet(insatsId: number | null | undefined) {
      if (!insatsId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.put({
          url: `Insats/${insatsId}/StatusTyp`,
          data: this.avslutaAktivitetForm,
        });

        snackbar.genericSnackbarMessage =
          "Aktivitet avslutad och flyttad till historik";
        snackbar.genericSnackbar = true;

        dialog.dialogAvslutaAktivitet = false;

        reload.toggleReload("reloadBeslutsparOversiktAktuellView");

        this.selectedInsats = null;
      } catch (error) {
        //
      }
    },

    async updateAktivitetOmfattning(insatsId: number | null | undefined) {
      if (!insatsId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.put({
          url: `Insats/${insatsId}/Omfattning`,
          data: {
            omfattning: this.omfattning,
          },
        });

        snackbar.genericSnackbarMessage = "Omfattning uppdaterad";
        snackbar.genericSnackbar = true;

        dialog.dialogEditAktivitetOmfattning = false;

        reload.toggleReload("reloadBeslutsparOversiktAktuellView");

        this.selectedInsats = null;

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async forlangAktivitet(insatsId: number | null | undefined) {
      if (!insatsId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.put({
          url: `Insats/${insatsId}/ForlangDatum`,
          data: this.forlangAktivitetForm,
        });

        snackbar.genericSnackbarMessage = this.forlangAktivitetForm
          .forlangBeslut
          ? "Aktivitet och beslut förlängt"
          : "Aktivitet förlängd";
        snackbar.genericSnackbar = true;

        dialog.dialogForlangAktivitet = false;

        reload.toggleReload("reloadBeslutsparOversiktAktuellView");

        this.selectedInsats = null;
      } catch (error) {
        //
      }
    },

    async addMatchadInsats(
      selectedInsatsId: number | null | undefined,
      selectedPlatsId: number | null | undefined
    ) {
      if (!selectedInsatsId || !selectedPlatsId) return;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const plats = usePlatsStore();

        await crud.post({
          url: `Insats/${selectedInsatsId}/Matchning/${selectedPlatsId}`,
          data: this.insatsMatchningForm,
        });

        snackbar.genericSnackbarMessage = "Placering skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogPlaceraInsats = false;
        dialog.dialogMatchaAktivitet = false;

        deltagare.getBeslutList(deltagare.beslutsparId);

        this.selectedInsats = null;
        plats.selectedPlats = null;
      } catch (error) {
        //
      }
    },

    async addPlaneringFoljs(beslutsparId: number | null | undefined) {
      if (!beslutsparId) return;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        const data = (await crud.post({
          url: `Beslutspar/${beslutsparId}/Planfoljs`,
          data: this.planeringFoljsForm,
        })) as number;

        snackbar.genericSnackbarMessage = "Planering följs skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddPlaneringFoljs = false;

        reload.single("reloadBeslutsparOversiktAktuellView");

        await this.sendPlaneringFoljs(beslutsparId, data);
      } catch (error) {
        //
      }
    },

    /**
     * Skicka även till Omega
     */
    async sendPlaneringFoljs(beslutsparId: number, handelseId: number) {
      try {
        const crud = useCrudStore();

        await crud.put({
          url: `Beslutspar/${beslutsparId}/Uppdrag/SendPlaneringFolj/${handelseId}`,
        });
      } catch (error) {
        //
      }
    },
  },
});
