
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import RowTitle from "@/components/grid/RowTitle.vue";
import ADataTableVertical from "@/components/table/ADataTableVertical.vue";
import { ADataTableVerticalItem } from "@/models/table";
import { useAnvandareStore } from "@/store/anvandare";
import { useFastvalStore } from "@/store/fastval";
import { useHelpStore } from "@/store/help";
import { useStatusStore } from "@/store/status";
import { useStepperStore } from "@/store/stepper";

export default defineComponent({
  name: "KontrollAktivitet",

  components: {
    RowTitle,
    ADataTableVertical,
  },

  computed: {
    ...mapState(useStepperStore, ["insatsModel"]),
    ...mapState(useFastvalStore, ["findFvInsatsNamn"]),

    insatsInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "Aktivitet",
          value: this.nullableString(
            this.findFvInsatsNamn(this.insatsModel.fvInsatsId)
          ),
        },
        {
          key: "Period",
          value: `${this.nullableString(
            this.insatsModel.startdatum
          )} - ${this.nullableString(this.insatsModel.slutdatum)}`,
        },
        {
          key: "Status",
          value: "",
          component: "VerticalCellStatusTextAndIcon",
          iconSet: "period",
          status: this.statusTypForDatum(this.insatsModel.startdatum)?.namn,
        },
        {
          key: "Omfattning",
          value: this.nullableString(this.insatsModel.omfattning.toString()),
        },
        {
          key: "Projekt",
          value: this.nullableString(
            this.findFvProjektNamn(this.insatsModel.fvProjektId)
          ),
        },
        {
          key: "Resurs",
          value: this.nullableString(
            this.findGruppAnvandareNamn(this.insatsModel.handlaggareId)
          ),
        },
      ];
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString"]),
    ...mapActions(useStatusStore, ["statusTypForDatum"]),
    ...mapActions(useFastvalStore, ["findFvProjektNamn"]),
    ...mapActions(useAnvandareStore, ["findGruppAnvandareNamn"]),
  },
});
