
import Cookies from "js-cookie";
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent } from "vue";

import DialogAutoBevakning from "@/components/bevakning/DialogAutoBevakning.vue";
import StepperAddDeltagare from "@/components/deltagare/stepper/add-deltagare/StepperAddDeltagare.vue";
import DialogAbout from "@/components/dialog/DialogAbout.vue";
import DropdownMenu from "@/components/dropdown/DropdownMenu.vue";
import MeddelandeDropdown from "@/components/meddelande/MeddelandeDropdown.vue";
import PaminnelseDropdown from "@/components/paminnelse/PaminnelseDropdown.vue";
import DialogUppdrag from "@/components/uppdrag/dialog/DialogUppdrag.vue";
import { DropdownMenuItem } from "@/models/dropdown";
import { useAppStore } from "@/store/app";
import { useAuthStore } from "@/store/auth";
import { useDialogStore } from "@/store/dialog";

export default defineComponent({
  name: "AppBar",

  components: {
    DropdownMenu,
    MeddelandeDropdown,
    PaminnelseDropdown,
    DialogAutoBevakning,
    DialogUppdrag,
    StepperAddDeltagare,
    DialogAbout,
  },

  mounted() {
    if (this.isAuth) {
      this.getUser();
    }
  },

  computed: {
    ...mapState(useAuthStore, ["isAuth", "userName"]),
    ...mapWritableState(useAppStore, ["drawer"]),

    appName(): string | undefined {
      return process.env.VUE_APP_TITLE ?? "---";
    },

    version(): string | undefined {
      return process.env.VUE_APP_VERSION ?? "v0.0.0";
    },

    userMenuList(): DropdownMenuItem[] {
      return [
        { header: "användarmeny" },
        {
          title: "Profil (wip)",
          icon: "mdi-account",
          disabled: false,
          emit: { name: "", value: "" },
        },
        {
          title: "Mitt konto (wip)",
          icon: "mdi-account-check",
          disabled: false,
          emit: { name: "", value: "" },
        },
        {
          title: "Mina inställningar (wip)",
          icon: "mdi-account-cog",
          disabled: false,
          emit: { name: "", value: "" },
        },
        {
          title: this.appName,
          icon: "mdi-information-outline",
          disabled: false,
          emit: { name: "open-dialog", value: "dialogAbout" },
        },
        { divider: true },
        {
          title: "Logga ut",
          icon: "mdi-logout",
          disabled: false,
          emit: { name: "logout", value: "" },
        },
      ];
    },
  },

  methods: {
    ...mapActions(useAuthStore, ["getUser"]),
    ...mapActions(useDialogStore, ["open"]),

    logout() {
      Cookies.remove("accorda10-rev8-token");
      location.reload();
    },
  },
});
