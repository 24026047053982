import { defineStore } from "pinia";

export const useSnackbarStore = defineStore("snackbar", {
  state: () => ({
    genericSnackbar: false,
    genericSnackbarMessage: null as string | null,
    errorSnackbar: false,
    errorSnackbarMessage: null as string | null,

    snackbarQueue: [] as { message: string; color: string | null }[],
  }),

  actions: {
    addSnackbar(message: string, color = null) {
      this.snackbarQueue.push({
        message: message,
        color: color,
      });
    },
  },
});
