
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import ADataTableVertical from "@/components/table/ADataTableVertical.vue";
import { ADataTableVerticalItem } from "@/models/table";
import { useAnvandareStore } from "@/store/anvandare";
import { useFastvalStore } from "@/store/fastval";
import { useHelpStore } from "@/store/help";
import { useStatusStore } from "@/store/status";
import { useStepperStore } from "@/store/stepper";

export default defineComponent({
  name: "KontrollBeslut",

  components: {
    ADataTableVertical,
  },

  computed: {
    ...mapState(useStepperStore, ["beslut"]),
    ...mapState(useFastvalStore, ["findGruppFvBeslutNamn"]),

    beslutInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "Beslut",
          value: this.nullableString(
            this.findGruppFvBeslutNamn(this.beslut.fvBeslutId)
          ),
        },
        {
          key: "Period",
          value: `${this.nullableString(
            this.beslut.startdatum
          )} - ${this.nullableString(this.beslut.slutdatum)}`,
        },
        {
          key: "Status",
          value: "",
          component: "VerticalCellStatusTextAndIcon",
          iconSet: "period",
          status: this.statusTypForDatum(this.beslut.startdatum)?.namn,
        },
        {
          key: "Ärendenummer",
          value: this.nullableString(this.beslut.arendeNummer),
        },
        {
          key: "Projekt",
          value: this.nullableString(
            this.findFvProjektNamn(this.beslut.fvProjektId)
          ),
        },
        {
          key: "Handläggare",
          value: this.nullableString(
            this.findGruppAnvandareNamn(this.beslut.handlaggareId)
          ),
        },
      ];
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString"]),
    ...mapActions(useStatusStore, ["statusTypForDatum"]),
    ...mapActions(useAnvandareStore, ["findGruppAnvandareNamn"]),
    ...mapActions(useFastvalStore, ["findFvProjektNamn"]),
  },
});
