import { AxiosRequestHeaders } from "axios";
import { defineStore } from "pinia";

import { accorda } from "@/service/accorda";

import { useErrorStore } from "./error";

export const useCrudStore = defineStore("crud", {
  actions: {
    get(args: {
      url: string;
      headers?: AxiosRequestHeaders;
      params?: unknown;
    }) {
      return new Promise((resolve, reject) => {
        accorda
          .get(args.url, {
            headers: args.headers,
            params: args.params,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            const errorStore = useErrorStore();
            errorStore.handle(error.response);

            reject(error.response);
          });
      });
    },

    post(args: { url: string; data?: unknown }) {
      return new Promise((resolve, reject) => {
        accorda
          .post(args.url, args.data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            const errorStore = useErrorStore();
            errorStore.handle(error.response);

            reject(error.response);
          });
      });
    },

    put(args: { url: string; data?: unknown }) {
      return new Promise((resolve, reject) => {
        accorda
          .put(args.url, args.data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            const errorStore = useErrorStore();
            errorStore.handle(error.response);

            reject(error.response);
          });
      });
    },

    destroy(args: { url: string }) {
      return new Promise((resolve, reject) => {
        accorda
          .delete(args.url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            const errorStore = useErrorStore();
            errorStore.handle(error.response);

            reject(error.response);
          });
      });
    },

    download(args: { url: string }) {
      return new Promise((resolve, reject) => {
        accorda
          .get(args.url, {
            responseType: "blob",
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            const errorStore = useErrorStore();
            errorStore.handle(error.response);

            reject(error.response);
          });
      });
    },
  },
});
