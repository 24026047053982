
import { defineComponent, PropType } from "vue";

import { ADataTableVerticalItem } from "@/models/table";

import VerticalCellAktivitetMatchad from "./cell/VerticalCellAktivitetMatchad.vue";
import VerticalCellAnvandareList from "./cell/VerticalCellAnvandareList.vue";
import VerticalCellArbetsstallePlatsPlaceringStatus from "./cell/VerticalCellArbetsstallePlatsPlaceringStatus.vue";
import VerticalCellArbetsstalleRoll from "./cell/VerticalCellArbetsstalleRoll.vue";
import VerticalCellPlaceringar from "./cell/VerticalCellPlaceringar.vue";
import VerticalCellPrimarKontakt from "./cell/VerticalCellPrimarKontakt.vue";
import VerticalCellRawHtml from "./cell/VerticalCellRawHtml.vue";
import VerticalCellStatusAktuell from "./cell/VerticalCellStatusAktuell.vue";
import VerticalCellStatusTextAndIcon from "./cell/VerticalCellStatusTextAndIcon.vue";
import VerticalCellWithButton from "./cell/VerticalCellWithButton.vue";
import VerticalCellWithKey from "./cell/VerticalCellWithKey.vue";

export default defineComponent({
  name: "ADataTableVertical",

  components: {
    VerticalCellRawHtml,
    VerticalCellAktivitetMatchad,
    VerticalCellPrimarKontakt,
    VerticalCellAnvandareList,
    VerticalCellStatusAktuell,
    VerticalCellStatusTextAndIcon,
    VerticalCellPlaceringar,
    VerticalCellArbetsstalleRoll,
    VerticalCellArbetsstallePlatsPlaceringStatus,
    VerticalCellWithButton,
    VerticalCellWithKey,
  },

  props: {
    items: {
      type: [] as unknown as PropType<ADataTableVerticalItem[] | []>,
    },

    tableClass: {
      type: String,
      default: "ml-3",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    keyColumnWidth: {
      type: Number,
      default: 100,
    },

    valueColumnWidth: {
      type: Number,
      default: 0,
    },

    hideKeyColumn: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    headers: [
      {
        text: "key",
        value: "key",
        sortable: false,
        cellClass: "caption font-weight-bold",
        width: 100,
      },
      {
        text: "value",
        value: "value",
        sortable: false,
        cellClass: "caption",
      },
    ],
  }),
});
