
import { mapActions, mapState } from "pinia";
import { defineComponent, PropType } from "vue";

import AAlert from "@/components/alert/AAlert.vue";
import ACard from "@/components/card/ACard.vue";
import { StepProps } from "@/models/stepper";
import { Uppdrag } from "@/models/uppdrag";
import { useAnvandareStore } from "@/store/anvandare";
import { useDateStore } from "@/store/date";
import { useStepperStore } from "@/store/stepper";
import { useUppdragStore } from "@/store/uppdrag";

import FormNyAktivitet from "../form/FormNyAktivitet.vue";

export default defineComponent({
  name: "StepAktivitet",

  components: {
    AAlert,
    ACard,
    FormNyAktivitet,
  },

  props: {
    stepProps: {
      type: Object as PropType<StepProps>,
      required: true,
    },
  },

  computed: {
    ...mapState(useStepperStore, ["beslutspar"]),
    ...mapState(useAnvandareStore, ["findAnvandareGruppNamn"]),
    ...mapState(useUppdragStore, [
      "isUppdrag",
      "uppdragList",
      "selectedUppdragId",
    ]),

    valtUppdrag(): Uppdrag | null {
      const uppdrag = this.uppdragList.find(
        (i) => i.id === this.selectedUppdragId
      );

      if (uppdrag !== undefined) {
        return uppdrag;
      }

      return null;
    },
  },

  methods: {
    ...mapActions(useDateStore, ["formatDate"]),
  },
});
