
import { mapWritableState } from "pinia";
import { defineComponent } from "vue";

import { useSnackbarStore } from "@/store/snackbar";

export default defineComponent({
  name: "ErrorSnackbar",

  watch: {
    errorSnackbar: {
      handler(value) {
        if (!value) {
          this.errorSnackbarMessage = null;
        }
      },
    },
  },

  computed: {
    ...mapWritableState(useSnackbarStore, [
      "errorSnackbar",
      "errorSnackbarMessage",
    ]),
  },
});
