
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "AListItemLoading",

  props: {
    mini: {
      type: Boolean,
      required: true,
    },

    listWidth: {
      type: null as unknown as PropType<number | null | undefined>,
      default: 230,
      required: false,
    },

    loadingText: {
      type: String,
      default: "Hämtar data...",
    },

    chipColor: {
      type: String,
      default: "",
    },

    progressColor: {
      type: String,
      default: "grey darken-3",
    },

    loadingTextColor: {
      type: String,
      default: "grey--text text--darken-3",
    },
  },
});
