/**
 * Sätter stor bokstav på första bokstaven i strängen
 *
 * @param {string} str - sträng som ska ha stor bokstav
 * @returns {string} - sträng med första bokstaven stor
 *
 * @since version 0.5.0
 */
export const storBokstav = (str: string): string | null => {
  if (!str) return null;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Array med svenska ord för siffror, används i funktionen numberToWord
 *
 * @since Version 0.5.0
 */
export const numberWords = [
  "ett",
  "en",
  "två",
  "tre",
  "fyra",
  "fem",
  "sex",
  "sju",
  "åtta",
  "nio",
  "tio",
];

/**
 * Konverterar siffror till svenska namn på siffror upp till 10
 *
 * ett-ord / en-ord (neutrum, utrum)
 * @see https://sverigesradio.se/artikel/4589593
 *
 * @param {number} number - nummer som ska konverteras
 * @param {Boolean} tOrd - ska neutrum användas (ett)
 * @param {Boolean} capitalize - ska första bokstaven vara stor
 * @returns {string} - ord för siffra
 *
 * @since version 0.5.0
 */
export const numberToWord = (
  number: number,
  tOrd = false,
  capitalize = false
): string | number | null => {
  if (number >= 11) {
    return number;
  }

  if (tOrd && number === 1) {
    if (capitalize) {
      return storBokstav(numberWords[0]);
    }
    return numberWords[0];
  }

  if (capitalize) {
    return storBokstav(numberWords[number]);
  }

  return numberWords[number];
};

/**
 * Konverterar bytes till läsbar form
 *
 * @since version 0.8.7
 */
export const convertBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return "0";
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "kB", "MB", "GB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default {
  storBokstav,
  numberWords,
  numberToWord,
  convertBytes,
};
