import { defineStore } from "pinia";

import {
  SenasteUtbildning,
  SysselsattningKontakt,
  SysselsattningVidAvslut,
  Utbildningsprogram,
} from "@/models/kaa";

import { useCrudStore } from "./crud";

export const useKaaStore = defineStore("kaa", {
  state: () => ({
    kaaSenasteUtbildningList: [] as SenasteUtbildning[],
    kaaUtbildningsprogramList: [] as Utbildningsprogram[],
    kaaSysselsattningKontaktList: [] as SysselsattningKontakt[],
    kaaSysselsattningVidAvslutList: [] as SysselsattningVidAvslut[],
    kaaFvBeslutList: [],
    kaaUList: [],
    kaaIList: [],
  }),

  getters: {
    findKaaSysselsattningKontaktNamn:
      (state) =>
      (sysselsattningId: number | null): string | null => {
        if (!sysselsattningId) return null;

        const sysselsattning = state.kaaSysselsattningKontaktList.find(
          (i) => i.id === sysselsattningId
        );

        return sysselsattning?.namn ?? null;
      },

    findKaaSenasteUtbildningNamn:
      (state) =>
      (senasteUtbildningId: number | null): string | null => {
        if (!senasteUtbildningId) return null;

        const senasteUtbildning = state.kaaSenasteUtbildningList.find(
          (i) => i.id === senasteUtbildningId
        );

        return senasteUtbildning?.namn ?? null;
      },

    findKaaUtbildningsprogramNamn:
      (state) =>
      (utbildningsprogramId: number | null): string | null => {
        if (!utbildningsprogramId) return null;

        const utbildningsprogram = state.kaaUtbildningsprogramList.find(
          (i) => i.id === utbildningsprogramId
        );

        return utbildningsprogram?.namn ?? null;
      },
  },

  actions: {
    async getKaaSenasteUtbildningList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Kaa/SenasteUtbildning",
          params: {
            sortBy: "namn",
            sortOrder: "asc",
          },
        })) as SenasteUtbildning[];

        this.kaaSenasteUtbildningList = data;
      } catch (error) {
        //
      }
    },

    async getKaaUtbildningsprogramList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Kaa/Utbildningsprogram",
          params: {
            sortBy: "namn",
            sortOrder: "asc",
          },
        })) as Utbildningsprogram[];

        this.kaaUtbildningsprogramList = data;
      } catch (error) {
        //
      }
    },

    async getKaaSysselsattningKontaktList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Kaa/SysselsattningKontakt",
          params: {
            sortBy: "namn",
            sortOrder: "asc",
          },
        })) as SysselsattningKontakt[];

        this.kaaSysselsattningKontaktList = data;
      } catch (error) {
        //
      }
    },

    async getKaaSysselsattningVidAvslutList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Kaa/SysselsattningVidAvslut",
          params: {
            sortBy: "namn",
            sortOrder: "asc",
          },
        })) as SysselsattningVidAvslut[];

        this.kaaSysselsattningVidAvslutList = data;
      } catch (error) {
        //
      }
    },

    async getKaaFvBeslutList() {
      try {
        const crud = useCrudStore();

        const data = await crud.get({
          url: "Kaa/FvBeslut",
        });

        console.log(data);
      } catch (error) {
        //
      }
    },

    async getKaaAktivitetKaaUList(uppsokande = false) {
      try {
        const crud = useCrudStore();

        const data = await crud.get({
          url: "Kaa/Aktivitet/KAA-U",
          params: {
            uppsokande: uppsokande,
            sortBy: "namn",
            sortOrder: "asc",
          },
        });

        return data;
      } catch (error) {
        //
      }
    },

    async getKaaAktivitetKaaIList(uppsokande = false) {
      try {
        const crud = useCrudStore();

        const data = await crud.get({
          url: "Kaa/Aktivitet/KAA-I",
          params: {
            uppsokande: uppsokande,
            sortBy: "namn",
            sortOrder: "asc",
          },
        });

        return data;
      } catch (error) {
        //
      }
    },
  },
});
