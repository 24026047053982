import axios from "axios";
import Cookies from "js-cookie";

export const accorda = axios.create({
  baseURL: process.env.VUE_APP_ACCORDA_API,
  timeout: process.env.NODE_ENV === "production" ? 5000 : 20000,
});

accorda.interceptors.request.use(
  (config) => {
    const token = Cookies.get("accorda10-rev8-token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  accorda,
};
