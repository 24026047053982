
import "@/validation/rules";

import { mapActions, mapState, mapWritableState } from "pinia";
import { ValidationProvider } from "vee-validate";
import { defineComponent } from "vue";

import { Postnummer } from "@/models/adress";
import { useAdressStore } from "@/store/adress";

export default defineComponent({
  name: "InputPostnummer",

  components: {
    ValidationProvider,
  },

  props: {
    initField: {
      type: Boolean,
      default: false,
      required: true,
    },

    resetField: {
      type: Boolean,
      default: false,
      required: true,
    },

    appendIcon: {
      type: String,
      default: null,
    },

    hint: {
      type: String,
      default: null,
    },

    vid: {
      type: String,
      default: "Postnummer",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    initField: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getPostnummerOrtList();
        }
      },
    },

    resetField: {
      handler(value) {
        if (value) {
          this.adressForm.postnummer = null;
          this.adressForm.displayPostnr = null;
        }
      },
    },
  },

  computed: {
    ...mapState(useAdressStore, ["postnummerList"]),
    ...mapWritableState(useAdressStore, ["adressForm"]),

    postnummerLocal: {
      get(): string | null | undefined {
        return this.adressForm.displayPostnr;
      },

      set(value: string | Postnummer) {
        if (typeof value === "string") {
          this.adressForm.postnummer = value.replace(/\s/g, "");
          this.adressForm.displayPostnr = value.replace(
            /^(\d{3})(\d{2})$/g,
            "$1 $2"
          );
        }

        if (typeof value === "object") {
          if (!value) {
            this.adressForm.postnummer = null;
            this.adressForm.displayPostnr = null;
            this.adressForm.ort = null;
          }

          if (value) {
            this.adressForm.postnummer = value.namn.replace(/\s/g, "");
            this.adressForm.displayPostnr = value.namn.replace(
              /^(\d{3})(\d{2})$/g,
              "$1 $2"
            );
          }
        }
      },
    },
  },

  methods: {
    ...mapActions(useAdressStore, ["getPostnummerOrtList"]),
  },
});
