
import { defineComponent, PropType } from "vue";

import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellArbetsstallePlatsPlaceringStatus",

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    color(): string | null {
      return this.item?.color ? this.item.color : null;
    },

    status(): string | null {
      return this.item?.status ? this.item.status : null;
    },
  },
});
