import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import sv from "vuetify/src/locale/sv";

import light from "./lightThemeAlfa";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { sv },
    current: "sv",
  },

  theme: {
    dark: false,
    themes: {
      light,
    },
  },
});
