
import { defineComponent } from "vue";

export default defineComponent({
  name: "RowTitle",

  props: {
    title: {
      type: String,
      default: null,
    },
  },
});
