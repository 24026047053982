import { AxiosResponse } from "axios";
import { defineStore } from "pinia";

import { useSnackbarStore } from "./snackbar";

export const useErrorStore = defineStore("error", {
  state: () => ({
    errors: [],
  }),

  actions: {
    handle(error: AxiosResponse) {
      if (error === undefined) {
        this.criticalError();
        return;
      }

      if (error.status >= 500) {
        this.serversideError(error);
      }

      if (error.status > 399 && error.status < 500) {
        this.clientSideError(error);
      }
    },

    criticalError() {
      console.log("critical error");
    },

    serversideError(error: AxiosResponse) {
      console.log("serversideError", error);

      /**
       * @todo kolla om detta kan uppstå ens
       */
      if (Object.prototype.hasOwnProperty.call(error.data, "errors")) {
        if (error.data.errors.length !== 0) {
          const snackbar = useSnackbarStore();

          snackbar.errorSnackbarMessage =
            "Ett serverfel har uppstått, vänligen kontakta supporten.";
          snackbar.errorSnackbar = true;
        }
      }

      /**
       * Visar delar av felmeddelandet från server
       */
      if (Object.prototype.hasOwnProperty.call(error, "data")) {
        const snackbar = useSnackbarStore();

        const text = "Ett serverfel har uppstått, vänligen kontakta supporten.";
        const excerpt = error.data.substring(0, 150);

        snackbar.errorSnackbarMessage = `${text} | ${excerpt}`;
        snackbar.errorSnackbar = true;
      }
    },

    /**
     * Oftast formulär fel
     */
    clientSideError(error: AxiosResponse) {
      console.log("clientSideError", error);

      if (Object.prototype.hasOwnProperty.call(error.data, "errors")) {
        this.errors = error.data.errors;
      }

      let errorMessage = null as string | null;

      switch (error.status) {
        case 400:
          /**
           * Bad Request
           *
           * Används tillfälligt för utveckling för att visa exakt fel.
           *
           * @since version 0.8.24
           */
          errorMessage = "";

          for (const [key, value] of Object.entries(error.data.errors)) {
            const textList = value as string[];

            for (const v of textList) {
              errorMessage += `${key}: ${v}`;
            }
          }

          break;
        case 401:
          // Unauthorized
          errorMessage = "Autentisering misslyckades";
          break;

        case 403:
          // Forbidden
          errorMessage = "Otillåten åtkomst";
          break;

        case 404:
          // Not Found
          errorMessage = "Kunde inte hittas";
          break;

        case 415:
          /**
           * Unsupported Media Type
           *
           * Tillfälligt under utveckling
           */
          errorMessage = "Unsupported Media Type";
          break;

        default:
          errorMessage = "Någonting gick fel";
      }

      if (errorMessage) {
        const snackbar = useSnackbarStore();

        snackbar.errorSnackbarMessage = errorMessage;
        snackbar.errorSnackbar = true;
      }
    },
  },
});
