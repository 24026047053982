import dayjs from "dayjs";
import { ManipulateType, OpUnitType, QUnitType } from "dayjs";

require("dayjs/locale/sv");
dayjs.locale("sv");

import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

export const format = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  format = "YYYY-MM-DD"
): string => {
  return dayjs(date).format(format);
};

export const add = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  quantity: number,
  unit?: ManipulateType | undefined
) => {
  return dayjs(date).add(quantity, unit);
};

export const subtract = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  quantity: number,
  unit?: ManipulateType | undefined
) => {
  return dayjs(date).subtract(quantity, unit);
};

export const isAfter = (
  currentDay: string | number | Date | dayjs.Dayjs | null | undefined,
  thisDay: string | number | Date | dayjs.Dayjs | null | undefined
): boolean => {
  return dayjs(currentDay).isAfter(dayjs(thisDay));
};

export const isBefore = (
  currentDay: string | number | Date | dayjs.Dayjs | null | undefined,
  thisDay: string | number | Date | dayjs.Dayjs | null | undefined,
  unit?: ManipulateType | undefined
): boolean => {
  return dayjs(currentDay).isBefore(dayjs(thisDay, unit));
};

export const isSame = (
  currentDay: string | number | Date | dayjs.Dayjs | null | undefined,
  thisDay: string | number | Date | dayjs.Dayjs | null | undefined,
  unit?: ManipulateType | undefined
): boolean => {
  return dayjs(currentDay).isSame(thisDay, unit);
};

interface IPosition {
  before: boolean;
  same: boolean;
  after: boolean;
}

export const position = (
  selectedDate: string | number | Date | dayjs.Dayjs | null | undefined
): IPosition => {
  const before = isBefore(
    selectedDate,
    new Date().toISOString().substring(0, 10),
    "day"
  );
  const same = isSame(
    new Date().toISOString().substring(0, 10),
    selectedDate,
    "day"
  );
  const after = isAfter(
    selectedDate,
    new Date().toISOString().substring(0, 10)
  );

  return {
    before: before,
    same: same,
    after: after,
  };
};

export const betweenMinMax = (
  current: string | number | Date | dayjs.Dayjs | null | undefined,
  a: string | number | Date | dayjs.Dayjs | null | undefined,
  b: string | number | Date | dayjs.Dayjs | null | undefined
): boolean => {
  return dayjs(current).isBetween(a, b, null, "[]");
};

/**
 * Räknar ut skillnaden mellan två datum.
 *
 * @since Version 0.11.0
 */
export const getDiffBetweenTwoDates = (
  a: string | number | Date | dayjs.Dayjs | null | undefined,
  b: string | number | Date | dayjs.Dayjs | null | undefined,
  unit: QUnitType | OpUnitType = "year"
): number => {
  const date1 = dayjs(a);
  const date2 = dayjs(b);

  return date2.diff(date1, unit);
};

export default {
  format,
  add,
  subtract,
  isAfter,
  isBefore,
  isSame,
  position,
};
