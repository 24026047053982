
import { defineComponent } from "vue";

import DialogDefault from "./DialogDefault.vue";

export default defineComponent({
  name: "DialogWip",

  components: {
    DialogDefault,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    toolbarTitle: {
      type: String,
      default: "Under utveckling",
    },
  },
});
