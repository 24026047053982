import { defineStore } from "pinia";

import { ExternResurs, ExternResursOrganisation } from "@/models/resurs";

import { useCrudStore } from "./crud";
import { useDeltagareStore } from "./deltagare";
import { useDialogStore } from "./dialog";
import { useFormStore } from "./form";
import { useKontaktuppgiftStore } from "./kontaktuppgift";
import { useReloadStore } from "./reload";
import { useSnackbarStore } from "./snackbar";
import { useStepperStore } from "./stepper";

export const useExternResursStore = defineStore("externresurs", {
  state: () => ({
    beslutsparExternResursForm: {
      externResursOrganisationId: null as number | null,
      resursId: null as number | null,
    },

    isEditExternResursOrganisation: false,
    externResursOrganisationForm: {
      id: null as number | null,
      namn: null as string | null,
    },

    isEditResurs: false,
    selectedResurs: null as ExternResurs | null,
    resursForm: {
      id: null as number | null,
      fornamn: null as string | null,
      efternamn: null as string | null,
      namn: null as string | null,
      externResursOrganisationId: null as number | null,
      telefon: null as string | null,
      epost: null as string | null,
    },

    externResursOrganisationList: [] as ExternResursOrganisation[],
    beslutsparExternResursList: [] as ExternResurs[],
    externResursList: [] as ExternResurs[],
    externResursOrganisationPeopleList: [] as {
      id: number;
      namn: string;
    }[],
  }),

  getters: {
    findTaMedExternResursList(state): ExternResurs[] {
      const stepper = useStepperStore();
      const array = [] as ExternResurs[];

      for (const id of stepper.externResursId) {
        const resurs = state.beslutsparExternResursList.find(
          (i) => i.id === id
        ) as ExternResurs;

        array.push(resurs);
      }

      return array;
    },
  },

  actions: {
    formatExternResursList(items: ExternResurs[]): ExternResurs[] {
      const kontaktuppgift = useKontaktuppgiftStore();

      return items.map((item) => ({
        ...item,
        kontaktuppgift: kontaktuppgift.sortKontaktuppgiftList(
          kontaktuppgift.formatKontaktuppgiftList(item.kontaktuppgift)
        ),
      }));
    },

    async addExternResursBeslutspar() {
      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.put({
          url: `Beslutspar/${deltagare.beslutsparId}/ExternResurs/${this.beslutsparExternResursForm.resursId}`,
        });

        snackbar.genericSnackbarMessage = "Extern resurs har lagts till";
        snackbar.genericSnackbar = true;

        dialog.dialogAddExternResursBeslutspar = false;

        reload.single("reloadAfterAddExternResursBeslutspar");
      } catch (error) {
        //
      }
    },

    async getExternResursOrganisationToEdit(
      externResursOrganisationId: number | null | undefined
    ) {
      if (!externResursOrganisationId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `externresursorganisation/${externResursOrganisationId}`,
        })) as ExternResursOrganisation;

        this.externResursOrganisationForm.id = data.id;
        this.externResursOrganisationForm.namn = data.namn;
      } catch (error) {
        //
      }
    },

    async addEditExternResursOrganisation() {
      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();

        if (this.isEditExternResursOrganisation) {
          await crud.put({
            url: `externresursorganisation/${this.externResursOrganisationForm.id}`,
            data: this.externResursOrganisationForm,
          });
        }

        if (!this.isEditExternResursOrganisation) {
          await crud.post({
            url: "externresursorganisation",
            data: this.externResursOrganisationForm,
          });
        }

        snackbar.genericSnackbarMessage = this.isEditExternResursOrganisation
          ? "Organisation uppdaterad"
          : "Organisation skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditExternResursOrganisation = false;

        this.getExternResursOrganisationList();
      } catch (error) {
        //
      }
    },

    async getResursToEdit(resursId: number | null | undefined) {
      if (!resursId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `externresurs/${resursId}`,
        })) as ExternResurs;

        this.resursForm.id = data.id;
        this.resursForm.fornamn = data.fornamn;
        this.resursForm.efternamn = data.efternamn;
        this.resursForm.externResursOrganisationId =
          data.externResursOrganisationId;

        this.selectedResurs = data;
      } catch (error) {
        //
      }
    },

    async addEditResurs(): Promise<void> {
      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const form = useFormStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        if (this.isEditResurs) {
          await crud.put({
            url: `externresurs/${this.resursForm.id}`,
            data: this.resursForm,
          });
        }

        if (!this.isEditResurs) {
          await crud.post({
            url: "externresurs",
            data: this.resursForm,
          });
        }

        snackbar.genericSnackbarMessage = this.isEditResurs
          ? "Extern resurs uppdaterad"
          : "Extern resurs skapad";
        snackbar.genericSnackbar = true;

        if (this.isEditResurs) {
          this.getResursToEdit(this.resursForm.id);
          form.isDirty = false;
          this.selectedResurs = null;
          reload.single("reloadAfterEditResurs");
        } else {
          reload.single("reloadAfterAddResurs");
          dialog.dialogAddResurs = false;
        }

        this.getExternResursList();
      } catch (error) {
        //
      }
    },

    async getExternResursOrganisationList(
      allaBestallare = true,
      ejaktuella = false
    ) {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "ExternResursOrganisation",
          params: {
            allabestallare: allaBestallare,
            ejaktuella: ejaktuella,
          },
        })) as ExternResursOrganisation[];

        this.externResursOrganisationList = data;
      } catch (error) {
        //
      }
    },

    async getBeslutsparExternResursList(beslutsparId: number | null) {
      if (!beslutsparId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Beslutspar/${beslutsparId}/ExternResurs`,
        })) as ExternResurs[];

        this.beslutsparExternResursList = this.formatExternResursList(data);
      } catch (error) {
        //
      }
    },

    async getExternResursList(ejaktuella = false) {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "externresurs",
          params: {
            ejaktuella: ejaktuella,
          },
        })) as ExternResurs[];

        this.externResursList = this.formatExternResursList(data);
      } catch (error) {
        //
      }
    },

    async getExternResursOrganisationPeopleList(
      externResursOrganisationId: number | null | undefined
    ) {
      if (!externResursOrganisationId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `ExternResursOrganisation/${externResursOrganisationId}/ExternResurs`,
        })) as {
          id: number;
          fornamn: string;
          efternamn: string;
        }[];

        this.externResursOrganisationPeopleList = data.map((i) => ({
          id: i.id,
          namn: `${i.fornamn} ${i.efternamn ?? ""}`,
        }));
      } catch (error) {
        //
      }
    },
  },
});
