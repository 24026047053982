import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

import {
  StyleReference,
  Table,
  TableCell,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import { defineStore } from "pinia";

import { format } from "@/helpers/date";
import { FilterData } from "@/models/filterListgeneratorStatistik";

import { useAuthStore } from "./auth";
import { useFilterListgeneratorStatistikStore } from "./filterListgeneratorStatistik";
import { useHelpStore } from "./help";
import { useListGeneratorStore } from "./listgenerator";

export const useGeneratePdfStore = defineStore("generatePdf", {
  state: () => ({}),

  getters: {},

  actions: {
    async generateBeslutListA(filterData: FilterData) {
      const bgColor1 = "#dbdbdb";
      const bgColor3 = "#f0f0f0";

      const listgenerator = useListGeneratorStore();
      const filterListgeneratorStatistik =
        useFilterListgeneratorStatistikStore();
      const auth = useAuthStore();
      const help = useHelpStore();

      const dagensDatum = format(
        new Date().toISOString().substring(0, 10),
        "D MMMM YYYY"
      );
      const anvNamn = auth.userName;
      const deltagareCount = listgenerator.resultData.deltagare.length;
      const anvandareGruppList =
        filterListgeneratorStatistik.inloggadAnvandareGruppList;
      const period = filterListgeneratorStatistik.inskrivningsperiodText;
      const filtreringar = filterListgeneratorStatistik.setFiltreringar(
        filterData,
        anvandareGruppList
      );

      const tableList = [] as {
        style?: StyleReference | undefined;
        unbreakable?: boolean | undefined;
        table: Table;
      }[];

      let isGenerated = false;

      try {
        for (const deltagare of listgenerator.resultData.deltagare) {
          const beslutsparList = [] as { style?: string; table: Table }[];

          for (const inskrivning of deltagare.inskrivning) {
            for (const beslutspar of inskrivning.beslutspar) {
              const beslutList = [
                [
                  { text: "Beställare", fillColor: bgColor3 },
                  { text: "Beslut", fillColor: bgColor3 },
                  { text: "Ärendenummer", fillColor: bgColor3 },
                  { text: "Beslutsperiod", fillColor: bgColor3 },
                  { text: "Veckor", fillColor: bgColor3 },
                  { text: "Veckor*", fillColor: bgColor3 },
                  { text: "Status", fillColor: bgColor3 },
                  { text: "Vidare till", fillColor: bgColor3 },
                  { text: "Handläggare", fillColor: bgColor3 },
                ],
              ] as TableCell[][];

              for (const beslut of beslutspar.beslut) {
                beslutList.push([
                  "---",
                  beslut.fvBeslut.namn,
                  beslut.arendeNummer ? beslut.arendeNummer : "---",
                  format(beslut.startdatum) + " - " + format(beslut.slutdatum),
                  "0",
                  "0",
                  beslut.status,
                  "---",
                  "---",
                ]);
              }

              beslutsparList.push(
                {
                  table: {
                    headerRows: 0,
                    widths: ["*"],
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          columns: [
                            {
                              text: "Spår:",
                              fontSize: 8,
                              bold: true,
                              width: 50,
                            },
                            { text: beslutspar.grupp.namn, fontSize: 8 },
                            {
                              text: "Initierad av: ",
                              fontSize: 8,
                              bold: true,
                              width: 50,
                            },
                            { text: "---", fontSize: 8 },
                            {
                              text: "Ingångsvärde: ",
                              fontSize: 8,
                              bold: true,
                              width: 55,
                            },
                            {
                              text: beslutspar.beslutsparMetaData.fvIngangsvarde
                                .namn,
                              fontSize: 8,
                              width: 100,
                            },
                            {
                              text: "Spårstart: ",
                              fontSize: 8,
                              bold: true,
                              width: 40,
                            },
                            {
                              text: format(beslutspar.createDate),
                              fontSize: 8,
                              width: 50,
                            },
                            {
                              text: "Ersättning ut: ",
                              fontSize: 8,
                              bold: true,
                              width: 50,
                            },
                            { text: "---", fontSize: 8 },
                          ],
                        },
                      ],
                      [
                        {
                          border: [false, false, false, false],
                          columns: [
                            {
                              text: "Spårperiod: ",
                              fontSize: 8,
                              bold: true,
                              width: 50,
                            },
                            { text: "0 veckor", fontSize: 8 },
                            {
                              text: "Ersättning in: ",
                              fontSize: 8,
                              bold: true,
                              width: 50,
                            },
                            { text: "---", fontSize: 8 },
                            {
                              text: "Stödnivå: ",
                              fontSize: 8,
                              bold: true,
                              width: 55,
                            },
                            { text: "---", fontSize: 8, width: 100 },
                            {
                              text: "Spårslut: ",
                              fontSize: 8,
                              bold: true,
                              width: 40,
                            },
                            { text: "0000-00-00", fontSize: 8, width: 50 },
                            {
                              text: "Avslutsorsak: ",
                              fontSize: 8,
                              bold: true,
                              width: 50,
                            },
                            { text: "---", fontSize: 8 },
                          ],
                        },
                      ],
                    ],
                  },
                },
                {
                  style: "nestedTableStyle",
                  table: {
                    headerRows: 1,
                    keepWithHeaderRows: 0,
                    dontBreakRows: true,
                    widths: [
                      "*",
                      "*",
                      "auto",
                      "auto",
                      "auto",
                      "auto",
                      "auto",
                      "auto",
                      "*",
                    ],
                    body: beslutList,
                  },
                }
              );
            }
          }

          tableList.push({
            style: "mainTableStyle",
            unbreakable: true,
            table: {
              headerRows: 1,
              keepWithHeaderRows: 0,
              dontBreakRows: true,
              widths: ["*"],
              body: [
                [
                  {
                    text: `${deltagare.namn} / ${help.formateraPersonnummer(
                      deltagare.personnummer
                    )}`,
                    fontSize: 10,
                    bold: true,
                    fillColor: bgColor1,
                  },
                ],
                [beslutsparList],
              ],
            },
          });
        }

        const docDefinition = {
          pageOrientation: "landscape",

          content: [
            {
              columns: [
                { text: "Beslutslista", style: "header", width: "*" },
                {
                  text: `Antal deltagare: ${deltagareCount}`,
                  fontSize: 8,
                  width: "auto",
                  margin: [0, 10, 0, 0],
                },
              ],
            },
            {
              text: period,
              fontSize: 8,
              margin: [0, 5, 0, 0],
            },
            {
              text: filtreringar,
              fontSize: 8,
              margin: [0, 5, 0, 5],
              lineHeight: 1.2,
            },
            {
              text: "Veckor = Antal veckor från beslutsstart till beslutsslut eller slutdatum på rapportperiod beroende på vilket som kommer först.",
              fontSize: 8,
            },
            {
              text: "Veckor* = Antal veckor som ryms i vald rapportsperiod.",
              fontSize: 8,
            },
            tableList,
          ],

          footer: function (currentPage: number, pageCount: number) {
            return [
              {
                columns: [
                  {
                    text: `den ${dagensDatum}`,
                    alignment: "left",
                    fontSize: 6,
                    margin: [40, 0, 0, 0],
                  },
                  {
                    text: `Sida ${currentPage.toString()} av ${pageCount}`,
                    alignment: "center",
                    fontSize: 6,
                  },
                  {
                    text: `Skapad av: ${anvNamn}`,
                    alignment: "right",
                    fontSize: 6,
                    margin: [0, 0, 40, 0],
                  },
                ],
              },
            ];
          },

          styles: {
            header: {
              fontSize: 18,
              bold: true,
            },
            mainTableStyle: {
              fontSize: 8,
              margin: [0, 5, 0, 15], // - a tuple of four values `[left, top, right, bottom]`
            },
            nestedTableStyle: {
              fontSize: 8,
              margin: [0, 3, 0, 3],
            },
          },
        } as TDocumentDefinitions;

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);

        pdfDocGenerator.getDataUrl((dataUrl) => {
          const doc = document.getElementById("pdf") as HTMLIFrameElement;

          if (doc) {
            doc.src = dataUrl;
          }
        });

        isGenerated = true;
      } catch (error) {
        isGenerated = false;
      }

      return isGenerated;
    },
  },
});
