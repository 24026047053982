
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import { DataTableHeader } from "vuetify/types";

import ACard from "@/components/card/ACard.vue";
import ADataTableDefault from "@/components/table/ADataTableDefault.vue";
import { KeyNumber, StatistikTableRow } from "@/models/statistik";
import { useHelpStore } from "@/store/help";
import { useStatistikStore } from "@/store/statistik";

export default defineComponent({
  name: "AntalPerErsattning",

  components: {
    ACard,
    ADataTableDefault,
  },

  props: {
    resultDone: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useStatistikStore, ["resultData"]),

    ersattningInHeaders(): DataTableHeader[] {
      return [
        {
          text: "Ersättning in",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
      ];
    },

    ersattningInItems(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerBeslutspar.ersattningIn as KeyNumber
      )) {
        array.push({
          name: key,
          antal: value,
          totalAntal: this.resultData?.uppgifterPerBeslutspar.totaltAntal ?? 0,
        });
      }

      return array;
    },

    ersattningUtHeaders(): DataTableHeader[] {
      return [
        {
          text: "Ersättning ut",
          value: "name",
          sortable: false,
        },
        {
          text: "Antal",
          value: "antal",
          sortable: false,
          align: "center",
        },
      ];
    },

    ersattningUtItems(): StatistikTableRow[] {
      const array = [] as unknown as StatistikTableRow[];

      for (const [key, value] of Object.entries(
        this.resultData?.uppgifterPerBeslutspar.ersattningUt as KeyNumber
      ).sort()) {
        array.push({
          name: key,
          antal: value,
          totalAntal: this.resultData?.uppgifterPerBeslutspar.totaltAntal ?? 0,
        });
      }
      return array;
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString"]),
  },
});
