
import { defineComponent } from "vue";

export default defineComponent({
  name: "MeddelandeDropdown",

  data: () => ({
    unreadCount: 0,
    onlyUnread: [],
  }),
});
