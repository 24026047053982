
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent } from "vue";

import DialogStepper from "@/components/dialog/DialogStepper.vue";
import { StepperItem } from "@/models/stepper";
import { useDialogStore } from "@/store/dialog";
import { useReloadStore } from "@/store/reload";
import { useSnackbarStore } from "@/store/snackbar";
import { useStepperStore } from "@/store/stepper";

import StepAktivitet from "./step/StepAktivitet.vue";
import StepIngangsvardenBeslut from "./step/StepIngangsvardenBeslut.vue";
import StepKontroll from "./step/StepKontroll.vue";
import StepPersonnummer from "./step/StepPersonnummer.vue";
import StepPersonuppgift from "./step/StepPersonuppgift.vue";

export default defineComponent({
  name: "StepperAddDeltagare",

  components: {
    DialogStepper,
    StepPersonnummer,
    StepPersonuppgift,
    StepIngangsvardenBeslut,
    StepAktivitet,
    StepKontroll,
  },

  watch: {
    dialogAddDeltagare: {
      handler(value: boolean) {
        if (value) {
          this.activeStepper = "addDeltagare";
        }

        if (!value) {
          const stepper = useStepperStore();
          stepper.$reset();

          this.activeStepper = "none";
        }
      },
    },
  },

  computed: {
    ...mapState(useStepperStore, ["saving"]),
    ...mapWritableState(useDialogStore, ["dialogAddDeltagare"]),
    ...mapWritableState(useStepperStore, [
      "serverValidationSuccess",
      "activeStepper",
    ]),
    ...mapWritableState(useSnackbarStore, [
      "genericSnackbar",
      "genericSnackbarMessage",
    ]),

    stepperItems(): StepperItem[] {
      return [
        {
          step: 1,
          title: "Personnummer",
          component: "StepPersonnummer",
        },
        {
          step: 2,
          title: "Personuppgifter",
          component: "StepPersonuppgift",
        },
        {
          step: 3,
          title: "Beslut",
          component: "StepIngangsvardenBeslut",
        },
        {
          step: 4,
          title: "Aktivitet",
          component: "StepAktivitet",
        },
        {
          step: 5,
          title: "Kontroll",
          component: "StepKontroll",
        },
      ];
    },
  },

  methods: {
    ...mapActions(useStepperStore, ["validatePersonnummer", "addDeltagare"]),

    async submit() {
      const routeParams = await this.addDeltagare();

      if (routeParams.id && routeParams.beslutsparId) {
        this.$router.push({
          name: "deltagare-beslutspar-oversikt-aktuellt-view",
          params: {
            id: routeParams.id,
            beslutsparId: routeParams.beslutsparId,
          },
        });

        // visa meddelande
        const snackbar = useSnackbarStore();
        snackbar.genericSnackbarMessage = "Deltagare skapad";
        snackbar.genericSnackbar = true;

        // close dialog
        this.dialogAddDeltagare = false;

        // reload
        const reload = useReloadStore();
        reload.toggleReload("reloadDeltagareDefaultLayout");
      }
    },
  },
});
