
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent, PropType } from "vue";

import ACard from "@/components/card/ACard.vue";
import InputDateValidationWrapper from "@/components/input/date/InputDateValidationWrapper.vue";
import InputSelectDefault from "@/components/input/select/InputSelectDefault.vue";
import InputStatus from "@/components/input/select/InputStatus.vue";
import InputTextDefault from "@/components/input/text/InputTextDefault.vue";
import { AnvandareGrupp } from "@/models/grupp";
import { StepProps } from "@/models/stepper";
import { useAnvandareStore } from "@/store/anvandare";
import { useDeltagareStore } from "@/store/deltagare";
import { useFastvalStore } from "@/store/fastval";
import { useKaaStore } from "@/store/kaa";
import { useStatusStore } from "@/store/status";
import { useStepperStore } from "@/store/stepper";

export default defineComponent({
  name: "StepIngangsvardenBeslut",

  components: {
    ACard,
    InputSelectDefault,
    InputTextDefault,
    InputDateValidationWrapper,
    InputStatus,
  },

  props: {
    stepProps: {
      type: Object as PropType<StepProps>,
      required: true,
    },
  },

  watch: {
    "stepProps.currentStep": {
      immediate: true,
      handler(value: number) {
        if (value) {
          if (value === this.stepProps.item.step) {
            this.getData();
          }
        }
      },
    },

    "beslutspar.gruppId": {
      immediate: true,
      handler(value: number) {
        if (value) {
          this.beslutspar.fvVerktygId = null;
          this.insatsModel.gruppId = value;
        }
      },
    },

    "beslutspar.fvVerktygId": {
      immediate: true,
      handler(value: number) {
        if (value) {
          if (value === -1) {
            this.getKaaData();
          }
        }

        if (!value) {
          this.beslutspar.fvIngangsvardeId = null;
          this.beslutspar.ersattningInId = null;
          this.beslutspar.fvStodnivaId = null;
          this.beslutspar.initiatorId = null;
          this.beslutspar.kaa.senasteUtbildningId = null;
          this.beslutspar.kaa.utbildningsprogramId = null;
          this.beslutspar.kaa.skola = null;

          // beslut
          this.beslut.fvBeslutId = null;
          this.beslut.fvProjektId = null;
          this.gruppFvProjektList = [];
        }
      },
    },
  },

  computed: {
    ...mapState(useKaaStore, [
      "kaaSenasteUtbildningList",
      "kaaUtbildningsprogramList",
    ]),
    ...mapState(useStepperStore, [
      "minStartdatumBeslut",
      "maxStartdatumBeslut",
      "minSlutdatumBeslut",
      "maxSlutdatumBeslut",
    ]),
    ...mapState(useAnvandareStore, [
      "anvandareGruppList",
      "gruppAnvandareList",
    ]),
    ...mapState(useFastvalStore, [
      "fvIngangsvardeList",
      "fvStodnivaList",
      "fvBestallareList",
      "gruppFvVerktygList",
      "gruppFvErsattningList",
      "gruppFvBeslutList",
    ]),
    ...mapState(useDeltagareStore, ["beslutsparList"]),
    ...mapState(useStatusStore, ["statusTypEnum"]),

    ...mapWritableState(useStepperStore, [
      "beslutspar",
      "beslut",
      "insatsModel",
    ]),
    ...mapWritableState(useFastvalStore, ["gruppFvProjektList"]),

    /**
     * När man lägger till en ny deltagare behöver inte grupper filtreras.
     * När man registrerar i ny grupp filtreras redan tagna grupper ut.
     *
     * @since version 0.8.16
     */
    dynamicAnvandareGruppList(): AnvandareGrupp[] {
      if (this.stepProps.useGruppFilter) {
        const activeList = this.beslutsparList.filter(
          (i) => i.status === this.statusTypEnum.aktiv.namn
        );

        const gruppList = this.anvandareGruppList.filter((grupp) => {
          return !activeList.some((selected) => {
            return selected.grupp.id === grupp.id;
          });
        });

        return gruppList;
      } else {
        return this.anvandareGruppList;
      }
    },
  },

  methods: {
    ...mapActions(useAnvandareStore, [
      "getAnvandareGruppList",
      "getGruppAnvandareList",
    ]),
    ...mapActions(useKaaStore, [
      "getKaaSenasteUtbildningList",
      "getKaaUtbildningsprogramList",
    ]),
    ...mapActions(useFastvalStore, [
      "getFvIngangsvardeList",
      "getFvStodnivaList",
      "getFvBestallareList",
      "getGruppFvVerktygList",
      "getGruppFvErsattningList",
      "getGruppFvBeslutList",
      "getGruppFvProjektList",
      "getKaaGruppFvBeslutList",
    ]),

    async getData() {
      await this.getAnvandareGruppList();
      await this.getFvIngangsvardeList();
      await this.getFvStodnivaList();
      await this.getFvBestallareList(true);
    },

    async getRelationData() {
      // beslutsspår
      await this.getGruppFvVerktygList(this.beslutspar.gruppId);
      await this.getGruppFvErsattningList(this.beslutspar.gruppId);

      // beslut
      await this.getGruppFvBeslutList(this.beslutspar.gruppId);
      await this.getGruppAnvandareList(this.beslutspar.gruppId);
      await this.getGruppFvProjektList(this.beslutspar.gruppId);
    },

    async getKaaData() {
      await this.getFvIngangsvardeList(this.beslutspar.fvVerktygId === -1);

      /**
       * Sätt ingångsvärdet till KAA-Kommunala aktivitetsansvaret
       *
       * @todo eventuellt gör en enum för fvIngangsvarden
       *
       * @since version 0.8.37
       */
      this.beslutspar.fvIngangsvardeId = -8;

      await this.getKaaSenasteUtbildningList();
      await this.getKaaUtbildningsprogramList();
      await this.getKaaGruppFvBeslutList();
    },
  },
});
