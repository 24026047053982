import Cookies from "js-cookie";
import { defineStore } from "pinia";

import { Atkomst, Credentials, Token, User } from "@/models/auth";
import { AnvandareGrupp } from "@/models/grupp";

import { useCrudStore } from "./crud";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    loading: false,
    saving: false,
    accessToken: Cookies.get("accorda10-rev8-token") || null,
    user: null as User | null,
    inloggadAnvandareGuppList: [] as AnvandareGrupp[],
    primarGrupp: null as AnvandareGrupp | null,
    atkomstList: [] as Atkomst[],

    /**
     * @todo kolla backend struktur för åtkomstinställningar ENUM och gör om till ex:
     * {
     *    SkyddadeUppgifterTillsyn: { id: -1, namn: "Utförare - Skyddade Uppgifter Tillsyn" }
     * }
     */
    atkomstEnum: Object.freeze({
      "Utförare - Skyddade Uppgifter Tillsyn":
        "Utförare - Skyddade Uppgifter Tillsyn",
      "Platsbank Tillsyn": "Platsbank Tillsyn",
      "Utförare - Stämpelklocka Tillsyn": "Utförare - Stämpelklocka Tillsyn",
      "Utförare - Mallar Tillsyn": "Utförare - Mallar Tillsyn",
      "Utförare - Dokument Tillsyn": "Utförare - Dokument Tillsyn",
      "Utförare - Anteckning Tillsyn": "Utförare - Anteckning Tillsyn",
      "Utförare - Beslut och insats tillsyn":
        "Utförare - Beslut och insats tillsyn",
      Administration: "Administration",
      Gallring: "Gallring",
      "Statistik - Utökad Statistik": "Statistik - Utökad Statistik",
      Statistik: "Statistik",
      Platsbank: "Platsbank",
      "Utförare - Nytt Parallellt Beslutsspår":
        "Utförare - Nytt Parallellt Beslutsspår",
      "Utförare - Se Anteckningar": "Utförare - Se Anteckningar",
      "Utförare - Se Skyddade Uppgifter": "Utförare - Se Skyddade Uppgifter",
      Utforare: "Utförare",
      Myndighet: "Myndighet",
    }),
  }),

  getters: {
    isAuth: (state) => !!state.accessToken,

    userName(state): string {
      return state.user?.namn ?? "Saknas";
    },
  },

  actions: {
    async login(credentials: Credentials): Promise<boolean> {
      let success = false;

      this.loading = true;

      try {
        success = await this.authorizeCredentials(credentials);

        if (success) {
          await this.getUser();
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }

      return success;
    },

    async authorizeCredentials(credentials: Credentials): Promise<boolean> {
      let success = false;

      try {
        const crud = useCrudStore();

        const data = (await crud.post({
          url: "Authenticate/Login",
          data: credentials,
        })) as Token;

        Cookies.set("accorda10-rev8-token", data.jwtToken, {
          secure: false,
          sameSite: "strict",
        });
        this.accessToken = data.jwtToken;

        if (this.isAuth) {
          success = true;
        }
      } catch (error) {
        success = false;
      }

      return success;
    },

    async getUser() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Authenticate/User",
        })) as User;

        this.user = data;

        await this.getAtkomstList();
      } catch (error) {
        //
      }
    },

    async getInloggadAnvandareGruppList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "InloggadAnvandare/Grupp",
        })) as AnvandareGrupp[];

        this.inloggadAnvandareGuppList = data;

        this.primarGrupp = data.find((i) => i.primar) ?? null;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getAtkomstList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "InloggadAnvandare/Atkomst",
        })) as Atkomst[];

        this.atkomstList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    hasAtkomst(namn: string): boolean {
      if (this.atkomstList.length === 0) return false;

      const atkomst = this.atkomstList.find((i) => i.namn === namn);

      return atkomst !== undefined ? atkomst.atkomst : false;
    },
  },
});
