
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "StatusAktuell",

  props: {
    aktuell: {
      type: null as unknown as PropType<boolean | null>,
      default: false,
      required: false,
    },

    status: {
      type: null as unknown as PropType<string | null>,
      default: "---",
      required: false,
    },
  },
});
