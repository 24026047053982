
import { defineComponent, PropType } from "vue";

import { DropdownIconSize, DropdownMenuItem } from "@/models/dropdown";

export default defineComponent({
  name: "DropdownMenu",

  props: {
    items: {
      type: Array as PropType<DropdownMenuItem[]>,
      default: () => [],
    },

    activator: {
      type: String,
      default: "icon",
    },

    buttonText: {
      type: String,
      default: null,
    },

    isUppercase: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: "mdi-view-list",
    },

    iconSize: {
      type: String as PropType<DropdownIconSize | null>,
      default: null,
    },

    buttonTile: {
      type: Boolean,
      default: false,
    },
  },
});
