
import { mapActions } from "pinia";
import { ValidationProvider } from "vee-validate";
import Vue, { defineComponent, PropType } from "vue";

import { useHelpStore } from "@/store/help";

export default defineComponent({
  name: "InputSelectDefault",

  components: {
    ValidationProvider,
  },

  props: {
    vid: {
      type: String,
      default: "ValidationVid",
    },

    mode: {
      type: String,
      default: "eager",
    },

    value: {
      type: null as unknown as PropType<string | number | number[] | null>,
      default: null,
      required: false,
    },

    items: {
      type: Array,
      default: () => [],
    },

    appendIcon: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    height: {
      type: Number,
      default: 304,
    },

    label: {
      type: String,
      default: "label",
    },

    hint: {
      type: String,
      default: null,
    },

    itemText: {
      type: String,
      default: "namn",
    },

    itemValue: {
      type: String,
      default: "id",
    },

    required: {
      type: Boolean,
      default: true,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
      default: null,
    },

    setFocus: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    setFocus: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          type VRefs = Vue & InstanceType<typeof HTMLInputElement>;

          if (this.$refs !== undefined) {
            setTimeout(() => {
              const input = this.$refs[this.getRefName] as VRefs;
              input.focus();
            }, 200);
          }
        }
      },
    },
  },

  computed: {
    getRefName(): string {
      return this.slugify(this.label);
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["slugify"]),
  },
});
