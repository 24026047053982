
import { defineComponent, PropType } from "vue";

import { MultiList } from "@/models/list";

export default defineComponent({
  name: "AListItemButton",

  props: {
    item: {
      type: Object as PropType<MultiList>,
      required: true,
    },

    mini: {
      type: Boolean,
      required: true,
    },

    isChild: {
      type: Boolean,
      default: false,
    },
  },
});
