import { defineStore } from "pinia";

import { LoadingName } from "@/models/loading";

export const useLoadingStore = defineStore("loading", {
  state: () => ({
    loadingDeltagareIndex: false,
  }),

  getters: {},

  actions: {
    set(name: LoadingName, value: boolean) {
      this[name] = value;
    },
  },
});
