import { defineStore } from "pinia";

export const useRedirectStore = defineStore("redirect", {
  state: () => ({
    redirectAfterUpdateStatus: false,
  }),

  actions: {
    toggleRedirect(name: string) {
      this[name as keyof typeof this.$state] =
        !this[name as keyof typeof this.$state];
    },
  },
});
