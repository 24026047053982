
import { defineComponent } from "vue";

import AAlert from "./AAlert.vue";

export default defineComponent({
  name: "AAlertLoading",

  components: {
    AAlert,
  },

  props: {
    text: {
      type: String,
      default: "Hämtar data...",
    },
  },
});
