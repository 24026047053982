
import "@/validation/rules";

import { mapState, mapWritableState } from "pinia";
import { ValidationProvider } from "vee-validate";
import { defineComponent } from "vue";

import { PostnummerOrt } from "@/models/adress";
import { useAdressStore } from "@/store/adress";

export default defineComponent({
  name: "InputOrt",

  components: {
    ValidationProvider,
  },

  props: {
    initField: {
      type: Boolean,
      default: false,
    },

    resetField: {
      type: Boolean,
      default: false,
      required: true,
    },

    appendIcon: {
      type: String,
      default: null,
    },

    hint: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    cannotAddOrt: false,
    turnOffDisabled: false,
  }),

  watch: {
    resetField: {
      handler(value) {
        if (value) {
          this.adressForm.ort = null;
        }
      },
    },

    "adressForm.postnummer": {
      immediate: true,
      handler(value) {
        if (value) {
          this.findOrt(value);
        }

        if (!value) {
          this.cannotAddOrt = false;
        }
      },
    },

    "adressForm.adressTypId": {
      immediate: true,
      handler(value: number | null) {
        if (value) {
          if (value === this.adressTypEnum.besoksadress.id) {
            this.turnOffDisabled = true;
          } else {
            this.turnOffDisabled = false;
          }
        }
      },
    },
  },

  computed: {
    ...mapState(useAdressStore, ["ortList", "postnummerList", "adressTypEnum"]),
    ...mapWritableState(useAdressStore, ["adressForm"]),

    ortLocal: {
      get(): string | null {
        return this.adressForm.ort;
      },

      set(value: PostnummerOrt | string | null) {
        if (typeof value === "string") {
          this.adressForm.ort = value;
        }

        if (typeof value === "object") {
          this.adressForm.ort = value?.namn ?? null;
        }
      },
    },
  },

  methods: {
    findOrt(value: string) {
      const postnummer = this.postnummerList.find((i) => i.namn === value);

      if (postnummer !== undefined) {
        this.adressForm.ort = postnummer.ortNamn;
        this.cannotAddOrt = true;
      } else {
        this.cannotAddOrt = false;
      }
    },
  },
});
