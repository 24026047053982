
import { defineComponent, PropType } from "vue";

import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellRawHtml",

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },
});
