
import { defineComponent, PropType } from "vue";

import AAlert from "@/components/alert/AAlert.vue";

export default defineComponent({
  name: "AListItemNoData",

  components: {
    AAlert,
  },

  props: {
    mini: {
      type: Boolean,
      required: true,
    },

    listWidth: {
      type: null as unknown as PropType<number | null | undefined>,
      default: 230,
      required: false,
    },
  },
});
