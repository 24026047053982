import Cookies from "js-cookie";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "public-layout",
    redirect: {
      name: "login-epost-losenord-view",
    },
    component: () =>
      import(
        /* webpackChunkName: "public-layout" */ "@/layouts/public/DefaultLayout.vue"
      ),
    children: [
      {
        path: "/login-epost-losenord",
        name: "login-epost-losenord-view",
        component: () => import("@/views/public/LoginEpostLosenordView.vue"),
      },
      {
        path: "/aterstall-losenord",
        name: "request-reset-password-view",
        component: () => import("@/views/public/RequestResetPasswordView.vue"),
      },
      {
        path: "/skapa-losenord",
        name: "create-new-password-view",
        component: () => import("@/views/public/CreateNewPasswordView.vue"),
      },
    ],
  },

  {
    path: "/start",
    name: "start-layout",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "start-layout" */ "@/layouts/start/DefaultLayout.vue"
      ),
    children: [
      {
        path: "statistik",
        name: "start-statistik-view",
        component: () => import("@/views/start/StartStatistikView.vue"),
      },
    ],
  },

  {
    path: "/deltagare",
    name: "deltagare-layout",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "deltagare-layout" */ "@/layouts/deltagare/DefaultLayout.vue"
      ),
    children: [
      {
        path: ":id/spar/:beslutsparId?",
        name: "deltagare-beslutspar-layout",
        component: () => import("@/layouts/deltagare/BeslutsparLayout.vue"),
        children: [
          {
            path: "oversikt",
            name: "deltagare-beslutspar-oversikt-view",
            component: () =>
              import("@/views/deltagare/BeslutsparOversiktView.vue"),
            children: [
              {
                path: "aktuellt",
                name: "deltagare-beslutspar-oversikt-aktuellt-view",
                component: () =>
                  import(
                    "@/views/deltagare/BeslutsparOversiktAktuelltView.vue"
                  ),
              },
              {
                path: "tidslinje",
                name: "deltagare-beslutspar-oversikt-tidslinje-view",
                component: () =>
                  import(
                    "@/views/deltagare/BeslutsparOversiktTidslinjeView.vue"
                  ),
              },
              {
                path: "ingangsvarden",
                name: "deltagare-beslutspar-oversikt-ingangsvarden-view",
                component: () =>
                  import(
                    "@/views/deltagare/BeslutsparOversiktIngangsvardenView.vue"
                  ),
              },
            ],
          },

          {
            path: "anteckning",
            name: "deltagare-beslutspar-anteckning-view",
            component: () =>
              import("@/views/deltagare/BeslutsparAnteckningView.vue"),
          },

          {
            path: "matchning",
            name: "deltagare-beslutspar-matchning-view",
            component: () =>
              import("@/views/deltagare/BeslutsparMatchningView.vue"),
          },

          {
            path: "handlingsplan",
            name: "deltagare-beslutspar-handlingsplan-view",
            component: () =>
              import("@/views/deltagare/BeslutsparHandlingsplanView.vue"),
            children: [
              {
                path: ":handlingsplanId?",
                name: "deltagare-beslutspar-handlingsplan-detail-view",
                component: () =>
                  import(
                    "@/views/deltagare/BeslutsparHandlingsplanDetailView.vue"
                  ),
              },
            ],
          },

          {
            path: "dokumenthanterare",
            name: "deltagare-beslutspar-dokumenthanterare-view",
            component: () =>
              import("@/views/deltagare/BeslutsparDokumenthanterareView.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "/platsbank",
    name: "platsbank-layout",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "platsbank-layout" */ "@/layouts/platsbank/DefaultLayout.vue"
      ),
    children: [
      {
        path: "arbetsstalle/:id",
        name: "arbetsstalle-detail-layout",
        component: () => import("@/layouts/platsbank/DetailLayout.vue"),
      },
    ],
  },

  {
    path: "/administration",
    name: "administration-layout",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "administration-layout" */ "@/layouts/admin/DefaultLayout.vue"
      ),
    children: [
      {
        path: ":pages*",
        name: "administration-content-layout",
        component: () => import("@/layouts/admin/ContentLayout.vue"),
        children: [
          {
            path: ":detailPage/:id",
            name: "administration-detail-layout",
            component: () => import("@/layouts/admin/DetailLayout.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = Cookies.get("accorda10-rev8-token") || null;

  if (to.matched.some((record) => record.meta.auth)) {
    if (!token) {
      next({
        name: "public-layout",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
