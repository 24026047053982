import { defineStore } from "pinia";

import { Kontaktuppgift, KontaktuppgiftTyp } from "@/models/kontaktuppgift";
import { Parent } from "@/models/parent";

import { useCrudStore } from "./crud";
import { useDialogStore } from "./dialog";
import { useReloadStore } from "./reload";
import { useSnackbarStore } from "./snackbar";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;

export const useKontaktuppgiftStore = defineStore("kontaktuppgift", {
  state: () => ({
    loading: false,
    saving: false,
    kontaktuppgiftList: [] as Kontaktuppgift[],
    kontaktuppgift: {
      id: null as number | null,
      varde: null as string | null,
      primar: false,
      kontaktuppgiftTypId: null as number | null,
      kontaktuppgiftTyp: null as KontaktuppgiftTyp | null,
    },
    kontaktuppgiftTypList: [] as KontaktuppgiftTyp[],
    isEdit: false,
  }),

  getters: {
    findKontaktuppgiftTyp:
      (state) =>
      (id: number | null): KontaktuppgiftTyp | null => {
        if (!id) return null;

        const kontaktTyp = state.kontaktuppgiftTypList.find((i) => i.id === id);

        return kontaktTyp ?? null;
      },
  },

  actions: {
    formateraTelefon(value: string | null): string {
      if (!value) return "---";

      return value.replace(
        /^([+]46)\s*(7[0236])\s*(\d{4})\s*(\d{3})$/g,
        "$1 (0) $2 $3 $4"
      );
    },

    kontaktuppgiftTypNamn(defaultReturnString = "Värde"): string {
      if (this.kontaktuppgift && this.kontaktuppgift.kontaktuppgiftTypId) {
        const vid = this.findKontaktuppgiftTyp(
          this.kontaktuppgift.kontaktuppgiftTypId
        );

        return vid ? vid.namn : defaultReturnString;
      }

      return defaultReturnString;
    },

    findPrimarKontaktuppgiftByType(
      kontaktuppgiftList: Kontaktuppgift[],
      type: string
    ): string | null {
      const primarKontaktList = kontaktuppgiftList.filter((i) => i.primar);

      if (primarKontaktList) {
        const kontakt = primarKontaktList.find(
          (i) => i.kontaktuppgiftTyp?.namn === type
        );

        if (kontakt) {
          if (kontakt.kontaktuppgiftTyp.namn === "Telefon") {
            return this.formateraTelefon(kontakt.varde);
          } else {
            const url = `mailto:${kontakt.varde}`;
            const sanerad = this.saneraUrl(url);

            return `<a href="${sanerad}">${kontakt.varde}</a>`;
          }
        }
      }

      return null;
    },

    saneraUrl(url: string): string {
      return sanitizeUrl(url);
    },

    /**
     * Sorterar kontaktuppgifter så att Epost kommer först
     *
     * @since version 0.8.0
     */
    sortKontaktuppgiftList(items: Kontaktuppgift[]): Kontaktuppgift[] {
      if (!items) return [];

      return items.sort((a, b) => {
        const nameA = a.kontaktuppgiftTyp?.namn.toUpperCase();
        const nameB = b.kontaktuppgiftTyp?.namn.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    },

    formatKontaktuppgiftList(items: Kontaktuppgift[]): Kontaktuppgift[] {
      if (!items) return [];

      const array = [];

      const kontaktuppgift = useKontaktuppgiftStore();

      for (const item of items) {
        if (item.kontaktuppgiftTyp.namn === "Telefon") {
          array.push({
            ...item,
            varde: kontaktuppgift.formateraTelefon(item.varde),
          });
        }

        if (item.kontaktuppgiftTyp.namn === "Epost") {
          const url = `mailto:${item.varde}`;
          const sanerad = this.saneraUrl(url);

          array.push({
            ...item,
            varde: `<a href="${sanerad}">${item.varde}</a>`,
          });
        }
      }

      return array;
    },

    async getKontaktuppgiftList(parent: Parent | null) {
      if (!parent) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `${parent.name}/${parent.id}/${parent.relation}`,
        })) as Kontaktuppgift[];

        this.kontaktuppgiftList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getKontaktuppgiftTypList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "KontaktuppgiftTyp",
        })) as KontaktuppgiftTyp[];

        this.kontaktuppgiftTypList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getKontaktuppgiftToEdit(parent: Parent, kontaktuppgiftId: number) {
      this.loading = true;

      this.kontaktuppgift.primar = false;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `${parent.name}/${parent.id}/${parent.relation}/${kontaktuppgiftId}`,
        })) as Kontaktuppgift;

        this.kontaktuppgift.id = data.id;
        this.kontaktuppgift.varde = data.varde;
        this.kontaktuppgift.primar = data.primar;
        this.kontaktuppgift.kontaktuppgiftTypId = data.kontaktuppgiftTyp.id;
        this.kontaktuppgift.kontaktuppgiftTyp = data.kontaktuppgiftTyp;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async addEditKontaktuppgift(parent: Parent | null) {
      if (!parent) return;

      this.saving = true;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        if (this.isEdit) {
          await crud.put({
            url: `${parent.name}/${parent.id}/${parent.relation}/${this.kontaktuppgift.id}`,
            data: this.kontaktuppgift,
          });
        }

        if (!this.isEdit) {
          await crud.post({
            url: `${parent.name}/${parent.id}/${parent.relation}`,
            data: this.kontaktuppgift,
          });
        }

        snackbar.genericSnackbarMessage = this.isEdit
          ? "Kontaktväg uppdaterad"
          : "Kontaktväg skapad";
        snackbar.genericSnackbar = true;

        dialog.dialogAddEditKontaktuppgift = false;
        this.isEdit = false;

        reload.single("reloadAfterAddEditKontaktuppgift");

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async setPrimarKontaktuppgift(parent: Parent) {
      this.saving = true;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();

        await crud.put({
          url: `${parent.name}/${parent.id}/${parent.relation}/${this.kontaktuppgift.id}`,
          data: {
            varde: this.kontaktuppgift.varde,
            primar: true,
          },
        });

        snackbar.genericSnackbarMessage = `${this.kontaktuppgift.kontaktuppgiftTyp?.namn} satt som primär kontaktväg`;
        snackbar.genericSnackbar = true;

        dialog.dialogSetPrimarKontaktuppgift = false;

        reload.single("reloadAfterSetPrimarKontaktuppgift");

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },
  },
});
