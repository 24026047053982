
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import ADataTableVertical from "@/components/table/ADataTableVertical.vue";
import { ADataTableVerticalItem } from "@/models/table";
import { useAnvandareStore } from "@/store/anvandare";
import { useFastvalStore } from "@/store/fastval";
import { useHelpStore } from "@/store/help";
import { useKaaStore } from "@/store/kaa";
import { useStepperStore } from "@/store/stepper";

export default defineComponent({
  name: "KontrollIngangsvarde",

  components: {
    ADataTableVertical,
  },

  computed: {
    ...mapState(useAnvandareStore, ["findAnvandareGruppNamn"]),
    ...mapState(useStepperStore, ["beslutspar"]),
    ...mapState(useKaaStore, [
      "findKaaSenasteUtbildningNamn",
      "findKaaUtbildningsprogramNamn",
    ]),
    ...mapState(useFastvalStore, [
      "findGruppFvVerktygNamn",
      "findFvIngangsvardeNamn",
      "findGruppFvErsattningNamn",
      "findFvStodnivaNamn",
      "findFvBestallareNamn",
    ]),

    ingangsvardenInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "Grupp",
          value: this.nullableString(
            this.findAnvandareGruppNamn(this.beslutspar.gruppId)
          ),
        },
        {
          key: "Verktyg",
          value: this.nullableString(
            this.findGruppFvVerktygNamn(this.beslutspar.fvVerktygId)
          ),
        },
        {
          key: "Ingångsvärde",
          value: this.nullableString(
            this.findFvIngangsvardeNamn(this.beslutspar.fvIngangsvardeId)
          ),
        },
        {
          key: "Ersättning in",
          value: this.nullableString(
            this.findGruppFvErsattningNamn(this.beslutspar.ersattningInId)
          ),
        },
        {
          key: "Stödnivå",
          value: this.nullableString(
            this.findFvStodnivaNamn(this.beslutspar.fvStodnivaId)
          ),
        },
        {
          key: "Initierats av",
          value: this.nullableString(
            this.findFvBestallareNamn(this.beslutspar.initiatorId)
          ),
        },
      ];
    },

    kaaInfoList(): ADataTableVerticalItem[] {
      return [
        {
          key: "Senaste utbildning",
          value: this.nullableString(
            this.findKaaSenasteUtbildningNamn(
              this.beslutspar.kaa.senasteUtbildningId
            )
          ),
        },
        {
          key: "Utbildningsprogram",
          value: this.nullableString(
            this.findKaaUtbildningsprogramNamn(
              this.beslutspar.kaa.utbildningsprogramId
            )
          ),
        },
        {
          key: "Skola",
          value: this.nullableString(this.beslutspar.kaa.skola),
        },
      ];
    },
  },

  methods: {
    ...mapActions(useHelpStore, ["nullableString"]),
  },
});
