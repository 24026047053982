
import { defineComponent, PropType } from "vue";

import { MultiList } from "@/models/list";

import AListItemButton from "./AListItemButton.vue";
import AListItemNav from "./AListItemNav.vue";

export default defineComponent({
  name: "AListItemChildren",

  components: {
    AListItemNav,
    AListItemButton,
  },

  props: {
    item: {
      type: Object as PropType<MultiList>,
      required: true,
    },

    mini: {
      type: Boolean,
      required: true,
    },

    activeColor: {
      type: String,
      default: "primary--text",
    },
  },

  data: () => ({
    active: false,
  }),
});
