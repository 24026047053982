
import { ManipulateType } from "dayjs";
import { mapActions, mapWritableState } from "pinia";
import Vue, { defineComponent, PropType } from "vue";

import { betweenMinMax } from "@/helpers/date";
import { add, format, subtract } from "@/helpers/date";
import { useDateStore } from "@/store/date";
import { useHelpStore } from "@/store/help";
import { useSnackbarStore } from "@/store/snackbar";

export default defineComponent({
  name: "InputDate",

  props: {
    value: {
      type: String as PropType<string | null | undefined>,
      default: "",
      required: false,
    },

    label: {
      type: String,
      default: "label",
    },

    hint: {
      type: String,
      default: null,
    },

    min: {
      type: String,
      default: "",
    },

    max: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default: () => [],
    },

    appendIcon: {
      type: String,
      default: "mdi-calendar",
    },

    prependIcon: {
      type: String,
      default: null,
    },

    required: {
      type: Boolean,
      default: true,
    },

    setFocus: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    menu: false,
    date: null as string | null,
  }),

  watch: {
    menu: {
      handler(value) {
        if (value) {
          if (this.value) {
            this.date = this.value;
          }
        }

        if (!value) {
          //
        }
      },
    },

    setFocus: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          type VRefs = Vue & InstanceType<typeof HTMLInputElement>;

          if (this.$refs !== undefined) {
            setTimeout(() => {
              const input = this.$refs.getRefName as VRefs;
              input.focus();
            }, 200);
          }
        }
      },
    },
  },

  computed: {
    ...mapWritableState(useSnackbarStore, [
      "errorSnackbar",
      "errorSnackbarMessage",
    ]),

    getRefName(): string {
      return this.slugify(this.label);
    },
  },

  methods: {
    ...mapActions(useDateStore, ["formatDate"]),
    ...mapActions(useHelpStore, ["slugify"]),

    selected(date: string): void {
      const isAllowed = betweenMinMax(date, this.min, this.max);

      if (isAllowed) {
        this.menu = false;
        this.$emit("input", date);
      }

      if (!isAllowed) {
        this.ogiltigtDatum();
      }
    },

    dateSelectedWithKeydownSpace() {
      if (this.date === null) {
        return;
      }
      const isAllowed = betweenMinMax(this.date, this.min, this.max);

      if (isAllowed) {
        this.menu = false;
        this.$emit("input", this.date);
        this.date = null;
      }

      if (!isAllowed) {
        this.ogiltigtDatum();
      }
    },

    dateIsAllowed(modifier: string, number: number, unit: ManipulateType) {
      let toDate = "";

      if (modifier === "add") {
        if (this.date) {
          toDate = format(add(this.date, number, unit));
        } else {
          toDate = format(
            add(new Date().toISOString().substring(0, 10), number, unit)
          );
        }
      }

      if (modifier === "subtract") {
        if (this.date) {
          toDate = format(subtract(this.date, number, unit));
        }
      }

      if (betweenMinMax(toDate, this.min, this.max)) {
        return true;
      } else if (!betweenMinMax(toDate, this.min, this.max)) {
        this.ogiltigtDatum(toDate);
        return false;
      }
    },

    up() {
      if (this.menu) {
        if (this.dateIsAllowed("subtract", 1, "week")) {
          this.pickModified("subtract", 1, "week");
        }
      }
    },

    down() {
      if (this.menu) {
        if (this.dateIsAllowed("add", 1, "week")) {
          this.pickModified("add", 1, "week");
        }
      }
    },

    left(e: KeyboardEvent) {
      if (this.menu) {
        if (e.shiftKey) {
          if (this.dateIsAllowed("subtract", 1, "month")) {
            this.pickModified("subtract", 1, "month");
          }
        }

        if (!e.shiftKey) {
          if (this.dateIsAllowed("subtract", 1, "day")) {
            this.pickModified("subtract", 1, "day");
          }
        }
      }
    },

    right(e: KeyboardEvent) {
      if (this.menu) {
        if (e.shiftKey) {
          if (this.dateIsAllowed("add", 1, "month")) {
            this.pickModified("add", 1, "month");
          }
        }

        if (!e.shiftKey) {
          if (this.dateIsAllowed("add", 1, "day")) {
            this.pickModified("add", 1, "day");
          }
        }
      }
    },

    pickModified(modifier: string, number: number, unit: ManipulateType) {
      if (this.$refs.getRefName && this.$refs.datePicker) {
        let current = "";

        if (this.date === null) {
          current = new Date().toISOString().substring(0, 10);
        } else {
          current = (this.$refs.datePicker as Vue & { inputDate: string })
            .inputDate;
        }

        if (modifier === "add") {
          const addDate = format(add(current, number, unit));
          (
            this.$refs.datePicker as Vue & {
              dateClick: (addDate: string) => boolean;
            }
          ).dateClick(addDate);
        }

        if (modifier === "subtract") {
          const subtractDate = format(subtract(current, number, unit));
          (
            this.$refs.datePicker as Vue & {
              dateClick: (subtractDate: string) => boolean;
            }
          ).dateClick(subtractDate);
        }
      }
    },

    ogiltigtDatum(toDate?: string): void {
      this.errorSnackbarMessage =
        toDate !== undefined
          ? `${toDate} är utanför tillåten gräns`
          : "Ogiltigt datum";
      this.errorSnackbar = true;
    },
  },
});
