
import { defineComponent, PropType } from "vue";

import ACard from "@/components/card/ACard.vue";

export default defineComponent({
  name: "DialogDefault",

  components: {
    ACard,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    toolbarTitle: {
      type: null as unknown as PropType<string | null | undefined>,
      default: "toolbarTitle",
    },

    minusDefaultHeight: {
      type: Number,
      default: 300,
    },

    minusFullscreenHeight: {
      type: Number,
      default: 70,
    },

    openAsFullscreen: {
      type: Boolean,
      default: false,
    },

    contentClass: {
      type: String,
      default: "pa-1",
    },

    dialogWidth: {
      type: null as unknown as PropType<string | number>,
      default: null,
    },
  },

  data: () => ({
    fullscreen: false,
  }),

  watch: {
    value: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          if (this.openAsFullscreen) {
            this.fullscreen = true;
          }
        }
      },
    },
  },

  computed: {
    defaultHeight(): number {
      return this.$vuetify.breakpoint.height - this.minusDefaultHeight;
    },

    fullscreenHeight(): number {
      return this.$vuetify.breakpoint.height - this.minusFullscreenHeight;
    },
  },

  methods: {
    toggleFullscreen(): void {
      this.fullscreen = !this.fullscreen;
      this.$emit("fullscreen", this.fullscreen);
    },
  },
});
