
import { mapActions } from "pinia";
import { defineComponent, PropType } from "vue";

import { StatusTypObject } from "@/models/status";
import { useStatusStore } from "@/store/status";

export default defineComponent({
  name: "StatusTextAndIcon",

  props: {
    /**
     * iconSet
     */
    iconSet: {
      type: String as PropType<string>,
      default: "period",
      required: true,
      validator: (value: string) =>
        ["period", "arbetsstalle", "anvandare"].includes(value),
    },

    status: {
      type: String as PropType<string | null | undefined>,
    },
  },

  computed: {
    statusObject(): StatusTypObject | null {
      if (!this.status) return null;

      return this.setStatus(this.iconSet, this.status);
    },
  },

  methods: {
    ...mapActions(useStatusStore, ["setStatus"]),
  },
});
