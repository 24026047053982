
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent, PropType } from "vue";

import AAlert from "@/components/alert/AAlert.vue";
import DialogPrompt from "@/components/dialog/DialogPrompt.vue";
import InputDateValidationWrapper from "@/components/input/date/InputDateValidationWrapper.vue";
import InputNumberDefault from "@/components/input/number/InputNumberDefault.vue";
import InputSelectDefault from "@/components/input/select/InputSelectDefault.vue";
import InputStatus from "@/components/input/select/InputStatus.vue";
import { StepProps } from "@/models/stepper";
import { useAnvandareStore } from "@/store/anvandare";
import { useDateStore } from "@/store/date";
import { useDeltagareStore } from "@/store/deltagare";
import { useFastvalStore } from "@/store/fastval";
import { useHelpStore } from "@/store/help";
import { useInsatsStore } from "@/store/insats";
import { useSnackbarStore } from "@/store/snackbar";
import { useStepperStore } from "@/store/stepper";

export default defineComponent({
  name: "FormNyAktivitet",

  components: {
    AAlert,
    DialogPrompt,
    InputDateValidationWrapper,
    InputNumberDefault,
    InputSelectDefault,
    InputStatus,
  },

  props: {
    stepProps: {
      type: Object as PropType<StepProps>,
      required: true,
    },
  },

  data: () => ({
    dialogPrompt: false,
  }),

  watch: {
    "stepProps.dialog": {
      handler(value: boolean) {
        if (!value) {
          this.dialogPrompt = false;
        }
      },
    },

    "stepProps.currentStep": {
      immediate: true,
      handler(value: number) {
        if (value) {
          /**
           * Om beslutet är kaa ska andra aktiviteter hämtas
           */
          if (this.isBytBeslutKaa && value === this.stepProps.item.step) {
            this.getData();
          }

          if (
            value === this.stepProps.item.step &&
            !this.insatsModel.isInternResurs
          ) {
            this.dialogPrompt =
              this.isAktivitetHandlaggareSameAsBeslutHandlaggare(
                this.insatsModel.handlaggareId,
                this.beslut.handlaggareId
              );
          }
        }
      },
    },

    "beslutspar.gruppId": {
      immediate: true,
      handler(value: number) {
        if (value) {
          this.getGruppFvAktivitetList(value);
        }
      },
    },

    "beslut.fvProjektId": {
      immediate: true,
      handler(value: number) {
        if (value) {
          this.insatsModel.fvProjektId = value;
        }
      },
    },

    "beslut.startdatum": {
      immediate: true,
      handler(value: string) {
        if (value) {
          this.insatsModel.startdatum = value;
        }
      },
    },

    "beslut.fvBeslutId": {
      immediate: true,
      handler(value: number) {
        if (value) {
          if (this.beslutspar.fvVerktygId === -1 && this.stepProps.dialog) {
            this.getData();
          }
        }
      },
    },

    "insatsModel.handlaggareId": {
      immediate: true,
      handler(value: number) {
        if (value) {
          if (this.stepProps.currentStep === this.stepProps.item.step) {
            this.dialogPrompt =
              this.isAktivitetHandlaggareSameAsBeslutHandlaggare(
                value,
                this.beslut.handlaggareId
              );
          }
        }
      },
    },

    isNyAktivitet: {
      immediate: true,
      handler(value) {
        if (value) {
          this.insatsId = [];
          this.resetStepForm = { value: false, step: null };
        }

        if (!value) {
          this.resetFormNyAktivitet();

          this.resetStepForm = {
            value: true,
            step: this.stepProps.currentStep,
          };
        }
      },
    },
  },

  computed: {
    ...mapState(useFastvalStore, ["fvInsatsList", "gruppFvProjektList"]),
    ...mapState(useAnvandareStore, ["gruppAnvandareList"]),
    ...mapState(useStepperStore, [
      "beslutspar",
      "beslut",
      "minStartdatumAktivitet",
      "maxStartdatumAktivitet",
      "minSlutdatumAktivitet",
      "maxSlutdatumAktivitet",
      "isNyAktivitet",
      "isBytBeslutKaa",
      "internResursId",
    ]),
    ...mapWritableState(useStepperStore, [
      "insatsModel",
      "resetStepForm",
      "insatsId",
    ]),
    ...mapState(useDeltagareStore, ["internResursList"]),
  },

  methods: {
    ...mapActions(useDateStore, ["formatDate"]),
    ...mapActions(useHelpStore, ["nullableString"]),
    ...mapActions(useFastvalStore, [
      "getKaaiOrKaauFvInsatsList",
      "getGruppFvAktivitetList",
    ]),
    ...mapActions(useAnvandareStore, ["findGruppAnvandareNamn"]),
    ...mapActions(useInsatsStore, [
      "isAktivitetHandlaggareSameAsBeslutHandlaggare",
    ]),

    async getData() {
      await this.getKaaiOrKaauFvInsatsList(this.beslut.fvBeslutId);
    },

    cancel() {
      this.insatsModel.isInternResurs = false;
      this.dialogPrompt = false;
      this.insatsModel.handlaggareId = this.beslut.handlaggareId;
    },

    confirmed() {
      this.insatsModel.isInternResurs = true;
      this.dialogPrompt = false;

      const snackbar = useSnackbarStore();
      snackbar.genericSnackbarMessage =
        "Resurs kommer läggas till som intern resurs när aktiviteten sparas";
      snackbar.genericSnackbar = true;
    },

    resetFormNyAktivitet() {
      this.insatsModel.fvInsatsId = null;
      this.insatsModel.startdatum = this.beslut.startdatum;
      this.insatsModel.slutdatum = null;
      this.insatsModel.omfattning = 0;
      this.insatsModel.fvProjektId = null;
      this.insatsModel.handlaggareId = this.beslut.handlaggareId;
      this.insatsModel.gruppId = this.beslutspar.gruppId;
    },
  },
});
