
import { defineComponent, PropType } from "vue";

import { MultiList } from "@/models/list";

export default defineComponent({
  name: "AListItemAction",

  props: {
    item: {
      type: Object as PropType<MultiList>,
      required: true,
    },

    mini: {
      type: Boolean,
      required: true,
    },
  },
});
