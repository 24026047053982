
import { mapActions, mapWritableState } from "pinia";
import { defineComponent } from "vue";

import DialogPrompt from "@/components/dialog/DialogPrompt.vue";
import { useDialogStore } from "@/store/dialog";
import { useStatusStore } from "@/store/status";

export default defineComponent({
  name: "DialogUpdateStatus",

  components: {
    DialogPrompt,
  },

  computed: {
    ...mapWritableState(useDialogStore, ["dialogUpdateStatus"]),
  },

  methods: {
    ...mapActions(useStatusStore, ["updateStatus"]),

    cancel() {
      this.dialogUpdateStatus = false;
    },

    async confirmed() {
      await this.updateStatus();
    },
  },
});
