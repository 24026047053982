
import {
  ArcElement,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Colors,
  Legend,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { defineComponent, PropType } from "vue";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

export default defineComponent({
  name: "APieChart",

  components: {
    Pie,
  },

  props: {
    chartData: {
      type: null as unknown as PropType<ChartData<"pie">>,
      required: true,
    },

    chartOptions: {
      type: null as unknown as PropType<ChartOptions>,
    },

    chartTitle: {
      type: null as unknown as PropType<string | null>,
    },
  },

  computed: {
    defaultChartOptions(): ChartOptions {
      if (!this.chartOptions) {
        return {
          plugins: {
            title: {
              display: true,
              text: this.chartTitle ? this.chartTitle : "---",
            },
            tooltip: {
              callbacks: {
                label: function (context: TooltipItem<"pie">) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed !== null) {
                    label += `${context.parsed} %`;
                  }
                  return label;
                },
              },
            },
          },
        };
      }

      return this.chartOptions;
    },
  },
});
