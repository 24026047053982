import { defineStore } from "pinia";

import { FilterData } from "@/models/filterListgeneratorStatistik";
import { StatistikResultat, StatistikTableRow } from "@/models/statistik";

import { useCrudStore } from "./crud";
import { useFilterListgeneratorStatistikStore } from "./filterListgeneratorStatistik";

export const useStatistikStore = defineStore("statistik", {
  state: () => ({
    loadingResult: false,
    resultData: null as StatistikResultat | null,
  }),

  getters: {},

  actions: {
    async getResultData(filterData: FilterData) {
      this.loadingResult = true;

      try {
        const crud = useCrudStore();
        const filterListgeneratorStatistik =
          useFilterListgeneratorStatistikStore();

        const data = (await crud.post({
          url: "Statistik",
          data: {
            filtreringsalternativ: filterData,
            inskrivningstyp: filterListgeneratorStatistik.inskrivningstypForm,
            startdatum: filterListgeneratorStatistik.filterForm.startdatum,
            slutdatum: filterListgeneratorStatistik.filterForm.slutdatum,
            kaa: filterListgeneratorStatistik.filterForm.kaa,
          },
        })) as StatistikResultat;

        console.log(data);

        this.resultData = data;

        this.loadingResult = false;
      } catch (error) {
        this.loadingResult = false;
      }
    },

    alderKeyName(key: string): string {
      switch (key) {
        case "Under16":
          return "Under 16 år";
        case "16_19":
          return "16 - 19 år";
        case "20_24":
          return "20 - 24 år";
        case "25_65":
          return "25 - 65 år";
        case "Over65":
          return "Över 65 år";
        default:
          return "---";
      }
    },

    konKeyName(key: string): string {
      switch (key) {
        case "Man":
          return "Man";
        case "Kvinna":
          return "Kvinna";
        case "Okant":
          return "Okänt";
        default:
          return "---";
      }
    },

    addTotalProcentToAllItems(
      array: StatistikTableRow[],
      totalProcent: number
    ) {
      for (const item of array) {
        item.totalProcent = `${totalProcent} %`;
      }
    },
  },
});
