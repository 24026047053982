
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent } from "vue";

import AAlert from "@/components/alert/AAlert.vue";
import DialogDefault from "@/components/dialog/DialogDefault.vue";
import { Uppdrag } from "@/models/uppdrag";
import { useAdressStore } from "@/store/adress";
import { useDateStore } from "@/store/date";
import { useDialogStore } from "@/store/dialog";
import { useStatusStore } from "@/store/status";
import { useStepperStore } from "@/store/stepper";
import { useUppdragStore } from "@/store/uppdrag";

export default defineComponent({
  name: "DialogUppdrag",

  components: {
    AAlert,
    DialogDefault,
  },

  mounted() {
    /**
     * Hämta data direkt på en refresh
     */
    this.getDataWithInterval();

    /**
     * starta intervall för att hämta uppdrag,
     * @todo detta ska ändras till SignalR
     *
     * @since version 0.8.42
     */
    setInterval(() => {
      this.getDataWithInterval();
    }, 10000);

    this.getUppdragStatusList();
  },

  data: () => ({
    dialog: false,
    expanded: [],
    singleExpand: true,
    headers: [
      {
        text: "Personnummer",
        sortable: false,
        value: "uppdragPerson.personnummer",
        width: 80,
      },
      {
        text: "Namn",
        sortable: false,
        value: "uppdragPerson",
        width: 200,
      },
      {
        text: "Beslut",
        sortable: false,
        value: "",
      },
      {
        text: "Beslutsperiod",
        sortable: false,
        value: "beslutPeriod",
      },
      {
        text: "Inkom",
        sortable: true,
        value: "createDate",
        width: 170,
      },
      {
        text: "Handläggare",
        sortable: false,
        value: "handlaggareNamn",
      },
      {
        text: "Status",
        sortable: true,
        value: "uppdragStatus.namn",
        width: 200,
      },
      {
        text: "",
        value: "data-table-expand",
        width: 50,
        align: "center",
      },
    ],
  }),

  computed: {
    ...mapWritableState(useDialogStore, ["dialogUppdrag"]),

    ...mapWritableState(useStepperStore, ["deltagare"]),

    ...mapState(useAdressStore, ["adressTypEnum"]),
    ...mapWritableState(useAdressStore, ["adressForm"]),

    ...mapState(useUppdragStore, ["uppdragList"]),
    ...mapWritableState(useUppdragStore, ["selectedUppdragId", "isUppdrag"]),
  },

  methods: {
    ...mapActions(useUppdragStore, [
      "getUppdragList",
      "getUppdragStatusList",
      "uppdragStatusColor",
      "uppdragCount",
    ]),
    ...mapActions(useDateStore, ["formatDate"]),
    ...mapActions(useDialogStore, ["open"]),
    ...mapActions(useAdressStore, ["formateraPostnr"]),

    async getDataWithInterval() {
      await this.getUppdragList();
    },

    async itemExpanded(event: { item: Uppdrag; value: boolean }) {
      this.selectedUppdragId = event.item.id;

      // om tabell rad är expanderat och status inkommen
      if (event.value && event.item.uppdragStatus.namn === "Inkommen") {
        const status = useStatusStore();
        const uppdrag = useUppdragStore();

        status.statusToChange.name = "Uppdrag";
        status.statusToChange.id = uppdrag.selectedUppdragId;
        status.statusToChange.status = "visad";

        await status.updateStatus();
        await uppdrag.sendStatus(uppdrag.selectedUppdragId);
        await this.getUppdragList();
      }
    },

    accepteraUppdrag(item: Uppdrag) {
      // sätt data för lägg till deltagare
      this.deltagare.personnummer = item.uppdragPerson.personnummer;
      this.deltagare.fornamn = item.uppdragPerson.fornamn;
      this.deltagare.efternamn = item.uppdragPerson.efternamn;
      this.deltagare.epost = item.uppdragPerson.epost;
      this.deltagare.telefon = item.uppdragPerson.mobil; // item.uppdragPerson.telefon; // hemtelefon i omega, finns också mobil men vi har bara telefon

      // sätt deltagaren adress
      if (item.uppdragPerson.adress) {
        this.adressForm.adressTypId = this.adressTypEnum.personadress.id;
        this.adressForm.co = item.uppdragPerson.adress?.co;
        this.adressForm.gatuadress = item.uppdragPerson.adress.gatuadress;
        this.adressForm.displayPostnr = this.formateraPostnr(
          item.uppdragPerson.adress.postnummer
        );
        this.adressForm.postnummer =
          item.uppdragPerson.adress.postnummer.replace(/\s/g, "");
        this.adressForm.ort = item.uppdragPerson.adress.ort;
      }

      // öppna lägg till deltagare
      this.selectedUppdragId = item.id;
      this.isUppdrag = true;

      this.dialogUppdrag = false;
      this.open("dialogAddDeltagare");
    },
  },
});
