
import { defineComponent, PropType } from "vue";

type Alert = "success" | "info" | "warning" | "error";

export default defineComponent({
  name: "AAlert",

  props: {
    type: {
      type: String as PropType<Alert>,
      default: "info",
      required: false,
      validator: (value: string) =>
        ["success", "info", "warning", "error"].includes(value),
    },

    dense: {
      type: Boolean,
      default: true,
    },

    dismissible: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: "alert-circle",
    },

    alertClass: {
      type: String,
      default: null,
    },

    elevation: {
      type: null as unknown as PropType<string | null>,
    },

    transition: {
      type: null as unknown as PropType<string | null>,
    },

    color: {
      type: null as unknown as PropType<string | null>,
    },
  },
});
