import { defineStore } from "pinia";

import { BaseObject } from "@/models/base";
import {
  FastvalTypNamn,
  FvBeslut,
  FvBestallare,
  FvBestallareTyp,
  FvDUA,
  FvErsattning,
  FvIceRoll,
  FvIngangsvarde,
  FvInsats,
  FvProjekt,
  FvResultat,
  FvResultatTyp,
  FvStodniva,
  FvVerktyg,
} from "@/models/fastval";
import { MultiList } from "@/models/list";

import { useCrudStore } from "./crud";
import { useDeltagareStore } from "./deltagare";
import { useDialogStore } from "./dialog";
import { useFormStore } from "./form";
import { useReloadStore } from "./reload";
import { useSavingStore } from "./saving";
import { useSnackbarStore } from "./snackbar";
import { useStepperStore } from "./stepper";

export const useFastvalStore = defineStore("fastval", {
  state: () => ({
    loading: false,

    fastvalList: [
      "fvbestallare",
      "fvbeslut",
      "fvaktivitet",
      "fvhandelse",
      "fvprojekt",
      "fversattning",
      "fvresultatskr",
      "fvresultatesf",
      "fvstodniva",
      "fvverktyg",
    ],

    fvbestallare: {
      title: "Beställare",
      icon: "mdi-account-tie",
    },

    fvbeslut: {
      title: "Beslut",
      icon: "mdi-file-document-edit",
    },

    fvaktivitet: {
      title: "Aktivitet",
      icon: "mdi-calendar-expand-horizontal",
    },

    fvhandelse: {
      title: "Händelser",
      icon: "mdi-calendar-alert",
    },

    fvprojekt: {
      title: "Projektgrupper",
      icon: "mdi-account-group",
    },

    fversattning: {
      title: "Ersättningar",
      icon: "mdi-cash-multiple",
    },

    fvresultatskr: {
      title: "Resultat SKR",
      icon: "mdi-account-arrow-right",
    },

    fvresultatesf: {
      title: "Resultat ESF",
      icon: "mdi-account-arrow-right",
    },

    fvstodniva: {
      title: "Stödnivåer",
      icon: "mdi-lifebuoy",
    },

    fvverktyg: {
      title: "Verktyg",
      icon: "mdi-tools",
    },

    fvBestallareTypList: [] as FvBestallareTyp[],
    fvBeslutTypList: [] as BaseObject[],
    fvBestallareList: [] as FvBestallare[],
    fvDuaList: [] as FvDUA[],
    fvSocialstyrelseKlassningList: [] as BaseObject[],
    fvResultatTypSkrList: [] as FvResultatTyp[],
    fvResultatTypEsfList: [] as FvResultatTyp[],
    fvIngangsvardeList: [] as FvIngangsvarde[],
    fvStodnivaList: [] as FvStodniva[],
    fvInsatsList: [] as FvInsats[],
    fvBeslutList: [] as FvBeslut[],
    fvIceRollList: [] as FvIceRoll[],

    gruppFvVerktygList: [] as FvVerktyg[],
    gruppFvErsattningList: [] as FvErsattning[],
    gruppFvBeslutList: [] as FvBeslut[],
    gruppFvProjektList: [] as FvProjekt[],
    gruppFvResultatList: [] as FvResultat[],

    // för visning i select rbb i fvBeslut
    fvResultatSkrList: [] as FvResultat[],

    fvBestallareForm: {
      id: null as number | null,
      fvBestallareTypId: null as number | null,
      namn: null as string | null,
    },
    isEditFvBestallare: false,

    fvBeslutForm: {
      id: null as number | null,
      fvResultatId: null as number | null,
      fvBestallareId: null as number | null,
      forkortning: null as string | null,
      namn: null as string | null,
      overgripandeStatistik: false,
      autoinsats: false,
      skl1: false,
      sklFp: false,
      sklKoa: false,
      rbb: false,
      lon: false,
      avdrag: false,
      fvBeslutTypId: null as number | null,
    },
    isEditFvBeslut: false,

    fvInsatsForm: {
      id: null as number | null,
      fvDUAId: null as number | null,
      fvSocialstyrelseKlassningId: null as number | null,
      namn: null as string | null,
      forkortning: null as string | null,
      anteckningHandelse: false,
      insatsHandelse: false,
    },
    isEditFvAktivitet: false,
    isEditFvHandelse: false,

    fvProjektForm: {
      id: null as number | null,
      namn: null as string | null,
    },
    isEditFvProjekt: false,

    fvErsattningForm: {
      id: null as number | null,
      namn: null as string | null,
    },
    isEditFvErsattning: false,

    fvResultatSkrForm: {
      id: null as number | null,
      fvResultatTypId: null as number | null,
      namn: null as string | null,
    },
    isEditFvResultatSkr: false,

    fvResultatEsfForm: {
      id: null as number | null,
      fvResultatTypId: null as number | null,
      namn: null as string | null,
    },
    isEditFvResultatEsf: false,

    fvStodnivaForm: {
      id: null as number | null,
      beskrivning: null as string | null,
      namn: null as string | null,
    },
    isEditFvStodniva: false,
  }),

  getters: {
    findGruppFvVerktygNamn:
      (state) =>
      (fvVerktygId: number | null): string | null => {
        if (!fvVerktygId) return null;

        const verktyg = state.gruppFvVerktygList.find(
          (i) => i.id === fvVerktygId
        );

        return verktyg?.namn ?? null;
      },

    findFvIngangsvardeNamn:
      (state) =>
      (fvIngangsvardeId: number | null): string | null => {
        if (!fvIngangsvardeId) return null;

        const ingangsvarde = state.fvIngangsvardeList.find(
          (i) => i.id === fvIngangsvardeId
        );

        return ingangsvarde?.namn ?? null;
      },

    findGruppFvErsattningNamn:
      (state) =>
      (fvErsattningId: number | null): string | null => {
        if (!fvErsattningId) return null;

        const ersattning = state.gruppFvErsattningList.find(
          (i) => i.id === fvErsattningId
        );

        return ersattning?.namn ?? null;
      },

    findFvStodnivaNamn:
      (state) =>
      (fvStodnivaId: number | null): string | null => {
        if (!fvStodnivaId) return null;

        const stodniva = state.fvStodnivaList.find(
          (i) => i.id === fvStodnivaId
        );

        return stodniva?.namn ?? null;
      },

    findFvBestallareNamn:
      (state) =>
      (initiatorId: number | null): string | null => {
        if (!initiatorId) return null;

        const bestallare = state.fvBestallareList.find(
          (i) => i.id === initiatorId
        );

        return bestallare?.namn ?? null;
      },

    findGruppFvBeslutNamn:
      (state) =>
      (fvBeslutId: number | null): string | null => {
        if (!fvBeslutId) return null;

        const beslut = state.gruppFvBeslutList.find((i) => i.id === fvBeslutId);

        return beslut?.namn ?? null;
      },

    findFvInsatsNamn:
      (state) =>
      (fvInsatsId: number | null): string | null => {
        if (!fvInsatsId) return null;

        const insats = state.fvInsatsList.find((i) => i.id === fvInsatsId);

        return insats?.namn ?? null;
      },
  },

  actions: {
    findFvProjektNamn(fvProjektId: number | null): string | null {
      if (!fvProjektId) return null;

      const projekt = this.gruppFvProjektList.find((i) => i.id === fvProjektId);

      return projekt?.namn ?? null;
    },

    getFastvalNavList(): MultiList[] {
      const array = [] as MultiList[];

      for (const fastval of this.fastvalList) {
        const object = this.$state[fastval as keyof typeof this.$state] as {
          title: string;
          icon: string;
        };

        array.push({
          type: "nav",
          title: object.title,
          icon: {
            name: object.icon,
            size: null,
            color: null,
          },
          disabled: false,
          route: {
            name: "administration-content-layout",
            params: {
              pages: ["fastaval", fastval],
            },
          },
          children: [],
        });
      }

      return array;
    },

    async getFvBestallareTypList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "FvBestallareTyp",
        })) as FvBestallareTyp[];

        this.fvBestallareTypList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFvBeslutTypList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "FvBeslutTyp",
        })) as BaseObject[];

        this.fvBeslutTypList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFvBestallareList(
      init = false,
      ejaktuella = false,
      sortBy = "namn",
      sortOrder = "asc"
    ) {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "FvBestallare",
          params: {
            init: init,
            ejaktuella: ejaktuella,
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        })) as FvBestallare[];

        this.fvBestallareList = data;
      } catch (error) {
        //
      }
    },

    async getFvDuaList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "dua",
        })) as FvDUA[];

        this.fvDuaList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFvSocialstyrelseKlassningList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "fvSocialstyrelseklassning",
        })) as BaseObject[];

        this.fvSocialstyrelseKlassningList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFvResultatTypSkrList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "FvResultatTypSkr",
        })) as FvResultatTyp[];

        this.fvResultatTypSkrList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFvResultatTypEsfList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "FvResultatTypEsf",
        })) as FvResultatTyp[];

        this.fvResultatTypEsfList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * Används för att hämta olika ingångsvärden för ny deltagare
     * och om verktyget KAA används hämtas det från annan endpoint
     *
     * @since version 0.8.37
     */
    async getFvIngangsvardeList(isKaa = false) {
      this.loading = true;

      let url = "FvIngangsvarde";

      if (isKaa) {
        url = "FvIngangsvarde/KAA";
      }

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: url,
        })) as FvIngangsvarde[];

        this.fvIngangsvardeList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFvStodnivaList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "FvStodniva",
        })) as FvStodniva[];

        this.fvStodnivaList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getFvIceRollList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "KartlaggningKontakt/Roller",
        })) as FvIceRoll[];

        this.fvIceRollList = data;
      } catch (error) {
        //
      }
    },

    /**
     * Hämtar lista för att kunna välja rbb (resultat beslut byte) i fvBeslut
     *
     * @since version 0.8.31
     */
    async getFvResultatSkrList() {
      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "FvResultatSKR",
          params: {
            ejaktuella: false,
          },
        })) as FvResultat[];

        this.fvResultatSkrList = data;
      } catch (error) {
        //
      }
    },

    /*******************************************************************
     *** FASTVAL MED GRUPP KOPPLING
     *******************************************************************
     */

    async getGruppFvErsattningList(gruppId: number | null | undefined) {
      if (!gruppId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Grupp/${gruppId}/FvErsattning`,
        })) as FvErsattning[];

        this.gruppFvErsattningList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getGruppFvVerktygList(gruppId: number | null | undefined) {
      if (!gruppId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Grupp/${gruppId}/FvVerktyg`,
        })) as FvVerktyg[];

        this.gruppFvVerktygList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getGruppFvBeslutList(
      gruppId: number | null | undefined,
      beslutTyp = "huvudbeslut",
      ejaktuella = false
    ) {
      this.gruppFvBeslutList = [];

      if (!gruppId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Grupp/${gruppId}/FvBeslut`,
          params: {
            ejaktuella: ejaktuella,
            typ: beslutTyp,
          },
        })) as FvBeslut[];

        this.gruppFvBeslutList = data.map((item) => ({
          ...item,
          namn: `${item.namn} / ${item.fvBestallare?.namn}`,
        }));
      } catch (error) {
        //
      }
    },

    async getGruppFvProjektList(gruppId: number | null | undefined) {
      if (!gruppId) return;

      this.loading = true;

      this.gruppFvProjektList = [];

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Grupp/${gruppId}/FvProjekt`,
          params: {
            ejaktuella: false,
          },
        })) as FvProjekt[];

        this.gruppFvProjektList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getGruppFvAktivitetList(gruppId: number | null | undefined) {
      if (!gruppId) return;

      this.fvInsatsList = [];

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Grupp/${gruppId}/FvAktivitet`,
          params: {
            ejaktuella: false,
          },
        })) as FvInsats[];

        this.fvInsatsList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getGruppFvResultatList(gruppId: number | null | undefined) {
      if (!gruppId) return;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Grupp/${gruppId}/FvResultat`,
        })) as FvResultat[];

        this.gruppFvResultatList = data;
      } catch (error) {
        //
      }
    },

    /*******************************************************************
     *** KAA
     *******************************************************************
     */

    async getKaaGruppFvBeslutList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "Kaa/FvBeslut",
        })) as FvBeslut[];

        this.gruppFvBeslutList = data.map((item) => ({
          ...item,
          namn: `${item.namn} / ${item.fvBestallare?.namn}`,
        }));

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getKaaiOrKaauFvInsatsList(fvBeslutId: number | null | undefined) {
      if (!fvBeslutId) return;

      const deltagare = useDeltagareStore();
      let endpoint = "";

      if (fvBeslutId === deltagare.kaa.u.id) {
        endpoint = deltagare.kaa.u.namn;
      }

      if (fvBeslutId === deltagare.kaa.i.id) {
        endpoint = deltagare.kaa.i.namn;
      }

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `Kaa/Aktivitet/${endpoint}`,
          params: {
            sortBy: "namn",
            sortOrder: "acs",
          },
        })) as FvInsats[];

        this.fvInsatsList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getKaaiOrKaauFvBeslutList() {
      this.loading = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();
        const stepper = useStepperStore();

        const data = (await crud.get({
          url: "Kaa/FvBeslut",
        })) as FvBeslut[];

        const items = data.map((item) => ({
          ...item,
          namn: `${item.namn} / ${item.fvBestallare?.namn}`,
        }));

        /**
         * Kontrollera vilken KAA som ska bytas till och sätt värden enligt det.
         *
         * @since version 0.5.7
         */
        if (deltagare.isKAAI) {
          this.gruppFvBeslutList = items.filter(
            (i) => i.id !== deltagare.kaa.i.id
          );
          stepper.beslut.fvBeslutId = deltagare.kaa.u.id;
        }

        if (deltagare.isKAAU) {
          this.gruppFvBeslutList = items.filter(
            (i) => i.id !== deltagare.kaa.u.id
          );
          stepper.beslut.fvBeslutId = deltagare.kaa.i.id;
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /*******************************************************************
     *** Administration fastval
     *******************************************************************
     */

    async getFastvalToEdit(
      fastvalNamn: FastvalTypNamn,
      fastvalId: number | null | undefined
    ) {
      if (!fastvalId) return;

      let data = null as unknown | null;

      try {
        const crud = useCrudStore();

        data = await crud.get({
          url: `${fastvalNamn}/${fastvalId}`,
        });
      } catch (error) {
        //
      }

      return data;
    },

    async addEditFastval(
      fastvalNamn: FastvalTypNamn,
      formData: { id: number | null; [key: string]: unknown },
      isEdit = false,
      message: string
    ) {
      let data = null as unknown as { id: number } | null;

      const saving = useSavingStore();
      saving.set("savingFastval", true);

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();
        const form = useFormStore();

        if (isEdit && formData) {
          await crud.put({
            url: `${fastvalNamn}/${formData.id}`,
            data: formData,
          });
        }

        if (!isEdit) {
          data = (await crud.post({
            url: `${fastvalNamn}`,
            data: formData,
          })) as { id: number };
        }

        snackbar.genericSnackbarMessage = message;
        snackbar.genericSnackbar = true;

        if (isEdit) {
          reload.toggleReload("reloadFastvalDetailView");
          reload.toggleReload("reloadAdministrationContentLayout");

          form.isDirty = false;
        }

        dialog.dialogAddFastval = false;
        saving.set("savingFastval", false);
      } catch (error) {
        data = null;
        saving.set("savingFastval", false);
      }

      return data;
    },
  },
});
