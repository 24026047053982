var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-dialog',{attrs:{"value":_vm.value,"persistent":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-stepper',{attrs:{"flat":"","height":_vm.stepperHeight},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-header',{style:('height: 50px;')},_vm._l((_vm.stepItems),function(item,n){return _c('v-stepper-step',{key:n,staticClass:"py-1",attrs:{"step":item.step,"complete":_vm.currentStep > item.step,"rules":[() => !item.hasError]}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1),_c('v-stepper-items',_vm._l((_vm.stepItems),function(item,n){return _c('v-stepper-content',{key:n,staticClass:"pa-1",attrs:{"step":item.step}},[_c('ValidationObserver',{ref:"form",refInFor:true,scopedSlots:_vm._u([{key:"default",fn:function({
                handleSubmit,
                valid,
                invalid,
                changed,
                touched,
                untouched,
                pristine,
                dirty,
                pending,
                required,
                validated,
                passed,
                failed,
              }){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._t("content",null,{"stepProps":_vm.stepProps(
                      {
                        valid,
                        invalid,
                        changed,
                        touched,
                        untouched,
                        pristine,
                        dirty,
                        pending,
                        required,
                        validated,
                        passed,
                        failed,
                      },
                      item
                    )})],2)]}}],null,true)})],1)}),1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-1"},[_c('div',{staticClass:"overline grey--text"},[_vm._v(_vm._s(_vm.stepperName))]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","outlined":"","disabled":_vm.currentStep === 1 || _vm.saving},on:{"click":function($event){return _vm.back()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" Tillbaka ")],1),_c('v-btn',{attrs:{"small":"","outlined":"","disabled":_vm.saving},on:{"click":function($event){return _vm.avbryt()}}},[_vm._v(" Avbryt ")]),_c('v-btn',{attrs:{"small":"","color":"success","disabled":_vm.totalSteps !== _vm.currentStep,"loading":_vm.saving},on:{"click":function($event){return _vm.validateAllForms()}}},[_vm._v(" Spara ")]),_c('v-btn',{attrs:{"small":"","color":"primary","disabled":_vm.totalSteps === _vm.currentStep},on:{"click":function($event){return _vm.validateStep()}}},[_vm._v(" Nästa "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1),_c('DialogPrompt',{attrs:{"title":"Är du säker på att du vill avbryta?","content":"Inmatad information kommer inte att sparas."},on:{"cancel":function($event){_vm.avbrytDialog = false},"confirmed":function($event){return _vm.confirmed()}},model:{value:(_vm.avbrytDialog),callback:function ($$v) {_vm.avbrytDialog=$$v},expression:"avbrytDialog"}}),_c('DialogPrompt',{attrs:{"title":"Åtgärd krävs","content":"Ett beslut kan inte avslutas om det inte finns en aktuell aktivitet fram till beslutets slutdatum.","no-cancel-button":"","confirmed-button-text":"Ok"},on:{"confirmed":function($event){return _vm.confirmed()}},model:{value:(_vm.avslutBeslutDialog),callback:function ($$v) {_vm.avslutBeslutDialog=$$v},expression:"avslutBeslutDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }