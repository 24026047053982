
import { defineComponent, PropType } from "vue";

import { Anvandare } from "@/models/anvandare";
import { ADataTableVerticalItem } from "@/models/table";

export default defineComponent({
  name: "VerticalCellAnvandareList",

  props: {
    item: {
      type: Object as PropType<ADataTableVerticalItem>,
      required: true,
    },
  },

  computed: {
    anvandareList(): Anvandare[] {
      if (this.item.anvandareList) {
        return this.item.anvandareList;
      } else {
        return [];
      }
    },
  },
});
