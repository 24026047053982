import { defineStore } from "pinia";

import { Anvandare } from "@/models/anvandare";
import { AtkomstRoll } from "@/models/atkomstroll";
import { BaseObject } from "@/models/base";
import { AnvandareGrupp } from "@/models/grupp";

import { useAuthStore } from "./auth";
import { useCrudStore } from "./crud";
import { useDeltagareStore } from "./deltagare";
import { useDialogStore } from "./dialog";
import { useFormStore } from "./form";
import { useHelpStore } from "./help";
import { useReloadStore } from "./reload";
import { useSnackbarStore } from "./snackbar";
import { useStepperStore } from "./stepper";

export const useAnvandareStore = defineStore("anvandare", {
  state: () => ({
    loading: false,
    saving: false,
    cannotSend: false,
    anvandare: null as Anvandare | null,
    atkomstRollList: [] as AtkomstRoll[],
    anvandareList: [] as Anvandare[],
    anvandareGruppList: [] as AnvandareGrupp[],
    gruppAnvandareList: [] as Anvandare[],
    skickaVidareDeltagareTillGruppList: [] as AnvandareGrupp[],
    kontrolleradIdentitetList: [] as BaseObject[],

    isEditAnvandare: false,

    anvandareForm: {
      id: null as number | null,
      epost: null as string | null,
      namn: null as string | null,
      befattning: null as string | null,
      arbetsstalle: null as string | null,
      ort: null as string | null,
      kontrolleradIdentitetId: null as number | null,
      gruppId: null as number | null,
      personnummer: null as string | null,
    },

    separatedAtkomstRollList: [] as {
      header?: string;
      id?: number;
      namn?: string;
    }[],

    anvandareGruppForm: {
      gruppId: null as number | null,
      primar: false,
    },

    atkomstRollId: [] as number[],
  }),

  getters: {
    anvandareNamn(state): string {
      return state.anvandare?.namn ?? "---";
    },

    aktuell(state): boolean {
      return state.anvandare ? state.anvandare.aktuell : false;
    },

    obekraftad(state): boolean {
      return state.anvandare
        ? state.anvandare?.status.length > 0 &&
            state.anvandare?.status[0] === "Obekräftad"
        : false;
    },

    findAnvandareGruppNamn:
      (state) =>
      (gruppId: number | null): string | null => {
        if (!gruppId) return null;

        const grupp = state.anvandareGruppList.find((i) => i.id === gruppId);

        return grupp?.namn ?? null;
      },

    findSkickaVidareDeltagareAnvandareGruppNamn:
      (state) =>
      (gruppId: number | null): string | null => {
        if (!gruppId) return null;

        const grupp = state.skickaVidareDeltagareTillGruppList.find(
          (i) => i.id === gruppId
        );

        return grupp?.namn ?? null;
      },

    findKontrolleradIdentitetNamn:
      (state) =>
      (kontrolleradIdentitetId: number | null | undefined): string | null => {
        if (!kontrolleradIdentitetId) return null;

        const kontrolleradIdentitet = state.kontrolleradIdentitetList.find(
          (i) => i.id === kontrolleradIdentitetId
        );

        return kontrolleradIdentitet?.namn ?? null;
      },

    filtreraUtTagnaAtkomstrollerForAnvandaren(): AtkomstRoll[] {
      return this.atkomstRollList.filter((r) => {
        return !this.anvandare?.atkomstRoll.some((aR) => {
          return r.id === aR.id;
        });
      });
    },
  },

  actions: {
    findGruppAnvandareNamn(anvandareId: number | null): string | null {
      if (!anvandareId) return null;

      const anvandare = this.gruppAnvandareList.find(
        (i) => i.id === anvandareId
      );

      return anvandare?.namn ?? null;
    },

    resetAnvandareForm() {
      this.anvandareForm.arbetsstalle = null;
      this.anvandareForm.befattning = null;
      this.anvandareForm.epost = null;
      this.anvandareForm.gruppId = null;
      this.anvandareForm.id = null;
      this.anvandareForm.kontrolleradIdentitetId = null;
      this.anvandareForm.namn = null;
      this.anvandareForm.ort = null;
      this.anvandareForm.personnummer = null;
    },

    async getAnvandare(anvandareId: number | null | undefined) {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `anvandare/${anvandareId}`,
        })) as Anvandare;

        this.anvandare = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async setPrimarAnvandareGrupp(
      anvandareId: number | null | undefined,
      gruppId: number | null | undefined
    ) {
      if (!anvandareId || !gruppId) return;

      this.saving = true;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const reload = useReloadStore();
        const dialog = useDialogStore();

        await crud.put({
          url: `anvandare/${anvandareId}/grupp/${gruppId}`,
          data: {
            primar: true,
          },
        });

        snackbar.genericSnackbarMessage = "Grupp satt som primär";
        snackbar.genericSnackbar = true;

        dialog.dialogSetPrimarAnvandareGrupp = false;

        reload.toggleReload("reloadAnvandareDetailView");

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async getAnvandareList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "anvandare",
        })) as Anvandare[];

        this.anvandareList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getAnvandareGruppList() {
      this.loading = true;

      try {
        const crud = useCrudStore();
        const auth = useAuthStore();

        const data = (await crud.get({
          url: `anvandare/${auth?.user?.id}/grupp`,
        })) as AnvandareGrupp[];

        this.anvandareGruppList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getGruppAnvandareList(
      gruppId: number | null | undefined
    ): Promise<Anvandare | null | undefined> {
      if (!gruppId) return;

      // this.loading = true;

      let handlaggare = null as Anvandare | null | undefined;

      try {
        const crud = useCrudStore();
        const auth = useAuthStore();

        const data = (await crud.get({
          url: `gruppanvandare/${gruppId}/anvandare`,
          params: {
            ejaktuella: false,
          },
        })) as Anvandare[];

        this.gruppAnvandareList = data;

        handlaggare = data.find((i) => i.id === auth.user?.id);

        if (handlaggare) {
          const stepper = useStepperStore();

          stepper.beslut.handlaggareId = handlaggare.id;
          stepper.insatsModel.handlaggareId = handlaggare.id;
        }

        // this.loading = false;
      } catch (error) {
        // this.loading = false;
      }

      return handlaggare;
    },

    /**
     * För att ett resultat ska kunna fås måste det valda beslutsspårets verktyg
     * finnas på något av de andra grupperna för att man ska kunna skicka vidare deltagaren.
     *
     * @since version 0.8.5
     */
    async getSkickaVidareDeltagareTillGruppList() {
      this.loading = true;

      try {
        const crud = useCrudStore();
        const deltagare = useDeltagareStore();

        const data = (await crud.get({
          url: `Beslutspar/${deltagare.beslutsparItem?.id}/VidareTillGrupper`,
        })) as AnvandareGrupp[];

        this.skickaVidareDeltagareTillGruppList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getKontrolleradIdentitetList() {
      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: "AnvandareKontrolleradIdentitet",
        })) as BaseObject[];

        this.kontrolleradIdentitetList = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async getSelectedAnvandareToEdit(anvandareId: number | null | undefined) {
      if (!anvandareId) return;

      this.loading = true;

      try {
        const crud = useCrudStore();

        const data = (await crud.get({
          url: `anvandare/${anvandareId}`,
        })) as Anvandare;

        this.anvandareForm.id = data.id;
        this.anvandareForm.epost = data.epost;
        this.anvandareForm.namn = data.namn;
        this.anvandareForm.befattning = data.befattning;
        this.anvandareForm.arbetsstalle = data.arbetsstalle;
        this.anvandareForm.ort = data.ort;
        this.anvandareForm.kontrolleradIdentitetId =
          data.kontrolleradIdentitetId;

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async addEditAnvandare(
      anvandareId?: number | null | undefined
    ): Promise<{ id: number }> {
      this.saving = true;

      let data = null as unknown as { id: number };

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const dialog = useDialogStore();
        const reload = useReloadStore();
        const form = useFormStore();

        if (this.isEditAnvandare && anvandareId) {
          await crud.put({
            url: `anvandare/${anvandareId}`,
            data: this.anvandareForm,
          });
        }

        if (!this.isEditAnvandare) {
          data = (await crud.post({
            url: "anvandare",
            data: this.anvandareForm,
          })) as { id: number };
        }

        snackbar.genericSnackbarMessage = this.isEditAnvandare
          ? "Användare uppdaterad"
          : "Användare skapad och ett bekräftelsemejl har skickat till den registrerade e-postadressen";
        snackbar.genericSnackbar = true;

        reload.toggleReload("reloadAdministrationContentLayout");

        if (this.isEditAnvandare) {
          reload.toggleReload("reloadAnvandareDetailView");
          form.isDirty = false;
        }

        dialog.dialogAddAnvandare = false;

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }

      return data;
    },

    async getAtkomstrollList() {
      this.loading = true;

      this.atkomstRollList = [];

      try {
        const crud = useCrudStore();
        const help = useHelpStore();

        const data = (await crud.get({
          url: "Atkomstroll",
        })) as AtkomstRoll[];

        this.atkomstRollList = help.sortListByNamn(data) as AtkomstRoll[];

        this.separatedAtkomstRollList = this.separateAndGroupAtkomstRollList(
          this.filtreraUtTagnaAtkomstrollerForAnvandaren
        );

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * Lägg till rubriker:
     * - Skapade roller
     * - Basroller
     */
    separateAndGroupAtkomstRollList(items: AtkomstRoll[]) {
      const help = useHelpStore();

      let positive = [] as AtkomstRoll[];
      let negative = [] as AtkomstRoll[];

      const array = [] as {
        divider?: boolean;
        header?: string;
        id?: number;
        namn?: string;
      }[];

      if (this.anvandare) {
        positive = items.filter((i) => !help.isValueNegative(i.id));

        negative = items.filter((i) => help.isValueNegative(i.id));
      }

      array.push({ header: "Skapade roller" });
      for (const p of positive) {
        array.push({ id: p.id, namn: p.namn });
      }

      array.push({ divider: true });
      array.push({ header: "Basroller" });
      for (const n of negative) {
        array.push({ id: n.id, namn: n.namn });
      }

      return array;
    },

    async addAnvandareGrupp(anvandareId: number | null | undefined) {
      if (!anvandareId) return;

      this.saving = true;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const reload = useReloadStore();
        const form = useFormStore();

        await crud.post({
          url: `anvandare/${anvandareId}/grupp`,
          data: this.anvandareGruppForm,
        });

        snackbar.genericSnackbarMessage = "Grupp tillagd";
        snackbar.genericSnackbar = true;

        reload.toggleReload("reloadAnvandareDetailView");

        /**
         * Extra reset av boolean
         *
         * @since version 0.8.34
         */
        this.anvandareGruppForm.primar = false;

        form.isDirty = false;

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },

    async addAnvandareAtkomstroll(anvandareId: number | null | undefined) {
      if (!anvandareId) return;

      this.saving = true;

      const form = useFormStore();

      const selected = this.atkomstRollId;
      const taken = this.anvandare?.atkomstRoll.map((i) => i.id);

      let data;

      if (taken) {
        data = [...selected, ...taken];
      }

      form.resetTabForm = false;

      try {
        const crud = useCrudStore();
        const snackbar = useSnackbarStore();
        const reload = useReloadStore();

        await crud.put({
          url: `Anvandare/${anvandareId}/AtkomstRoll`,
          data: data,
        });

        snackbar.genericSnackbarMessage =
          selected.length === 1 ? "Roll tillagd" : "Roller tillagda";
        snackbar.genericSnackbar = true;

        reload.toggleReload("reloadAnvandareDetailView");

        this.atkomstRollId = [];
        form.resetTabForm = true;

        form.isDirty = false;

        this.saving = false;
      } catch (error) {
        this.saving = false;
      }
    },
  },
});
