
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent } from "vue";

import DialogWip from "@/components/dialog/DialogWip.vue";
import AList from "@/components/list/AList.vue";
import DialogListGenerator from "@/components/listgenerator/dialog/DialogListGenerator.vue";
import DialogStatistik from "@/components/statistik/dialog/DialogStatistik.vue";
import { MultiList } from "@/models/list";
import { useAppStore } from "@/store/app";
import { useAuthStore } from "@/store/auth";
import { useDialogStore } from "@/store/dialog";

export default defineComponent({
  name: "NavigationDrawer",

  components: {
    AList,
    DialogWip,
    DialogListGenerator,
    DialogStatistik,
  },

  computed: {
    ...mapWritableState(useAppStore, ["drawer"]),
    ...mapState(useAuthStore, ["user", "atkomstEnum"]),
    ...mapWritableState(useDialogStore, [
      "dialogMyndighet",
      "dialogStatistik",
      "dialogGallring",
      "dialogSupport",
    ]),

    navigationList(): MultiList[] {
      return [
        {
          type: "action",
          title: "huvudmeny",
          icon: {
            name: "mdi-close",
            size: null,
            color: null,
          },
          disabled: false,
          route: null,
          children: [],
        },
        {
          type: "nav",
          title: "Start",
          icon: {
            name: "mdi-home",
            size: null,
            color: null,
          },
          disabled: false,
          route: {
            name: "start-statistik-view",
            params: null,
          },
          children: [],
        },
        {
          type: "button",
          title: "Myndighet",
          icon: {
            name: "mdi-gavel",
            size: null,
            color: null,
          },
          disabled: !this.hasAtkomst(this.atkomstEnum.Myndighet),
          route: null,
          children: [],
          emit: { name: "open-dialog", value: "dialogMyndighet" },
        },
        {
          type: "nav",
          title: "Utförare",
          icon: {
            name: "mdi-account-group",
            size: null,
            color: null,
          },
          disabled: !this.hasAtkomst(this.atkomstEnum.Utforare),
          route: {
            name: "deltagare-layout",
            params: null,
          },
          children: [],
        },

        {
          type: "nav",
          title: "Platsbanken",
          icon: {
            name: "mdi-bank",
            size: null,
            color: null,
          },
          disabled: !this.hasAtkomst(this.atkomstEnum.Platsbank),
          route: {
            name: "platsbank-layout",
            params: null,
          },
          children: [],
        },
        {
          type: "button",
          title: "Statistik",
          icon: {
            name: "mdi-chart-bar",
            size: null,
            color: null,
          },
          disabled: !this.hasAtkomst(this.atkomstEnum.Statistik),
          route: null,
          children: [],
          emit: { name: "open-dialog", value: "dialogStatistik" },
        },
        {
          type: "button",
          title: "Gallring",
          icon: {
            name: "mdi-archive",
            size: null,
            color: null,
          },
          disabled: !this.hasAtkomst(this.atkomstEnum.Gallring),
          route: null,
          children: [],
          emit: { name: "open-dialog", value: "dialogGallring" },
        },
        {
          type: "nav",
          title: "Administration",
          icon: {
            name: "mdi-application-cog",
            size: null,
            color: null,
          },
          disabled: !this.hasAtkomst(this.atkomstEnum.Administration),
          route: {
            name: "administration-layout",
            params: null,
          },
          children: [],
        },
        {
          type: "children",
          title: "Support",
          icon: {
            name: "mdi-lifebuoy",
            size: null,
            color: null,
          },
          disabled: false,
          route: null,
          children: [
            {
              type: "button",
              title: "Manual användare",
              icon: {
                name: "mdi-open-in-new",
                size: null,
                color: null,
              },
              disabled: false,
              route: null,
              children: [],
              emit: {
                name: "open-external-link",
                value: "https://2bra.se/accorda/UserAccorda9.pdf",
              },
            },
            {
              type: "button",
              title: "Manual administratör",
              icon: {
                name: "mdi-open-in-new",
                size: null,
                color: null,
              },
              disabled: false,
              route: null,
              children: [],
              emit: {
                name: "open-external-link",
                value: "https://2bra.se/accorda/AdminAccorda9.pdf",
              },
            },
            {
              type: "button",
              title: "Nyheter",
              icon: {
                name: "mdi-open-in-new",
                size: null,
                color: null,
              },
              disabled: false,
              route: null,
              children: [],
              emit: {
                name: "",
                value: "",
              },
            },
            {
              type: "button",
              title: "Supportanmälan",
              icon: {
                name: "mdi-open-in-new",
                size: null,
                color: null,
              },
              disabled: false,
              route: null,
              children: [],
              emit: {
                name: "open-external-link",
                value: "https://support.2bra.se/Arende/Create?prog=accorda",
              },
            },
            {
              type: "button",
              title: "Fjärrsupport Windows",
              icon: {
                name: "mdi-open-in-new",
                size: null,
                color: null,
              },
              disabled: false,
              route: null,
              children: [],
              emit: {
                name: "open-external-link",
                value:
                  "https://download.teamviewer.com/download/TeamViewerQS.exe",
              },
            },
          ],
        },
        {
          type: "divider",
          title: "",
          icon: {
            name: null,
            size: null,
            color: null,
          },
          disabled: false,
          route: null,
          children: [],
        },
        {
          type: "header",
          title: "genvägar",
          icon: {
            name: null,
            size: null,
            color: null,
          },
          disabled: false,
          route: null,
          children: [],
        },
        {
          type: "button",
          title: "Listgenerator",
          icon: {
            name: "mdi-format-list-bulleted",
            size: null,
            color: null,
          },
          disabled: !this.hasAtkomst(this.atkomstEnum.Utforare),
          route: null,
          children: [],
          emit: { name: "open-dialog", value: "dialogListGenerator" },
        },
      ];
    },
  },

  methods: {
    ...mapActions(useDialogStore, ["toggleDialog"]),
    ...mapActions(useAuthStore, ["hasAtkomst"]),

    clickedAction(event: MultiList) {
      if (event.title === "huvudmeny") {
        this.drawer = false;
      }
    },

    clickedButton(event: MultiList) {
      if (event.emit?.name === "open-dialog") {
        this.toggleDialog(event.emit?.value as string, true);
      }

      if (event.emit?.name === "open-external-link") {
        if (event.emit?.value) {
          window.open(event.emit.value as string, "_blank");
        }
      }

      this.drawer = false;
    },
  },
});
