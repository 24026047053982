
import { mapActions, mapState, mapWritableState } from "pinia";
import { defineComponent, PropType } from "vue";

import InputOrt from "@/components/adress/input/combobox/InputOrt.vue";
import InputPostnummer from "@/components/adress/input/combobox/InputPostnummer.vue";
import InputCo from "@/components/adress/input/text/InputCo.vue";
import InputGatuadress from "@/components/adress/input/text/InputGatuadress.vue";
import ACard from "@/components/card/ACard.vue";
import InputTelefonDefault from "@/components/input/text/InputTelefonDefault.vue";
import InputTextDefault from "@/components/input/text/InputTextDefault.vue";
import { StepProps } from "@/models/stepper";
import { useAdressStore } from "@/store/adress";
import { useStepperStore } from "@/store/stepper";

export default defineComponent({
  name: "StepPersonuppgift",

  components: {
    ACard,
    InputTelefonDefault,
    InputCo,
    InputGatuadress,
    InputPostnummer,
    InputOrt,
    InputTextDefault,
  },

  props: {
    stepProps: {
      type: Object as PropType<StepProps>,
      required: true,
    },
  },

  watch: {
    "stepProps.dialog": {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.getData();
        }
      },
    },

    "stepProps.currentStep": {
      handler(value: number) {
        if (value === this.stepProps.item.step) {
          if (!this.adressForm.adressTypId) {
            const adressTyp = this.findAdressTyp("Personadress");
            this.adressForm.adressTypId =
              adressTyp?.id ?? this.adressTypEnum.personadress.id;
          }
        }
      },
    },
  },

  computed: {
    ...mapState(useAdressStore, ["findAdressTyp", "adressTypEnum"]),
    ...mapWritableState(useAdressStore, ["adressForm"]),

    ...mapState(useStepperStore, ["loading", "saving"]),
    ...mapWritableState(useStepperStore, ["deltagare"]),
  },

  methods: {
    ...mapActions(useAdressStore, ["getAdressTypList"]),

    async getData() {
      await this.getAdressTypList();
    },
  },
});
