
import { mapState } from "pinia";
import { ValidationObserver } from "vee-validate";
import Vue, { defineComponent, PropType } from "vue";

import { GenericErrorType } from "@/models/error";
import { ValidationProps } from "@/models/props";
import { ResetStepForm, StepperItem, StepProps } from "@/models/stepper";
import { useErrorStore } from "@/store/error";
import { useStepperStore } from "@/store/stepper";

import DialogPrompt from "./DialogPrompt.vue";

/**
 * Type declaration för vee-validation
 *
 * @see https://stackoverflow.com/questions/52109471/typescript-in-vue-property-validate-does-not-exist-on-type-vue-element
 * @see https://vee-validate.logaretm.com/v3/guide/forms.html#programmatic-access-with-refs
 */
type VForm = Vue & InstanceType<typeof ValidationObserver>;

export default defineComponent({
  name: "DialogStepper",

  components: {
    ValidationObserver,
    DialogPrompt,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array as PropType<StepperItem[]>,
      default: () => [],
    },

    stepperName: {
      type: String,
      default: "stepperName",
    },

    stepHasServerValidation: {
      type: Number,
      default: 0,
    },

    serverValidationSuccess: {
      type: Boolean,
      default: false,
    },

    saving: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    currentStep: 1,
    stepItems: [] as StepperItem[],
    allFormsValid: false,
    errorMessages: null,
    genericError: [] as GenericErrorType[],
    avbrytDialog: false,
    avslutBeslutDialog: false,
  }),

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.mapStepItems();
        }

        if (!value) {
          this.currentStep = 1;
          this.errorMessages = null;
          this.genericError = [];
          this.avbrytDialog = false;
          this.avslutBeslutDialog = false;
          this.mapStepItems();
          this.resetAllForms();
        }
      },
    },

    errors: {
      handler(errors) {
        if (this.$refs.form) {
          const items = this.$refs.form as VForm[];

          for (const [key, value] of Object.entries(errors)) {
            for (let i = 0; i < items.length; i++) {
              if (key === "GenericError") {
                this.genericError = value as GenericErrorType[];

                /**
                 * @todo lägg till visning av GenericError
                 */
                console.log("GenericError", value);
              }

              /**
               * Special error för avslutning av beslut
               *
               * @since version 0.8.41
               */
              if (key === "AvslutBeslutError") {
                this.avslutBeslutDialog = true;
              }

              items[i].setErrors({
                [key]: value as string[],
              });

              if (key in items[i].errors) {
                // gå till steget som har felet
                this.goToStep(i + 1);

                // sätt steg header hasError
                this.stepItems[i].hasError = true;
              }
            }
          }
        }
      },
    },

    serverValidationSuccess: {
      immediate: true,
      handler(value: boolean) {
        if (value) {
          this.next();
        }
      },
    },

    /**
     * Om man behöver återställa Validation Observer manuellt för ett steg i stepper
     *
     * @since Version 0.5.6
     */
    resetStepForm: {
      handler(object: ResetStepForm) {
        if (object && object.value) {
          const items = this.$refs.form as VForm[];

          if (this.value && items !== undefined) {
            if (object.step === this.currentStep) {
              items[this.currentStep - 1].reset();
            }
          }
        }
      },
    },
  },

  computed: {
    ...mapState(useErrorStore, ["errors"]),
    ...mapState(useStepperStore, ["resetStepForm"]),

    totalSteps(): number {
      return this.stepItems.length;
    },

    stepperHeight(): number {
      return this.$vuetify.breakpoint.height - 200;
    },
  },

  methods: {
    mapStepItems() {
      this.stepItems = this.items.map((item) => ({
        ...item,
        hasError: false,
      }));
    },

    back() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    next() {
      if (this.currentStep !== this.totalSteps) {
        this.currentStep++;
      }

      this.$emit("server-validation-success", false);
    },

    async validateStep() {
      const index = this.currentStep - 1;
      const items = this.$refs.form as VForm[];
      const success = await items[index].validate();

      if (success) {
        this.stepItems[index].hasError = false;

        if (
          this.stepHasServerValidation !== 0 &&
          this.currentStep === this.stepHasServerValidation
        ) {
          this.$emit("run-server-validation");
        } else {
          this.next();
        }
      }
    },

    async validateAllForms() {
      const validated = [];

      if (this.$refs.form !== undefined) {
        const items = this.$refs.form as VForm[];

        for (const form of items) {
          const valid = await form.validate();
          validated.push(valid);
        }

        this.allFormsValid = validated.every(Boolean);

        if (this.allFormsValid) {
          this.submit();
        }
      }
    },

    submit() {
      this.$emit("submit");
    },

    avbryt() {
      const items = this.$refs.form as VForm[];

      if (items[0].flags.dirty || items[0].flags.valid) {
        this.avbrytDialog = true;
      } else {
        this.$emit("input", false);
        this.avbrytDialog = false;
      }
    },

    confirmed() {
      this.avbrytDialog = false;
      this.avslutBeslutDialog = false;
      this.$emit("input", false);
    },

    resetAllForms() {
      if (!this.$refs.form) return;

      const items = this.$refs.form as VForm[];

      items.forEach((form) => {
        form.reset();
      });
    },

    goToStep(step: number) {
      this.currentStep = step;
    },

    stepProps(validationProps: ValidationProps, item: StepperItem): StepProps {
      return {
        ...validationProps,
        item,
        dialog: this.value,
        currentStep: this.currentStep,
        saving: this.saving,
        height: this.stepperHeight,
      };
    },
  },
});
