
import { mapActions, mapState, mapWritableState } from "pinia";
import { ValidationObserver } from "vee-validate";
import { defineComponent } from "vue";

import AAlertLoading from "@/components/alert/AAlertLoading.vue";
import ACard from "@/components/card/ACard.vue";
import DialogDefault from "@/components/dialog/DialogDefault.vue";
import FormFilterListgeneratorStatistik from "@/components/filter-listgenerator-statistik/form/FormFilterListgeneratorStatistik.vue";
import { useDialogStore } from "@/store/dialog";
import { useFilterListgeneratorStatistikStore } from "@/store/filterListgeneratorStatistik";
import { useHelpStore } from "@/store/help";
import { useStatistikStore } from "@/store/statistik";

import AktivitetsperiodDagar from "../components/AktivitetsperiodDagar.vue";
import AntalOnskatArbetsomrade from "../components/AntalOnskatArbetsomrade.vue";
import AntalPerAktivitet from "../components/AntalPerAktivitet.vue";
import AntalPerBeslut from "../components/AntalPerBeslut.vue";
import AntalPerBestallare from "../components/AntalPerBestallare.vue";
import AntalPerErsattning from "../components/AntalPerErsattning.vue";
import AntalPerHandelse from "../components/AntalPerHandelse.vue";
import AntalPerHandlaggargruppStodniva from "../components/AntalPerHandlaggargruppStodniva.vue";
import AntalPerInitieradAv from "../components/AntalPerInitieradAv.vue";
import AntalPerProjekt from "../components/AntalPerProjekt.vue";
import AntalPerResultatVidAvslut from "../components/AntalPerResultatVidAvslut.vue";
import AntalPerResultatVidBeslutsbyte from "../components/AntalPerResultatVidBeslutsbyte.vue";
import AntalPlaceradePerTypAvArbetsplats from "../components/AntalPlaceradePerTypAvArbetsplats.vue";
import BarChartWithTable from "../components/BarChartWithTable.vue";
import DeltagarePerHogstaUtbildningsniva from "../components/DeltagarePerHogstaUtbildningsniva.vue";
import DeltagarePerHuvudsakligenArbetatMed from "../components/DeltagarePerHuvudsakligenArbetatMed.vue";
import DeltagarePerUtbildningsnivaKolada from "../components/DeltagarePerUtbildningsnivaKolada.vue";
import InskrivningsperioderVeckor from "../components/InskrivningsperioderVeckor.vue";
import KaaDeltagarePerFodelseAr from "../components/kaa/KaaDeltagarePerFodelseAr.vue";
import KaaDeltagarePerSenasteUtbildning from "../components/kaa/KaaDeltagarePerSenasteUtbildning.vue";
import KaaDeltagarePerSenasteUtbildningsprogram from "../components/kaa/KaaDeltagarePerSenasteUtbildningsprogram.vue";
import KaaDeltagareSenasteSkola from "../components/kaa/KaaDeltagareSenasteSkola.vue";
import UppgiftPerPerson from "../components/UppgiftPerPerson.vue";

export default defineComponent({
  name: "DialogStatistik",

  components: {
    AAlertLoading,
    ACard,
    DialogDefault,
    ValidationObserver,
    FormFilterListgeneratorStatistik,
    UppgiftPerPerson,
    InskrivningsperioderVeckor,
    DeltagarePerHogstaUtbildningsniva,
    DeltagarePerUtbildningsnivaKolada,
    DeltagarePerHuvudsakligenArbetatMed,
    AntalPerHandlaggargruppStodniva,
    AntalPerBestallare,
    AntalPerInitieradAv,
    AntalPerErsattning,
    AntalPerBeslut,
    AntalOnskatArbetsomrade,
    AntalPerAktivitet,
    AktivitetsperiodDagar,
    AntalPerHandelse,
    AntalPerProjekt,
    AntalPerResultatVidAvslut,
    AntalPerResultatVidBeslutsbyte,
    AntalPlaceradePerTypAvArbetsplats,
    KaaDeltagarePerFodelseAr,
    KaaDeltagarePerSenasteUtbildning,
    KaaDeltagareSenasteSkola,
    KaaDeltagarePerSenasteUtbildningsprogram,
    BarChartWithTable,
  },

  data: () => ({
    isDirty: false,
    isInvalid: false,
    resultDone: false,
    filtreringar: null as string | null,
  }),

  watch: {
    dialogStatistik: {
      immediate: true,
      handler(value: boolean) {
        if (!value) {
          this.resetData();
        }
      },
    },
  },

  computed: {
    ...mapWritableState(useDialogStore, ["dialogStatistik"]),

    ...mapState(useStatistikStore, ["resultData", "loadingResult"]),

    ...mapState(useFilterListgeneratorStatistikStore, [
      "loadingAnvandareGrupp",
      "filterData",
      "inloggadAnvandareGruppList",
      "inskrivningsperiodText",
    ]),
    ...mapWritableState(useFilterListgeneratorStatistikStore, [
      "resetFilterForm",
    ]),
  },

  methods: {
    ...mapActions(useFilterListgeneratorStatistikStore, [
      "getOnlySetFilters",
      "setFiltreringar",
    ]),
    ...mapActions(useStatistikStore, ["getResultData"]),
    ...mapActions(useHelpStore, ["percentage"]),

    async generateStatistik() {
      try {
        const filterData = await this.getOnlySetFilters();
        await this.getResultData(filterData);

        this.resultDone = true;

        this.filtreringar = this.setFiltreringar(
          filterData,
          this.inloggadAnvandareGruppList
        );
      } catch (error) {
        this.resultDone = false;
      }
    },

    resetData() {
      const statistik = useStatistikStore();
      statistik.$reset();
      this.resultDone = false;
    },
  },
});
