
import { defineComponent, PropType } from "vue";
import { DataTableHeader } from "vuetify/types";

/**
 * @todo Detta är en tabell till statistik och ska byta namn till ADataTableStatistik
 */
export default defineComponent({
  name: "ADataTableDefault",

  props: {
    headers: {
      type: [] as unknown as PropType<DataTableHeader[]>,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    tableClass: {
      type: String,
      default: "ml-3",
    },

    sortBy: {
      type: null as unknown as PropType<string | null | undefined>,
    },

    hasBodyAppendForSum: {
      type: Boolean,
      default: false,
    },

    rowSumTitle: {
      type: String,
      default: "Totalt",
    },
  },

  computed: {
    sortByDefault(): string | null | undefined {
      if (this.sortBy === undefined || this.sortBy === null) return "name";

      return this.sortBy;
    },
  },
});
