
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Colors,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { defineComponent, PropType } from "vue";
import { Bar } from "vue-chartjs";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors
);

export default defineComponent({
  name: "ABarChart",

  components: {
    Bar,
  },

  props: {
    chartData: {
      type: null as unknown as PropType<ChartData<"bar">>,
      required: true,
    },

    chartOptions: {
      type: null as unknown as PropType<ChartOptions>,
    },

    chartTitle: {
      type: null as unknown as PropType<string | null>,
    },
  },

  computed: {
    defaultChartOptions(): ChartOptions {
      if (!this.chartOptions) {
        return {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            title: {
              display: true,
              text: this.chartTitle ? this.chartTitle : "",
            },
          },
        };
      }

      return this.chartOptions;
    },
  },
});
